import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { MatPaginatorIntl, PageEvent } from "@angular/material/paginator";
import i18next from "i18next";
import { translateAndFormat } from "src/app/i18next";

export class PageQueryEvent {
	pageQuery: URLSearchParams;
	pageEvent: PageEvent;
}

// this function is to modify the labels and text displayed in the paginator
// see also: https://github.com/angular/components/blob/master/src/material/paginator/paginator-intl.ts
// see also: https://github.com/angular/components/issues/5573
export function CustomPaginatorLabel() {
	const customPaginatorIntl = new MatPaginatorIntl();

	i18next.on("initialized", () => {
		customPaginatorIntl.itemsPerPageLabel = `${translateAndFormat("rows per page", "capitalize")}:`;
		customPaginatorIntl.previousPageLabel = translateAndFormat("previous page", "capitalize");
		customPaginatorIntl.nextPageLabel = translateAndFormat("next page", "capitalize");
	});

	return customPaginatorIntl;
}

@Component({
	selector: "app-paginator",
	templateUrl: "./paginator.component.html",
	styleUrls: ["./paginator.component.scss"],
	providers: [{ provide: MatPaginatorIntl, useValue: CustomPaginatorLabel() }],
	encapsulation: ViewEncapsulation.ShadowDom,
})
export class PaginatorComponent {
	// this input will come from parent component as the total number of items in the API SQL tables and the current page
	@Input() length: number;
	@Input() pageIndex: number;
	@Input() pageSize: number;
	@Input() pageSizeOptions: number[];

	public pageQuery = new URLSearchParams();

	// Will emit a string with the pageQuery string and the page event
	@Output() pageEvent: EventEmitter<PageQueryEvent> = new EventEmitter();

	emitPageEvent(event: PageEvent): void {
		const previousPage = event.previousPageIndex;
		const currentPage = event.pageIndex;

		// if current page number is less than the previous page number, user clicked right arrow button to go to next page
		// if current page number equals previous page number, user changed the rows per page size
		// if current page number if greater than previous, user clicked the left arrow button to go back a page
		this.pageQuery.set("page", `${previousPage <= currentPage ? event.pageIndex + 1 : event.previousPageIndex}`);
		this.pageQuery.set("perPage", `${event.pageSize}`);

		// emitting the query string with the page event
		const emittedPageEvent: PageQueryEvent = {
			pageQuery: this.pageQuery,
			pageEvent: event,
		};
		this.pageEvent.emit(emittedPageEvent);
	}
}
