import { User } from "@auth0/auth0-angular";
import { Observable } from "rxjs";
import { filter, map, switchMap, take } from "rxjs/operators";
import { OwnerType, SettingApiService, SettingStatus } from "src/app/services/setting-api/setting-api.service";
import { environment } from "src/environments/environment";

export function getSettings(
	key: string,
	user$: Observable<User> | null,
	settingApiService: SettingApiService,
	local: boolean,
): Observable<string | null> {
	// if no settingsApiService provided, then use localStorage
	if (local) {
		return settingApiService.getLocalSetting(key).asObservable();
	} else {
		return user$.pipe(
			filter(user => !!user),
			take(1),
			switchMap(user => {
				const url = new URL(environment.environmentConstants.APP_ENDPOINT_SETTINGS);

				const params = new URLSearchParams({
					ownerId: user.id,
					name: key,
					ownerType: OwnerType.USER,
					status: SettingStatus.ACTIVE,
				});

				params.forEach((value, key) => url.searchParams.append(key, value));

				return settingApiService
					.getSetting(url.toString())
					.pipe(map(settings => (settings.length > 0 ? settings[0].value : null)));
			}),
		);
	}
}
