<button
	mat-button
	class="filter-button"
	[ngClass]="filterButtonClass"
	[matMenuTriggerFor]="dropdownMultiSelectMenu"
	(menuOpened)="handleMenuOpen()"
>
	<span
		*ngIf="showLabel"
		class="filter-button-label"
		[ngClass]="filterButtonLabelClass"
	>
		{{ dropdownLabel }}
	</span>
	<span
		class="filter-button-text"
		[ngClass]="filterButtonTextClass"
	>
		{{ selectedOption }}
	</span>
	<mat-icon>{{ buttonIconValue }}</mat-icon>
</button>
<mat-menu
	#dropdownMultiSelectMenu="matMenu"
	xPosition="after"
	class="multi-select-dropdown-menu"
	(closed)="handleMenuClose()"
>
	<div
		class="option-checkbox-container"
		*ngIf="showMenu"
		(click)="$event.stopPropagation()"
	>
		<div
			*ngFor="let option of options"
			class="option-checkbox-wrapper"
		>
			<mat-checkbox
				[id]="option.value"
				class="option-checkbox"
				[ngClass]="{ 'hide-checkbox': option.label === 'Loading...' }"
				(click)="matCheckboxClick($event)"
				(change)="matCheckboxChange($event, option)"
				[checked]="defaultCheckState[option.label]"
				[disabled]="option.label === 'Loading...'"
			>
				<img
					*ngIf="option.icon"
					class="option-icon"
					[src]="option.icon"
				/>

				<ng-container *ngIf="isInspectionType(); else normalLabel">
					<span *ngIf="option.label | splitAtFirstDelimiter as parts">
						<span>{{ parts[0] }}</span>
						<span
							class="other-parts"
							*ngIf="parts.length > 1"
						>
							{{ parts[1] }}
						</span>
					</span>
				</ng-container>

				<ng-template #normalLabel>
					<span>{{ option.label }}</span>
				</ng-template>
			</mat-checkbox>
			<br />
		</div>
	</div>
	<div class="action-button-container">
		<app-action-button
			title="cancel"
			type="outlined-1"
			[style.marginRight.px]="16"
			(buttonClicked)="handleMenuClose()"
		></app-action-button>

		<app-action-button
			title="apply"
			(buttonClicked)="dropdownSubmit()"
		></app-action-button>
	</div>
</mat-menu>
