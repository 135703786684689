import { translateAndFormat } from "../i18next";
import i18next from "i18next";

export interface SupportConstants {
	SUPPORT_TEXT: string;
	TERMS_CONDITIONS_TEXT: string;
	DATA_DISCLOSURE_TEXT: string;
	SUPPORT_PHONE_TEXT: string;
	SUPPORT_EMAIL_TEXT: string;
}
export interface SupportConstantsApi {
	supportConstants: SupportConstants | null;
}

export let api: SupportConstantsApi = {
	supportConstants: null,
};

i18next.on("initialized", () => {
	api = {
		supportConstants: {
			SUPPORT_TEXT: translateAndFormat("support", "capitalize"),
			TERMS_CONDITIONS_TEXT: translateAndFormat("terms & conditions", "title"),
			DATA_DISCLOSURE_TEXT: translateAndFormat("data disclosure", "title"),
			SUPPORT_PHONE_TEXT: translateAndFormat("support phone", "title"),
			SUPPORT_EMAIL_TEXT: translateAndFormat("support email", "title"),
		},
	};
});
