<svg
	class="zonar-logo-svg"
	xmlns="http://www.w3.org/2000/svg"
	viewBox="40 7 280 38"
>
	<g
		fill="#F3F4F5"
		fill-rule="evenodd"
	>
		<text>
			<tspan
				class="logo-text"
				x="41"
				y="36"
			>
				{{ logoTitle }}
			</tspan>
		</text>
	</g>
</svg>
