import { Injectable } from "@angular/core";
import { datadogRum } from "@datadog/browser-rum";
import { environment } from "../../../environments/environment";
import { appName, appVersion } from "../../../environments/shared";

@Injectable({
	providedIn: "root",
})
export class DataDogRumService {
	constructor() {
		if (environment.production && window.location.hostname !== "localhost") {
			const env = environment.production ? "production" : "staging";
			const trackInteractions = environment.brand.name === "zonar";
			const defaultPrivacyLevel = trackInteractions ? "mask-user-input" : "mask";

			datadogRum.init({
				applicationId: environment.datadog.applicationId,
				clientToken: environment.datadog.clientToken,
				site: environment.datadog.site,
				env: env,
				service: appName,
				version: appVersion,
				sampleRate: 100,
				trackInteractions: trackInteractions,
				defaultPrivacyLevel: defaultPrivacyLevel,
			});

			datadogRum.startSessionReplayRecording();
		}
	}
}
