import { Component, OnInit } from "@angular/core";
import { api } from "src/app/constants/support-constants";
import { currentLocale } from "src/app/i18next";
import { LocaleConfig, LocaleConfigService } from "src/app/services/locale-config/locale-config.service";
import { environment } from "src/environments/environment";
import { newDate } from "src/utils/newDate";

@Component({
	selector: "app-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
	public supportText: string = api.supportConstants.SUPPORT_TEXT;
	public termsConditionsText: string = api.supportConstants.TERMS_CONDITIONS_TEXT;
	public dataDisclosureText: string = api.supportConstants.DATA_DISCLOSURE_TEXT;
	public supportPhoneText: string = api.supportConstants.SUPPORT_PHONE_TEXT;
	public supportPhoneNumber: Record<string, string> =
		environment.brand["support-phone-number"][currentLocale() ?? "default"] ??
		environment.brand["support-phone-number"].default;
	public supportEmail: string = environment.brand["support-email"];
	public copyright = environment.brand.copyright;
	public footerLogoFilename = environment.brand["footer-logo-filename"];
	public localeConfig: LocaleConfig;

	constructor(private localeConfigService: LocaleConfigService) {}

	ngOnInit(): void {
		this.localeConfig = this.localeConfigService.getConfig();
	}

	public get currentYear() {
		return newDate().getFullYear();
	}

	get mailTo() {
		return `mailto:${this.supportEmail}`;
	}
}
