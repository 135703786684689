import { IUser } from "@zonar-ui/auth/lib/models/user.model";
import { Observable } from "rxjs";
import { filter, switchMap, take } from "rxjs/operators";
import {
	OwnerType,
	SettingApiService,
	SettingParams,
	SettingStatus,
} from "src/app/services/setting-api/setting-api.service";
import { environment } from "src/environments/environment";

export function setSettings(
	key: string,
	value: string,
	user$: Observable<IUser> | null,
	settingApiService: SettingApiService,
	local: boolean,
): Observable<any> {
	if (local) {
		localStorage.setItem(key, value);

		settingApiService.getLocalSetting(key).next(value);

		return settingApiService.getLocalSetting(key).asObservable();
	} else {
		let user: IUser = null;

		return user$.pipe(
			filter(user => !!user),
			take(1),
			switchMap(_user => {
				// check for existing settings
				user = _user;
				const url = new URL(environment.environmentConstants.APP_ENDPOINT_SETTINGS);

				const params = new URLSearchParams({
					ownerId: user.id,
					name: key,
					ownerType: OwnerType.USER,
					status: SettingStatus.ACTIVE,
				});

				params.forEach((value, key) => url.searchParams.append(key, value));

				return settingApiService.getSetting(url.toString());
			}),
			switchMap(userSettingResponse => {
				if (userSettingResponse && userSettingResponse[0]) {
					// setting already exists, update it
					const params: SettingParams = {
						value,
					};
					return settingApiService.updateSetting(userSettingResponse[0].id, params);
				} else {
					// create a new setting record
					const params: SettingParams = {
						ownerId: user.id,
						name: key,
						value,
						ownerType: OwnerType.USER,
						status: SettingStatus.ACTIVE,
					};
					return settingApiService.createSetting(params);
				}
			}),
		);
	}
}
