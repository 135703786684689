import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { append, patch } from "@ngxs/store/operators";
import { IUser } from "@zonar-ui/auth/lib/models/user.model";
import { EMPTY } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { HydratedUserProfile } from "src/app/models/core-api.models";
import { ErrorResponse } from "src/app/models/error-response";
import { RepairRequest, RepairResolutionsResponseInner, RepairResponse } from "../../../models/openAPIAliases";
import { RepairService } from "../service/repair.service";

export class RepairStateModel {
	mechanics: Array<IUser>;
	numberOfDefects: number;
	resolutions: RepairResolutionsResponseInner[];
	repair: Array<RepairResponse | ErrorResponse>;
	supervisingMechanics: Array<IUser>;
	allMechanics: Array<IUser>;
}

export class GetMechanics {
	static readonly type = "[Repairs] Get Mechanics";
	constructor(public companyPayload: string, public rolePayload: string, public endpoint: string) {}
}

export class GetSupervisingMechanics {
	static readonly type = "[Repairs] Get Supervising Mechanics";
	constructor(public companyPayload: string, public rolePayload: string, public endpoint: string) {}
}

export class SetAllMechanics {
	static readonly type = "[Repairs] Set All Mechanics";
	constructor(public mechanicsAndSupervisingMechanics: Array<IUser>) {}
}

export class GetCompanyResolutions {
	static readonly type = "[Repairs] Get Company Resolutions";
	constructor(public companyPayload: string, public endpoint: string) {}
}

export class PostRepair {
	static readonly type = "[Repairs] Post Repair On Defect(s)";
	constructor(public repairPayload: RepairRequest, public defectPayload: string, public endpoint: string) {}
}

export class ResetRepairs {
	static readonly type = "[Repairs] Reset Repair to Empty Array";
}

export class SetNumberOfDefects {
	static readonly type = "[Repairs] Set Number of Defects";
	constructor(public defectLengthPayload: number) {}
}

@Injectable()
@State<RepairStateModel>({
	name: "repair",
})
export class RepairState {
	constructor(private repairService: RepairService) {}

	@Selector()
	static getAllMechanics(state: RepairStateModel) {
		return state.allMechanics;
	}

	@Selector()
	static getMechanics(state: RepairStateModel) {
		return state.mechanics;
	}

	@Selector()
	static getSupervisingMechanics(state: RepairStateModel) {
		return state.supervisingMechanics;
	}

	@Selector()
	static getCompanyResolutions(state: RepairStateModel) {
		return state.resolutions;
	}

	@Selector()
	static getRepair(state: RepairStateModel) {
		return state.repair;
	}

	@Selector()
	static getNumberOfDefects(state: RepairStateModel) {
		return state.numberOfDefects;
	}

	@Action(SetAllMechanics)
	setAllMechanics(
		{ patchState }: StateContext<RepairStateModel>,
		{ mechanicsAndSupervisingMechanics }: SetAllMechanics,
	) {
		return patchState({
			allMechanics: mechanicsAndSupervisingMechanics,
		});
	}

	@Action(GetMechanics)
	getMechanics(
		{ patchState }: StateContext<RepairStateModel>,
		{ companyPayload, rolePayload, endpoint }: GetMechanics,
	) {
		return this.repairService.getMechanics(companyPayload, rolePayload, endpoint).pipe(
			tap((result: Array<HydratedUserProfile>) => {
				patchState({
					mechanics: result.map(mechanic => mechanic.user),
				});
			}),
		);
	}

	@Action(GetSupervisingMechanics)
	getSupervisingMechanics(
		{ patchState }: StateContext<RepairStateModel>,
		{ companyPayload, rolePayload, endpoint }: GetSupervisingMechanics,
	) {
		return this.repairService.getMechanics(companyPayload, rolePayload, endpoint).pipe(
			tap((result: Array<HydratedUserProfile>) => {
				patchState({
					supervisingMechanics: result
						.filter(mechanic => mechanic.userId !== endpoint)
						.map(mechanic => mechanic.user),
				});
			}),
		);
	}

	@Action(GetCompanyResolutions)
	getCompanyResolutions(
		{ patchState }: StateContext<RepairStateModel>,
		{ companyPayload, endpoint }: GetCompanyResolutions,
	) {
		return this.repairService.getResolution(companyPayload, endpoint).pipe(
			tap((result: RepairResolutionsResponseInner[]) => {
				patchState({
					resolutions: result,
				});
			}),
		);
	}

	@Action(PostRepair)
	postRepair({ setState }: StateContext<RepairStateModel>, { repairPayload, defectPayload, endpoint }: PostRepair) {
		return this.repairService.postRepairStatus(repairPayload, defectPayload, endpoint).pipe(
			tap((result: RepairResponse) => {
				setState(
					patch({
						repair: append<RepairResponse | ErrorResponse>([result]),
					}),
				);
			}),
			catchError((error: Error) => {
				// Handle the error here
				console.error("Could not post repair", error);
				setState(
					patch({
						repair: append<ErrorResponse | RepairResponse>([new ErrorResponse(error.message)]),
					}),
				);
				return EMPTY;
			}),
		);
	}

	@Action(ResetRepairs)
	resetRepairs({ patchState }: StateContext<RepairStateModel>) {
		return patchState({
			repair: [],
		});
	}

	@Action(SetNumberOfDefects)
	setNumberOfDefects({ patchState }: StateContext<RepairStateModel>, { defectLengthPayload }: SetNumberOfDefects) {
		return patchState({
			numberOfDefects: defectLengthPayload,
		});
	}
}
