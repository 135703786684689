import { Observable, combineLatest } from "rxjs";
import { debounceTime, filter } from "rxjs/operators";
import { LocaleService } from "src/app/services/locale/locale.service";
import { environment } from "src/environments/environment";
import { newDate } from "src/utils/newDate";
import { mock } from "ts-mockito";
import {
	Asset,
	DivisionInfo,
	InspectionAsset,
	InspectionDefect,
	InspectionDetail,
	InspectionDetailData,
	InspectionGet,
	InspectionInfo,
	InspectionReviewGet,
	InspectionZone,
	Media,
	RepairResponse,
	Telemetry,
	TranslatableString,
	VersionedId,
} from "./models/openAPIAliases";
import { supportedImperialUnits } from "./models/supported-imperial.units";
import { supportedMetricUnits } from "./models/supported-metric.units";
export abstract class Utils {
	public static isString(value: unknown) {
		return typeof value === "string";
	}

	public static handleMultipleSelects(selects: Observable<unknown>[], callback): void {
		combineLatest(selects).pipe(filter(this.filterGarbage), debounceTime(100)).subscribe(callback);
	}

	public static filterGarbage<DataFromStream>(dataFromStream: DataFromStream[] | DataFromStream) {
		const isNotGarbage = (item: unknown) =>
			Boolean(item) && (item.constructor !== Object || Object.keys(item).length > 0);

		return Array.isArray(dataFromStream) ? dataFromStream.every(isNotGarbage) : isNotGarbage(dataFromStream);
	}

	public static wrap(messages): void {
		console.log("");
		console.log("");
		console.log("");
		console.log("");
		console.log("");

		if (Array.isArray(messages)) {
			messages.forEach(message => {
				console.log("");
				console.log(message);
				console.log("");
			});
		} else {
			console.log(messages);
		}

		console.log("");
		console.log("");
		console.log("");
		console.log("");
		console.log("");
	}

	public static isEmptyObject<CheckObject extends Partial<Record<keyof CheckObject, unknown>>>(
		checkObject: CheckObject,
	): boolean {
		return Object.entries(checkObject).length === 0 && checkObject.constructor === Object;
	}
	public static isZonarTheme() {
		return environment.brand.name === "zonar";
	}

	/* returns 0 if an empty array or single entity unit is received to normalize it further,else returns the index of supported metric system */
	public static findUnitIndex(localeService: LocaleService, units: string | ReadonlyArray<string>) {
		if (units.length > 1 && Array.isArray(units)) {
			const supportedUnits = localeService.usesMetricSystem() ? supportedMetricUnits : supportedImperialUnits;

			return units.findIndex(unit => supportedUnits.includes(unit));
		}
		return 0;
	}

	public static buildMockInspection(): InspectionGet {
		const inspection: InspectionGet = mock<InspectionGet>();

		inspection.companyId = "1";
		inspection.companyName = "2";
		inspection.configId = "3";
		inspection.id = "4";

		const mockDivisionInfo: DivisionInfo = {
			divisionId: "5",
			divisionName: "division name",
			divisionType: "division type",
			parentDivisionId: "division parent ID",
		};

		const mockAsset: Asset = {
			assetCarrierDot: "carrier dot",
			assetCategory: "core",
			assetDivisions: [mockDivisionInfo],
			assetEngineSerialNumber: "engine serial number",
			assetHomeLocation: "home location",
			assetId: "6",
			assetIsRental: false,
			assetJurisdiction: "JU",
			assetLicense: "license",
			assetMake: "make",
			assetModel: "model",
			assetModelYear: "2020",
			assetName: "asset name",
			assetOemPin: "oem pin",
			assetPowerUnitNumber: "pun",
			assetRentalDot: "rental dot",
			assetSerialNumber: "serial number",
			assetType: ["asset type"],
			assetVersion: "version",
			assetVin: "vin",
		};

		const mockTelemetry: Telemetry = {
			latitude: 0,
			longitude: 0,
			elevation: 0,
			horizontalAccuracy: 0,
			verticalAccuracy: 0,
			timestamp: `${newDate().getTime()}`,
			gpsTimestamp: `${newDate().getTime()}`,
		};

		const mockVersionedId: VersionedId = {
			id: "7",
			version: 1,
		};

		const mockTranslatableString: TranslatableString = {
			languageKey: "8",
			languageLabel: "label",
		};

		const mockDefectMedia: Media = {
			mediaId: "8",
			mediaType: "png",
			thumbnail: "thumbnail",
		};

		const mockinspectionDefect: InspectionDefect = {
			componentIndex: 1,
			componentName: mockTranslatableString,
			conditionName: mockTranslatableString,
			defectId: "9",
			defectMedia: [mockDefectMedia],
			otherComponent: "other component",
			otherCondition: "other condition",
			severity: 0,
		};

		const mockInspectionZone: InspectionZone = {
			assetViewLocation: [0, 0],
			inspectionDefects: [mockinspectionDefect],
			sequence: 2,
			telemetry: mockTelemetry,
			verificationData: "verification data",
			verificationType: "nfc",
			zoneName: mockTranslatableString,
		};

		const mockInspectionAsset: InspectionAsset = {
			asset: mockAsset,
			assetViewGrid: [0, 0],
			assetViewId: "10",
			inspectionZones: [mockInspectionZone],
			zoneLayoutId: mockVersionedId,
			zoneLayoutName: mockTranslatableString,
		};

		inspection.inspectionAssets = [mockInspectionAsset];

		const mockInspectionDetailData: InspectionDetailData = {
			inspectionDetailAuxTagContent: "aux tag content",
			inspectionDetailCategory: "odometer",
			inspectionDetailContent: "detail content",
			inspectionDetailContentKey: "detail content key",
			inspectionDetailDataName: mockTranslatableString,
			inspectionDetailDataType: "int",
			inspectionDetailIndex: 3,
			inspectionDetailSelectedUnit: "mi",
			telemetry: mockTelemetry,
		};

		const mockInspectionDetail: InspectionDetail = {
			inspectionDetailData: [mockInspectionDetailData] as InspectionDetail["inspectionDetailData"],
			inspectionDetailId: mockVersionedId,
			inspectionDetailName: mockTranslatableString,
		};

		inspection.inspectionDetail = mockInspectionDetail;

		const mockInspectionInfoEnd: InspectionInfo = {
			action: "end",
			telemetry: mockTelemetry,
		};

		const mockInspectionInfoStart: InspectionInfo = {
			action: "start",
			telemetry: mockTelemetry,
		};

		inspection.inspectionInfo = [mockInspectionInfoStart, mockInspectionInfoEnd];

		const mockRepair: RepairResponse = {
			assetId: "12",
			comment: "repair comment",
			companyId: "13",
			created: `${newDate("July 19, 2020")}`,
			defectId: "14",
			languageChoice: "en-us",
			mechanicFirstName: "mechanic first name",
			mechanicId: "15",
			mechanicLastName: "mechanic last name",
			repairId: "16",
			repairStatus: "repaired",
			resolution: "closed",
			resolutionType: "repaired",
			userFirstName: "user first name",
			userId: "17",
			userLastName: "user last name",
			version: "version",
			workOrder: "work order",
		};

		inspection.inspectionRepairs = { "123": [mockRepair] };

		const mockInspectionReviews: InspectionReviewGet = {
			comment: "review comment",
			companyId: "18",
			id: "19",
			inspectionId: "20",
			inspectorFirstName: "inspector first name",
			inspectorId: "21",
			inspectorLastName: "inspector last name",
			telemetry: mockTelemetry,
			version: "version",
		};

		inspection.inspectionReviews = [mockInspectionReviews];
		inspection.inspectorDivisions = [mockDivisionInfo];
		inspection.inspectorFirstName = mockInspectionReviews.inspectorFirstName;
		inspection.inspectorId = mockInspectionReviews.inspectorId;
		inspection.inspectorLastName = mockInspectionReviews.inspectorLastName;
		inspection.isLegacy = false;
		inspection.languageChoice = mockRepair.languageChoice;
		inspection.languageId = mockVersionedId;
		inspection.maxSeverity = 255;
		inspection.requiresReview = false;
		inspection.settingsId = "22";
		inspection.userAgent = "user agent";
		inspection.version = "version";

		return inspection;
	}
}
