import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PermissionsService } from "@zonar-ui/auth";
import { ICompany } from "@zonar-ui/auth/lib/models/company.model";
import { BehaviorSubject, Observable, ReplaySubject, of } from "rxjs";
import { catchError, delay, filter, map, switchMap, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CompanyOption } from "../setting-api/setting-api.service";

@Injectable({
	providedIn: "root",
})
export class CacheCompaniesService {
	constructor(private permissionsService: PermissionsService, private http: HttpClient) {}

	companiesCache: CompanyOption[];
	// cache the current company name for mobile so user
	// can maintain state during mobile sidenav open/close
	company: CompanyOption;
	isZonarUser = false;

	selectedCompany$ = new BehaviorSubject<string>("");
	selectedCompanyOption$ = new BehaviorSubject<{ name: string; id: string }>({ name: "", id: "" });
	sidenavFetchingUserInformation$: ReplaySubject<any> = new ReplaySubject();

	getCompanies() {
		return this.permissionsService.getIsZonarUser().pipe(
			delay(0),
			take(1),
			switchMap(isZonarUser => {
				this.isZonarUser = isZonarUser;
				if (isZonarUser) {
					/* If the user is a Zonar Admin user, then instead of returning all teh companies,
					 * we will wait until the user enters a text and search for entered text
					 */
					return of(
						// TODO: GP added && this.selectedCompanyOption$.value.id !== "" when empty company context
						this.selectedCompanyOption$.value && this.selectedCompanyOption$.value.id !== ""
							? [this.selectedCompanyOption$.value]
							: [],
					);
				} else {
					return this.permissionsService.getCompanyMap().pipe(
						filter(companyMap => Boolean(companyMap)),
						take(1),
						map(companyMap => Object.values(companyMap)),
					);
				}
			}),
			map((companies: any[]) => {
				this.companiesCache = companies
					.map(c => {
						return {
							title: c.name,
							value: c.id,
						};
					})
					.sort(this.compareCompanies);
				// sidenav finishes initializing selected company and cache company options
				this.sidenavFetchingUserInformation$.next({
					fetchingUserInformation: false,
					isZonarUser: this.isZonarUser,
				});
				return [...this.companiesCache];
			}),
		);
	}

	getSearchedCompanies(searchText?: string): Observable<CompanyOption[]> {
		if (!searchText || searchText.length < 2) {
			return of([]);
		} else {
			return this.searchCompanies(searchText.trim()).pipe(
				map((companies: Array<ICompany>) => {
					this.companiesCache = [
						...companies.map(c => {
							return <CompanyOption>{
								title: c.name,
								value: c.id,
							};
						}),
					]
						.filter(x => x)
						.sort(this.compareCompanies);
					return <CompanyOption[]>[...this.companiesCache];
				}),
				catchError(() => {
					return of([]);
				}),
			);
		}
	}

	getSelectedCompany(companyId: string) {
		return this.getCompany(companyId).pipe(
			map((selectedCompany: ICompany) => {
				return [
					...this.companiesCache,
					{
						title: selectedCompany?.name,
						value: selectedCompany?.id,
					},
				];
			}),
		);
	}

	compareCompanies(a: CompanyOption, b: CompanyOption): number {
		if (a.title < b.title) {
			return -1;
		}
		if (a.title > b.title) {
			return 1;
		}
		return 0;
	}

	searchCompanies(searchText: string): Observable<ICompany[]> {
		const url = `${environment.environmentConstants.APP_ENDPOINT_CORE_COMPANY}/companies?q=name:${searchText}`;
		return this.http.get(url).pipe(map((companies: ICompany[]) => companies));
	}

	getCompany(companyId: string): Observable<ICompany> {
		const url = `${environment.environmentConstants.APP_ENDPOINT_CORE_COMPANY}/companies/${companyId}`;
		return this.http.get(url).pipe(map((company: ICompany) => company));
	}
}
