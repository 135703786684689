import { animate, state, style, transition, trigger } from "@angular/animations";
import { SelectionModel } from "@angular/cdk/collections";
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewChildren, ViewEncapsulation } from "@angular/core";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Select, Store } from "@ngxs/store";
import { startOfDay, subDays } from "date-fns";
import { Observable, Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, skip } from "rxjs/operators";
import { AppState } from "src/app/app.state";
import { EnabledFilters, FilterCardComponent } from "src/app/components/filter-card/filter-card.component";
import { InfoDialogComponent } from "src/app/components/info-dialog/info-dialog.component";
import { PageQueryEvent } from "src/app/components/paginator/paginator.component";
import { TabConfig } from "src/app/components/tab-control/tab-control.component";
import { translateAndFormat } from "src/app/i18next";
import { formatDate, formatDateTime } from "src/app/i18next/formatDate";
import { CustomDateRangeFilterChange } from "src/app/models/emitter-events.models";
import { ExportType } from "src/app/models/exportType";
import {
	GpsMissingInspectionList,
	Id,
	LangDictGet,
	MissingInspectionsAssetsResponse,
	MissingInspectionsInspectorsResponse,
} from "src/app/models/openAPIAliases";
import { SplitAtFirstDelimiterPipe } from "src/app/pipes/split-at-first-delimiter.pipe";
import { ExportService } from "src/app/services/export/export.service";
import { LoggerService } from "src/app/services/logger.service";
import { MemCacheService } from "src/app/services/mem-cache/mem-cache.service";
import { TabUpdaterService } from "src/app/services/tab-updater.service";
import { environment } from "src/environments/environment";
import { formatIso } from "src/utils/format-iso";
import { newDate } from "src/utils/newDate";
import { GetMissingInspectionsList, MissingInspectionsState } from "./state/missing-inspection.state";

export const debounceMs = 100;
export interface MissingInspectionSortState {
	sort: string;
	sortActive: string;
	sortDirection: string;
	reportFocus: string;
}

interface TabConfigData {
	reportFocus: "assets-gps" | "assets" | "inspectors";
}

export class MissingInspectionsInspectorViewModel {
	inspector: string;
	startTime: any;
	inspection: {
		name: string;
		type: any;
	};
	division: string;
	index?: number;
}

export class MissingInspectionsAssetViewModel {
	asset: {
		name: string;
		type: string;
		id: string;
	};
	inspection: {
		name: string;
		type: any;
	};
	division: string;
	startTime: any;
	index?: number;
}

export class GpsMissingInspectionViewModel {
	asset: {
		id: string;
		name: string;
	};
	inspection: {
		name: string;
		type: any;
	};
	division: string;
	startTime: any;
	zoneLayout: string;
	index?: number;
}

export type MissingInspectionsReportType = "inspectors" | "assets" | "assets-gps";

const stateKey = "missing-inspections";

@Component({
	selector: "app-missing-inspections",
	templateUrl: "./missing-inspections.component.html",
	styleUrls: ["./missing-inspections.component.scss"],
	encapsulation: ViewEncapsulation.None,
	animations: [
		// expandable rows example: https://material.angular.io/components/table/examples
		// added void: https://github.com/angular/components/issues/11990
		trigger("detailExpand", [
			state("collapsed, void", style({ height: "0px", minHeight: "0" })),
			state("expanded", style({ height: "*" })),
			transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
			transition("expanded <=> void", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
		]),
		trigger("rotatedState", [
			state("down", style({ transform: "rotate(0)" })),
			state("up", style({ transform: "rotate(180deg)" })),
			transition("down <=> up", animate("225ms cubic-bezier(0.4,0.0,0.2,1)")),
		]),
	],
})
export class MissingInspectionsComponent implements OnInit, AfterViewInit, OnDestroy {
	config = {
		initialSelection: [],
		allowMultiSelect: true,
	};
	defaultState: MissingInspectionSortState = {
		sort: this.getQueryStringForFieldSort("start-time", "desc"),
		sortActive: "start-time",
		sortDirection: "desc",
		reportFocus: "assets",
	};
	enabledFilters: EnabledFilters = {
		assetId: false,
		assetType: false,
		customDateRange: false,
		dateRange: false,
		defectType: false,
		inspectionType: false,
		inspectionName: false,
		homeLocation: false,
		inspector: false,
		page: "",
		severity: false,
		tableFilter: false,
		resetFilterButton: false,
		searchBar: false,
	};
	companyId: string;
	selectedDivisionId: string;
	hasTCU = undefined;
	inspectorsData: Array<MissingInspectionsInspectorViewModel> = [];
	assetsData: Array<MissingInspectionsAssetViewModel> = [];
	gpsAssetsData: Array<GpsMissingInspectionViewModel> = [];
	currentPageSize: number;
	isInitiallyLoading = true;
	missingInspectionsListCount = 0;
	pageIndex = 0;
	pageSize = 10;
	pageSizeOptions = [10, 25, 50, 75, 100];
	modifySearchString: string = this.translate("please modify your search and try again", "capitalize");
	reportFocus: "inspectors" | "assets" | "assets-gps" = "assets";
	rotateState: string[] = [];
	selectionModel: SelectionModel<any>;
	sortActive = "start-time";
	sortDirection = "desc";
	state: MissingInspectionSortState;
	subscriptions = new Subscription();
	zonarMapUrl = environment.environmentConstants.ZONAR_MAP_URL;
	missingInspectionsData:
		| MissingInspectionsAssetsResponse
		| MissingInspectionsInspectorsResponse
		| GpsMissingInspectionList = [];
	missingInspectionsDataTable: Array<
		MissingInspectionsInspectorViewModel | MissingInspectionsAssetViewModel | GpsMissingInspectionViewModel
	> = [];
	currentQueryStrings: {
		queryParams: URLSearchParams;
		customDateRangeFilter: CustomDateRangeFilterChange;
	} = {
		queryParams: new URLSearchParams({
			page: "1",
			perPage: this.pageSize.toString(),
			reportFocus: this.reportFocus,
		}),
		customDateRangeFilter: {
			dates: [{ startTime: startOfDay(subDays(newDate(), 7)), endTime: newDate() }],
		},
	};

	view: "inspectors" | "assets" | "assets-gps" = "assets";

	splitPipe = new SplitAtFirstDelimiterPipe();

	dataSource = new MatTableDataSource<
		MissingInspectionsInspectorViewModel | MissingInspectionsAssetViewModel | GpsMissingInspectionViewModel
	>();
	private debounceFilterUpdates$ = new Subject<URLSearchParams>();

	@ViewChild(FilterCardComponent) filterCardComponent: FilterCardComponent;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChildren("matCheckbox") checkboxes: MatCheckbox;

	sort: MatSort;

	@Select(AppState.selectCompanyHasTCU) hasTCU$: Observable<boolean>;
	@Select(AppState.selectLanguageDictionary) languageDictionary$: Observable<LangDictGet>;
	@Select(MissingInspectionsState.getMissingInspectionsList) missingInspectionsList$: Observable<
		MissingInspectionsAssetsResponse | MissingInspectionsInspectorsResponse | GpsMissingInspectionList
	>;
	@Select(MissingInspectionsState.getMissingInspectionsListCount) missingInspectionsListCount$: Observable<number>;
	@Select(MissingInspectionsState.missingInspectionsUpdate) getMissingInspectionsUpdate$: Observable<number>;
	@Select(AppState.selectSelectedDivision) selectedDivisionId$: Observable<Id>;
	@Select(AppState.selectCompanyId) companyId$: Observable<Id>;

	tabs: Array<TabConfig<unknown>> = [];
	tabsTmpl: Array<TabConfig<unknown>> = [
		{
			id: "assets",
			label: "Assets (All)",
			selected: true,
			data: {
				reportFocus: "assets",
			},
		},
		{
			id: "inspectors",
			label: "Inspectors",
			selected: false,
			data: {
				reportFocus: "inspectors",
			},
		},
	];
	constructor(
		public dialog: MatDialog,
		private tabUpdater: TabUpdaterService,
		private memCacheService: MemCacheService,
		private exportService: ExportService,
		private logger: LoggerService,
		public store: Store,
	) {}

	updateTabs(hasTCU: boolean) {
		if (hasTCU) {
			this.reportFocus = "assets-gps";
			this.sortActive = "start-time";
			this.tabs = [
				{
					id: "assets-gps",
					label: "Assets (GPS enabled)",
					selected: true,
					data: {
						reportFocus: "assets-gps",
					},
				},
				...this.tabsTmpl.map(tab => ({ ...tab, selected: false })),
			];
		} else {
			this.tabs = this.tabsTmpl.map(tab => {
				if (tab.id === "assets") {
					tab.label = "Assets";
				}

				return tab;
			});
		}
		this.enabledFilters = this.resetFilters(this.reportFocus);
		this.view = this.reportFocus;
		this.currentQueryStrings.queryParams.set("reportFocus", this.view);
		this.handleFilterValues(this.currentQueryStrings);
	}
	ngOnInit() {
		this.currentPageSize = 10;
		this.getDataSource().sort = this.sort;
		this.getDataSource().paginator = this.paginator;
		this.memCacheService.setValue("isLoadingPage", true);

		// only server-side sorting
		this.getDataSource().sortingDataAccessor = () => null;

		// Set filter views
		this.enabledFilters = {
			assetId: true,
			assetType: true,
			customDateRange: true,
			dateRange: false,
			defectType: false,
			inspectionType: true,
			inspectionName: true,
			homeLocation: true,
			inspector: true,
			page: "missing-inspections",
			severity: false,
			tableFilter: false,
			resetFilterButton: true,
			searchBar: false,
		};

		this.subscriptions.add(
			this.hasTCU$.subscribe(hasTCU => {
				if (hasTCU !== undefined) {
					this.hasTCU = hasTCU;
					this.updateTabs(hasTCU);
				} else {
					// WORK AROUND WHEN THE DEVICE API IS NOT WORKING TODO: GP
					this.updateTabs(false);
				}
			}),
		);

		// Subscribe to company id
		this.subscriptions.add(
			this.companyId$.subscribe(companyId => {
				if (companyId) {
					this.companyId = companyId;
				}
			}),
		);

		this.subscriptions.add(
			this.selectedDivisionId$.subscribe((selectedDivisionId: Id) => {
				this.selectedDivisionId = selectedDivisionId;
			}),
		);

		this.subscriptions.add(
			this.languageDictionary$.subscribe(langDict => {
				if (langDict) {
					// Subscribe to missing inspections list count
					this.subscriptions.add(
						this.missingInspectionsListCount$.subscribe((count: number) => {
							this.missingInspectionsListCount = count;

							//initialize all triangles in view to down since no expansion should happen on init
							this.rotateState = new Array(this.missingInspectionsListCount).fill("down");
						}),
					);
				}
			}),
		);

		// debounce filter updates - multiple will be generated when the filter card is cleard
		this.subscriptions.add(
			this.debounceFilterUpdates$
				.pipe(debounceTime(debounceMs))
				.subscribe(this.handleFilterValuesDebounced.bind(this)),
		);

		this.getDataSource().sort = this.sort;
		this.getDataSource().paginator = this.paginator;

		this.initState();

		// Subscribe to missing inspections list
		this.subscriptions.add(
			this.missingInspectionsList$
				.pipe(distinctUntilChanged((previous, current) => JSON.stringify(previous) === JSON.stringify(current)))
				.subscribe(
					(
						missingInspections:
							| MissingInspectionsAssetsResponse
							| MissingInspectionsInspectorsResponse
							| GpsMissingInspectionList,
					) => {
						this.missingInspectionListSubscribeHandler(missingInspections);
					},
				),
		);

		// Subscribe to memcache service
		this.subscriptions.add(
			this.memCacheService
				.cleared$()
				.pipe(filter(context => context === "missing-inspections"))
				.subscribe(() => {
					this.memCacheService.setValue(stateKey, this.defaultState);

					this.initState();
				}),
		);

		this.subscriptions.add(
			this.getMissingInspectionsUpdate$.pipe(skip(1)).subscribe(() => {
				this.handleMissingInspectionsUpdate();
			}),
		);

		this.subscriptions.add(
			this.memCacheService.cacheChanged$(stateKey).subscribe((value: MissingInspectionSortState) => {
				this.state = value;
			}),
		);
	}

	handleMissingInspectionsUpdate() {
		if (this.reportFocus === "inspectors") {
			if (this.getDataSource().data.length === 0 && this.inspectorsData.length > 0) {
				this.getDataSource().data = this.inspectorsData;
			} else {
				this.isInitiallyLoading = false;
			}
		} else if (this.reportFocus === "assets-gps") {
			if (this.getDataSource().data.length === 0 && this.gpsAssetsData.length > 0) {
				this.getDataSource().data = this.gpsAssetsData;
			} else {
				this.isInitiallyLoading = false;
			}
		} else {
			if (this.getDataSource().data.length === 0 && this.assetsData.length > 0) {
				this.getDataSource().data = this.assetsData;
			} else {
				this.isInitiallyLoading = false;
			}
		}
	}
	ngAfterViewInit() {
		setTimeout(() => {
			// Make sure we're on the Report tab (Missing Inspections)
			this.tabUpdater.onTabChange.emit({ tab: 1, subTab: 1 });
		}, 0);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	receiveSortObj(sort: MatSort) {
		this.sort = sort;
		this.sort.disableClear = true;
		this.getDataSource().sort = sort;
	}

	/**
	 * Initialize sorting state
	 */
	initState() {
		this.state = this.getState();

		if (!this.state) {
			this.state = {
				sort: this.currentQueryStrings.queryParams.get("sort"),
				sortActive: this.sortActive,
				sortDirection: this.sortDirection,
				reportFocus: this.reportFocus,
			};
		} else {
			switch (this.reportFocus) {
				case "assets-gps":
					this.state = {
						sort: this.getQueryStringForFieldSort("start-time", "desc"),
						sortActive: "start-time",
						sortDirection: "desc",
						reportFocus: "assets-gps",
					};
					break;
				case "assets":
					this.state = {
						sort: this.getQueryStringForFieldSort("start-time", "desc"),
						sortActive: "start-time",
						sortDirection: "desc",
						reportFocus: "assets",
					};
					break;
				case "inspectors":
					this.state = {
						sort: this.getQueryStringForFieldSort("start-time", "desc"),
						sortActive: "start-time",
						sortDirection: "desc",
						reportFocus: "inspectors",
					};
					break;
			}
		}

		this.sortActive = this.state.sortActive;
		this.sortDirection = this.state.sortDirection;
		if (this.state.sort) {
			this.currentQueryStrings.queryParams.set("sort", this.state.sort);
		}
		this.reportFocus = this.state.reportFocus as "inspectors" | "assets" | "assets-gps";
	}

	/**
	 * Update data on filter change
	 * @param filterQueries query strings for filters
	 */
	handleFilterValues(filterQueries: {
		queryParams: URLSearchParams;
		customDateRangeFilter: CustomDateRangeFilterChange;
	}) {
		if (typeof this.hasTCU === "undefined") return;
		this.currentQueryStrings.queryParams = new URLSearchParams();
		filterQueries.queryParams.forEach((value, key) => this.currentQueryStrings.queryParams.set(key, value));
		this.currentQueryStrings.customDateRangeFilter = filterQueries.customDateRangeFilter;

		if (this.reportFocus !== filterQueries.queryParams.get("reportFocus")) {
			this.isInitiallyLoading = true;

			this.currentQueryStrings.queryParams.set(
				"sort",
				this.getQueryStringForFieldSort(this.sortActive, this.sortDirection),
			);
		}

		this.updateTable(this.currentQueryStrings, true);
		this.flushState();
	}

	currentlySelectedMissingInspections() {
		const missingInspections = this.selectionModel.selected;
		missingInspections.sort((a, b) => a.index - b.index);

		return missingInspections;
	}

	/**
	 * Export the data to a CSV file
	 */
	exportCSV() {
		this.exportService.exportMissingInspectionData(
			this.currentlySelectedMissingInspections(),
			this.reportFocus,
			ExportType.CSV,
		);
	}

	/**
	 * Export the data to a PDF file
	 */
	exportPDF() {
		this.exportService.exportMissingInspectionData(
			this.currentlySelectedMissingInspections(),
			this.reportFocus,
			ExportType.PDF,
		);
	}

	/**
	 * Sort table data
	 * @param event Sorting event
	 */
	onMatSortChange(event: Sort) {
		this.currentQueryStrings.queryParams.set(
			"sort",
			this.getQueryStringForFieldSort(event.active, event.direction),
		);

		this.updateTable(this.currentQueryStrings);
		this.selectionModel?.clear();

		this.sortActive = event.active;
		this.sortDirection = event.direction;

		this.flushState();
	}

	/**
	 * Set the current state of the sorts
	 */
	flushState() {
		this.memCacheService.setValue<MissingInspectionSortState>(stateKey, {
			...this.defaultState,
			sort: this.currentQueryStrings.queryParams.get("sort"),
			sortActive: this.sortActive,
			sortDirection: this.sortDirection,
			reportFocus: this.reportFocus,
		});
	}

	/**
	 * Get the current state of the sorts
	 * @returns Current state of sorts
	 */
	getState() {
		return this.memCacheService.getValue<MissingInspectionSortState>(stateKey);
	}

	/**
	 * Method to generate the query string for the different sorts
	 * @param field filter field
	 * @param direction ascending or descending
	 * @returns query string for sorting
	 */
	getQueryStringForFieldSort(field: string = null, direction: string = null): string {
		let sort = "";

		switch (field) {
			case "start-time":
				sort = "startTime";
				break;
			case "inspector-name":
				sort = "inspectorName";
				break;
			case "inspection-name":
				sort = "inspectionName";
				break;
			case "inspection-type":
				sort = "inspectionType";
				break;
			case "asset-number":
				sort = "assetName";
				break;
			case "asset-type":
				sort = "zoneLayout";
				break;
			case "asset-id":
				sort = "assetName";
				break;

			// intentionally not sorting location/home location
			default:
				break;
		}

		return sort ? `${sort}.${direction}` : sort;
	}

	/**
	 * Data source for the table
	 * @returns Table data source
	 */
	getDataSource() {
		return this.dataSource;
	}

	/**
	 *
	 * @param event Mouse event
	 * @param row row data
	 * @returns Toggle the checkbox selection for the row or returns null if it didn't received the event
	 */
	handleCheckboxChange(
		event: MouseEvent,
		row: MissingInspectionsInspectorViewModel | MissingInspectionsAssetViewModel,
	) {
		return event ? this.selectionModel.toggle(row) : null;
	}

	/**
	 * Stop event propagation on checkbox click
	 * @param event Mouse event
	 */
	handleCheckboxClick(event: MouseEvent) {
		event.stopPropagation();
	}

	/**
	 * Label for the table checkbox
	 * @param row possible row selected on the table
	 * @returns Aria label for the row
	 */
	checkboxLabel(row: MissingInspectionsInspectorViewModel | MissingInspectionsAssetViewModel | undefined) {
		return !row
			? `${this.isAllSelected() ? "deselect" : "select"} all` // Master checkbox selected
			: `${this.selectionModel.isSelected(row) ? "deselect" : "select"} row`; // Individual row selected
	}

	/**
	 * Checks if all rows are selected
	 * @returns True if all rows are selected
	 */
	isAllSelected() {
		const numSelected = this.selectionModel.selected.length;
		const numRows = this.getDataSource().data.length;
		return numSelected === numRows;
	}

	/**
	 * Toggles between all and none row selected
	 */
	masterToggle() {
		this.isAllSelected()
			? this.selectionModel?.clear()
			: this.getDataSource().data.forEach(row => this.selectionModel?.select(row));
	}

	/**
	 * Rotate chevron icon
	 * @param i index of the row
	 */
	rotate(i: number) {
		this.rotateState = this.rotateState.map((rotation: string, k: number) => {
			if (k === i) {
				return rotation === "down" ? "up" : "down";
			} else {
				return rotation;
			}
		});
	}

	/**
	 * Paginate results
	 * @param pageQueryEvent Page query information
	 */
	onPaginate(pageQueryEvent: PageQueryEvent) {
		this.pageIndex = pageQueryEvent.pageEvent.pageIndex;
		this.pageSize = pageQueryEvent.pageEvent.pageSize;

		pageQueryEvent.pageQuery.forEach((value, key) => this.currentQueryStrings.queryParams.set(key, value));

		this.updateTable(this.currentQueryStrings);

		// reset selected rows
		this.selectionModel.clear();

		// reset chevrons
		this.rotateState = new Array(this.missingInspectionsListCount).fill("down");
	}

	/**
	 * Update data table
	 * @param currentQueryStrings Current query strings
	 * @param resetPaginator boolean to reset paginator
	 */
	updateTable(
		currentQueryStrings: {
			queryParams: URLSearchParams;
			customDateRangeFilter: CustomDateRangeFilterChange;
		},
		resetPaginator: boolean = false,
	) {
		if (this.companyId) {
			const { queryParams, customDateRangeFilter } = currentQueryStrings;

			// reset paginator on filter change back to first page
			if (resetPaginator) {
				queryParams.set("page", "1");
				queryParams.set("perPage", this.pageSize.toString());
				this.pageIndex = 0;
			}

			const _queryParams = new URLSearchParams(queryParams);

			const hasDivisionIds = _queryParams.has("divisionIds");
			if (!hasDivisionIds && this.selectedDivisionId) {
				_queryParams.set("divisionIds", this.selectedDivisionId);
			}

			if (this.reportFocus === "assets-gps") {
				if (_queryParams.has("timezone")) {
					_queryParams.delete("timezone");
				}
				if (_queryParams.has("inspectorIds")) {
					_queryParams.delete("inspectorIds");
				}
			} else if (this.reportFocus === "inspectors") {
				if (_queryParams.has("assetIds")) {
					_queryParams.delete("assetIds");
				}
				if (_queryParams.has("zoneLayoutIds")) {
					_queryParams.delete("zoneLayoutIds");
				}
			} else {
				if (_queryParams.has("inspectorIds")) {
					_queryParams.delete("inspectorIds");
				}
			}

			// Get missing inspections list
			this.store.dispatch(
				new GetMissingInspectionsList(
					customDateRangeFilter.dates,
					this.reportFocus,
					this.companyId,
					_queryParams,
				),
			);
		} else {
			this.logger.log(`updateTable() called, but companyId hasn't been populated yet`);
		}
	}

	/**
	 * Missing Inspections Subscription Handler
	 * @param missingInspections Missing Inspection response data
	 */
	missingInspectionListSubscribeHandler(
		missingInspections:
			| MissingInspectionsAssetsResponse
			| MissingInspectionsInspectorsResponse
			| GpsMissingInspectionList,
	) {
		// necessary because inspections is null initially
		if (missingInspections !== null && missingInspections !== undefined) {
			this.isInitiallyLoading = false;
			if (missingInspections && missingInspections.length > 0) {
				this.missingInspectionsData = missingInspections;
				this.missingInspectionsDataTable =
					this.reportFocus === "inspectors"
						? ((missingInspections as MissingInspectionsInspectorsResponse).map(
								(missingInspection, index) => ({
									inspector: missingInspection.inspectorName,
									startTime: formatDate(newDate(missingInspection.startTime)),
									inspection: {
										name: missingInspection.inspectionName,
										type: this.splitPipe.transform(missingInspection.inspectionType),
									},
									division: missingInspection.divisions[0],
									index: index,
								}),
						  ) as Array<MissingInspectionsInspectorViewModel>)
						: this.reportFocus === "assets"
						? ((missingInspections as MissingInspectionsAssetsResponse).map((missingInspection, index) => ({
								asset: {
									name: missingInspection.assetName,
									type: missingInspection.zoneLayoutName,
									id: missingInspection.assetId,
								},
								inspection: {
									name: missingInspection.inspectionName,
									type: this.splitPipe.transform(missingInspection.inspectionType),
								},
								division: missingInspection.divisions[0],
								startTime: formatDate(newDate(missingInspection.startTime)),
								index: index,
						  })) as Array<MissingInspectionsAssetViewModel>)
						: ((missingInspections as GpsMissingInspectionList).map((missingInspection, index) => ({
								asset: {
									id: missingInspection.assetId,
									name: missingInspection.assetName,
								},
								inspection: {
									name: missingInspection.inspectionName,
									type: this.splitPipe.transform(missingInspection.inspectionType),
								},
								division: missingInspection.divisions[0],
								startTime: this.splitPipe.transform(
									//@ts-expect-error TODO: Once the swagger has the correct models remove this expect error
									formatDateTime(newDate(missingInspection.timestamp)),
								),
								isoTime: this.splitPipe.transform(
									//@ts-expect-error TODO: Once the swagger has the correct models remove this expect error
									formatIso(newDate(missingInspection.timestamp).toISOString()),
								),
								zoneLayout: missingInspection.zoneLayoutName,
								index: index,
						  })) as Array<GpsMissingInspectionViewModel>);

				const dataToDisplay = this.missingInspectionsDataTable;

				this.getDataSource().data = dataToDisplay;

				if (this.reportFocus === "inspectors") {
					this.inspectorsData = dataToDisplay as Array<MissingInspectionsInspectorViewModel>;
				} else if (this.reportFocus === "assets") {
					this.assetsData = dataToDisplay as Array<MissingInspectionsAssetViewModel>;
				} else {
					this.gpsAssetsData = dataToDisplay as Array<GpsMissingInspectionViewModel>;
				}
			} else {
				// inspections has been cleared
				this.getDataSource().data = [];
				this.inspectorsData = [];
				this.assetsData = [];
				this.gpsAssetsData = [];
			}
		}
	}

	/**
	 * Open info dialog
	 */
	openInfoDialog() {
		this.dialog.open(InfoDialogComponent, {
			width: "524px",
		});
	}

	translate(key: string, formatType: "capitalize" | "uppercase" | "title" | "camelCase") {
		return translateAndFormat(key, formatType);
	}
	/**
	 * A tab was selected
	 */
	selectTab(data: TabConfigData) {
		/*
		 * wait for the current operation to complete to avoid an impatient user generating
		 * an indefinite number of requests.
		 */
		if (this.view === data.reportFocus) return;
		this.isInitiallyLoading = true;
		this.view = data.reportFocus;
		this.reportFocus = data.reportFocus;

		// reset filter card
		this.enabledFilters = this.resetFilters(this.reportFocus);
		this.filterCardComponent.resetCheckbox = true;
		this.filterCardComponent.clearAllState();

		// reset query params
		this.pageSize = 10;
		this.sortActive = this.defaultState.sortActive;
		this.sortDirection = this.defaultState.sortDirection;
		this.currentQueryStrings.queryParams = new URLSearchParams({
			page: "1",
			perPage: this.pageSize.toString(),
			reportFocus: this.reportFocus,
			sort: this.getQueryStringForFieldSort(this.sortActive, this.sortDirection),
		});

		this.currentQueryStrings.customDateRangeFilter = {
			dates: [{ startTime: startOfDay(subDays(newDate(), 7)), endTime: newDate() }],
		};

		this.updateTable(this.currentQueryStrings, true);

		// reset selected rows
		this.selectionModel.clear();

		// reset chevrons
		this.rotateState = new Array(this.missingInspectionsListCount).fill("down");
		this.filterCardComponent.resetCheckbox = false;
	}

	/**
	 * Handle filter values on Debounce
	 * @param filterQueries Filter query parameters
	 */
	handleFilterValuesDebounced(filterQueries: {
		queryParams: URLSearchParams;
		customDateRangeFilter: CustomDateRangeFilterChange;
	}): void {
		this.currentQueryStrings.queryParams = new URLSearchParams();

		filterQueries.queryParams.forEach((value, key) => this.currentQueryStrings.queryParams.set(key, value));
		this.currentQueryStrings.customDateRangeFilter = filterQueries.customDateRangeFilter;

		this.updateTable(this.currentQueryStrings, true);
	}

	resetFilters(reportFocus: string) {
		const enabledFilters = {
			assetId: false,
			assetType: false,
			customDateRange: true,
			dateRange: false,
			defectType: false,
			inspector: false,
			inspectionType: true,
			inspectionName: true,
			homeLocation: true,
			page: "missing-inspections",
			severity: false,
			tableFilter: false,
			resetFilterButton: true,
			searchBar: false,
		};

		switch (reportFocus) {
			case "assets-gps":
				enabledFilters.assetId = true;
				enabledFilters.assetType = true;
				break;

			case "assets":
				enabledFilters.assetId = true;
				enabledFilters.assetType = true;
				break;

			case "inspectors":
				enabledFilters.inspector = true;
				break;
		}

		return enabledFilters;
	}
}
