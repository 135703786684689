import { Component, Input } from "@angular/core";

@Component({
	selector: "app-zonar-logo",
	templateUrl: "./zonar-logo.component.html",
	styleUrls: ["./zonar-logo.component.scss"],
})
export class ZonarLogoComponent {
	@Input() logoTitle: string;
}
