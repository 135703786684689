import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

export enum OwnerType {
	COMPANY = "COMPANY",
	USERPROFILE = "USERPROFILE",
	USER = "USER",
}

export enum SettingStatus {
	ACTIVE = "ACTIVE",
	INACTIVE = "INACTIVE",
	ANY = "ANY",
}

export enum UserSettings {
	SELECTED_COMPANY = "SELECTED_COMPANY",
	SELECTED_DIVISION = "SELECTED_DIVISION",
}

export interface CompanyOption {
	title: string;
	value: string;
}

export interface SettingInfo {
	id: string;
	companyId?: string;
	divisionId?: string;
	applicationId?: string;
	ownerId: string;
	ownerType: OwnerType;
	name: string;
	value: string;
	status?: SettingStatus;
	created: string;
	modified: string;
}

export interface SettingParams {
	companyId?: string;
	divisionId?: string;
	applicationId?: string;
	ownerId?: string;
	ownerType?: OwnerType;
	name?: string;
	value?: string;
	status?: SettingStatus;
}

@Injectable({
	providedIn: "root",
})
export class SettingApiService {
	constructor(private http: HttpClient) {}

	// TODO: the code related to localSetting will need to be moved to the SettingsService once we start
	// using the sidenav from the PL
	private localSetting: Record<string, BehaviorSubject<string>> = {};

	getLocalSetting(key: string) {
		if (!this.localSetting[key]) {
			this.localSetting[key] = new BehaviorSubject<string>(localStorage.getItem(key));
		}

		return this.localSetting[key];
	}

	getSetting(url: string) {
		return this.http.get(url.toString()).pipe(map((settings: Array<SettingInfo>) => settings));
	}

	createSetting(params: SettingParams) {
		const url = environment.environmentConstants.APP_ENDPOINT_SETTINGS;
		return this.http.post(url, params, { observe: "response" });
	}

	updateSetting(settingId: string, params: SettingParams) {
		const url = `${environment.environmentConstants.APP_ENDPOINT_SETTINGS}/${settingId}`;
		return this.http.patch(url, params, { observe: "response" });
	}
}
