import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Select } from "@ngxs/store";
import { Observable, Subscription } from "rxjs";
import { AppState } from "src/app/app.state";

@Component({
	selector: "app-info-dialog",
	templateUrl: "./info-dialog.component.html",
	styleUrls: ["./info-dialog.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class InfoDialogComponent implements OnInit, OnDestroy {
	hasTCU = false;
	subscription = new Subscription();

	@Select(AppState.selectCompanyHasTCU) hasTCU$: Observable<boolean>;

	constructor(public dialogRef: MatDialogRef<InfoDialogComponent>) {}

	ngOnInit() {
		this.subscription.add(
			this.hasTCU$.subscribe(hasTCU => {
				if (hasTCU !== undefined) {
					this.hasTCU = hasTCU;
				}
			}),
		);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	closeDialog() {
		this.dialogRef.close();
	}
}
