<div class="missing-inspections-page">
	<div
		class="header-row"
		fxFlexLayout="row"
		fxLayoutAlign="space-between center"
	>
		<div class="heading-1">
			{{ "missing inspections" | i18next: { format: "title" } }}
			<span class="missing-inspections-record-count">({{ missingInspectionsListCount }})</span>
		</div>

		<app-export
			[disabled]="!selectionModel || !selectionModel.selected?.length"
			(csvExportReport)="exportCSV()"
			(pdfExportReport)="exportPDF()"
		></app-export>
	</div>

	<div class="report-information">
		<mat-icon
			class="material-icons-outlined pointer info-icon"
			(click)="openInfoDialog()"
		>
			info
		</mat-icon>
		<span>{{ "how we identify missing inspections" | i18next: { format: "capitalize" } }}</span>
	</div>

	<div class="tab-control">
		<app-tab-control
			[tabs]="tabs"
			(selected)="selectTab($event)"
		></app-tab-control>
	</div>

	<app-filter-card
		[enabledFilters]="enabledFilters"
		[context]="'missing-inspections'"
		(filterQueryObject)="handleFilterValues($event)"
	></app-filter-card>

	<div
		class="missing-inspections-table-wrapper"
		*ngIf="!isInitiallyLoading"
	>
		<mat-expansion-panel
			class="missing-inspections-panels"
			[expanded]="true"
		>
			<app-table
				[sortActive]="sortActive"
				[sortDirection]="sortDirection"
				(sortChange)="onMatSortChange($event)"
				[dataSource]="getDataSource()"
				(selectionModelChange)="selectionModel = $event"
				(sortObjChanged)="receiveSortObj($event)"
				[view]="view"
			>
				<!-- Inspector View Columns  -->
				<!-- Start Time column Inspector  -->
				<app-column
					name="date"
					id="start-time"
					sortable="true"
					type="single-value"
					data="startTime"
					view="inspectors"
				></app-column>

				<!-- Inspector Name Column Inspector -->
				<app-column
					name="inspector"
					id="inspector-name"
					sortable="true"
					type="single-value"
					data="inspector"
					view="inspectors"
				></app-column>

				<!-- Inspection Name Column Inspector -->
				<app-column
					name="insp name"
					id="inspection-name"
					sortable="true"
					type="single-value"
					data="inspection.name"
					view="inspectors"
				></app-column>

				<!-- Inspection Type Column Inspector -->
				<app-column
					name="insp type"
					id="inspection-type"
					sortable="true"
					type="multiple-values"
					data="inspection.type"
					view="inspectors"
				></app-column>

				<!-- Home Location Column Inspector -->
				<app-column
					name="home location"
					id="home-location"
					type="single-value"
					data="division"
					view="inspectors"
				></app-column>

				<!-- Asset View Columns  -->
				<!-- Start Time Column Assets -->
				<app-column
					name="date"
					id="start-time"
					sortable="true"
					type="single-value"
					data="startTime"
					view="assets"
				></app-column>

				<!-- Asset Number Column Assets -->
				<app-column
					name="asset number"
					id="asset-number"
					sortable="true"
					type="single-value"
					data="asset.name"
					view="assets"
					routerLinkData='{ "path": "/asset-details", "idExp": "asset.id" }'
				></app-column>

				<!-- Asset Type Column Assets -->
				<app-column
					name="asset type"
					id="asset-type"
					sortable="true"
					type="single-value"
					data="asset.type"
					view="assets"
				></app-column>

				<!-- Inspection Name Column Assets -->
				<app-column
					name="insp name"
					id="inspection-name"
					sortable="true"
					type="single-value"
					data="inspection.name"
					view="assets"
				></app-column>

				<!-- Inspection Type Column Assets -->
				<app-column
					name="insp type"
					id="inspection-type"
					sortable="true"
					type="multiple-values"
					data="inspection.type"
					view="assets"
				></app-column>

				<!-- Home Location Column Assets -->
				<app-column
					name="home location"
					id="home-location"
					type="single-value"
					data="division"
					view="assets"
				></app-column>

				<!-- GPS Asset View Columns  -->
				<!-- Start Time Column Gps Assets -->
				<app-column
					name="start date / time"
					id="start-time"
					sortable="true"
					type="multiple-values"
					data="startTime"
					view="assets-gps"
				></app-column>

				<!-- Asset Id Column Gps Assets -->
				<app-column
					name="asset id"
					id="asset-id"
					sortable="true"
					type="single-value"
					data="asset.name"
					view="assets-gps"
					routerLinkData='{ "path": "/asset-details", "idExp": "asset.id" }'
				></app-column>

				<!-- Asset Type Column Gps Assets -->
				<app-column
					name="asset type"
					id="asset-type"
					sortable="true"
					type="single-value"
					data="zoneLayout"
					view="assets-gps"
				></app-column>

				<!-- Inspection Name Column Gps Assets -->
				<app-column
					name="insp name"
					id="inspection-name"
					sortable="true"
					type="single-value"
					data="inspection.name"
					view="assets-gps"
				></app-column>

				<!-- Inspection Type Column Gps Assets -->
				<app-column
					name="insp type"
					id="inspection-type"
					sortable="true"
					type="multiple-values"
					data="inspection.type"
					view="assets-gps"
				></app-column>

				<!-- Home Location Column Gps Assets -->
				<app-column
					name="home location"
					id="home-location"
					type="single-value"
					data="division"
					view="assets-gps"
				></app-column>

				<!-- See in Live Maps button Column Gps Assets-->
				<!-- 
				The assignment for routerLinkData is confusing.  A Make it Great ticket has been created to improve this: EVIR2-5014

				For now, this comment should help.
				
				What is being passed to TableComponent is a json object of the form: 
				{"path": "{{zonarMapUrl}}"},
				{"idExp": "{{ass}}"},
				{"additional": "/history?recentStartTime="},
				{"additional2": "@isoTime"},
				{"transformFn": "datetimeTransformFn"} 

			The keys "additional", "additional2", and "transformFn" are used by 
			the table.component to construct the url for the routerLink.

			"additional" - a query string that is appended to "path"
			"additional2" - specifies a value for the query string
			                If there is an "@" prepended to this value, then it is
							used as a key to lookup from the data for the row.
			"transformFn" - specifies a function for transforming the "additional2" value
			                into a suitable query string value.  "transformFn" is a key for
							a map of functions in ./components/table/column-transforms/index.ts

			-->
				<app-column
					name=""
					id="live-map"
					type="button"
					data="see in live maps"
					view="assets-gps"
					routerLinkData="{ &quot;path&quot;: &quot;{{
						zonarMapUrl
					}}&quot;, &quot;idExp&quot;: &quot;asset.id&quot;, &quot;additional&quot;: &quot;/history?recentPathStartTime=&quot;, &quot;additional2&quot;:&quot;@isoTime&quot;,&quot;transformFn&quot;:&quot;datetimeTransformFn&quot; }"
				></app-column>
			</app-table>
		</mat-expansion-panel>

		<mat-card
			*ngIf="isInitiallyLoading"
			class="initial-loading-card"
			fxLayout="column"
			fxLayoutAlign="center center"
		></mat-card>

		<app-no-results-card
			*ngIf="missingInspectionsListCount === 0 && !isInitiallyLoading"
			[message]="modifySearchString"
		></app-no-results-card>

		<app-paginator
			*ngIf="missingInspectionsListCount !== 0 && !isInitiallyLoading"
			[length]="missingInspectionsListCount"
			[pageIndex]="pageIndex"
			[pageSizeOptions]="pageSizeOptions"
			[pageSize]="pageSize"
			(pageEvent)="onPaginate($event)"
		></app-paginator>
	</div>
</div>
