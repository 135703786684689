<div class="evir-app">
	<app-header
		[userId]="userId"
		[userName]="userName"
		[userCompany]="userCompany"
		[userDivision]="userDivision"
		(logoutControl)="logoutButtonHandler()"
	></app-header>

	<div
		*ngIf="!accountSelectionPage"
		class="evir-nav"
	>
		<div
			class="evir-nav-links-container"
			fxLayout="row"
			fxLayoutGap="16px"
		>
			<nav
				mat-tab-nav-bar
				[attr.aria-label]="'navigation links' | i18next"
				id="mat-tab-nav-bar"
			>
				<ng-container *ngFor="let routeLink of routeLinks; let i = index">
					<ng-container *ngIf="routeLink.subLabels === undefined">
						<a
							(click)="activeLinkIndex = i"
							[active]="activeLinkIndex === i"
							[routerLink]="routeLink.link"
							mat-tab-link
							class="evir-active-link"
							[ngClass]="{ 'mat-tab-link-active': activeLinkIndex === i }"
						>
							{{ routeLink.label }}
						</a>
					</ng-container>
					<ng-container *ngIf="routeLink.subLabels !== undefined">
						<a
							(click)="activeLinkIndex = i"
							(menuClosed)="(menuOption.menuOpen)"
							(menuOpened)="(menuOption.menuOpen)"
							[active]="activeLinkIndex === i"
							[matMenuTriggerFor]="navBarMenu"
							class="evir-active-link"
							[ngClass]="{ 'mat-menu-tab-link-active': menuOption.menuOpen }"
							#menuOption="matMenuTrigger"
							mat-tab-link
						>
							{{ routeLink.label }}
						</a>
						<mat-menu
							#navBarMenu="matMenu"
							class="nav-bar-menu"
							xPosition="after"
						>
							<a
								(click)="activeSubLinkIndex = j"
								[routerLink]="options.link"
								*ngFor="let options of routeLink.subLabels; let j = index"
								class="nav-bar-options"
								[active]="activeSubLinkIndex === j"
								[ngClass]="{
									'mat-menu-tab-sub-link-active': activeSubLinkIndex === j
								}"
								mat-menu-item
							>
								{{ options.label }}
							</a>
						</mat-menu>
					</ng-container>
				</ng-container>
			</nav>
		</div>
	</div>

	<router-outlet></router-outlet>
</div>

<app-footer></app-footer>
