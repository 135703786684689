import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PermissionsService } from "@zonar-ui/auth";
import { Observable, Subject, of } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map, take, takeUntil, withLatestFrom } from "rxjs/operators";
import { CacheCompaniesService } from "src/app/services/cache-companies/cache-companies.service";
import { ChangeCompanyService } from "src/app/services/change-company/change-company.service";
import {
	CompanyOption,
	OwnerType,
	SettingApiService,
	SettingParams,
	SettingStatus,
	UserSettings,
} from "src/app/services/setting-api/setting-api.service";

@Component({
	selector: "app-company-selector",
	templateUrl: "./company-selector.component.html",
	styleUrls: ["./company-selector.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class CompanySelectorComponent implements OnInit, OnDestroy {
	constructor(
		private dialogRef: MatDialogRef<CompanySelectorComponent>,
		private cacheCompaniesService: CacheCompaniesService,
		private changeCompanyService: ChangeCompanyService,
		private permissionsService: PermissionsService,
		private settingApiService: SettingApiService,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {}

	placeholder = "Company Name";
	companies: CompanyOption[];
	label: string;
	selectedCompany: CompanyOption;
	isLoadingCompanies = false;
	private onDestroy$ = new Subject<void>();
	public dropdownControl = new FormControl();
	public filteredCompanies: Observable<CompanyOption[]>;
	public height: string;
	public itemSize = 50;

	ngOnInit(): void {
		if (this.data && this.data.hasDefaultCompany) {
			this.dropdownControl.setValue(this.cacheCompaniesService.company);
			this.setSelectedCompany(this.cacheCompaniesService.company);
		}

		this.cacheCompaniesService
			.getCompanies()
			.pipe(
				filter(companies => Boolean(companies?.length)),
				take(1),
			)
			.subscribe(companies => {
				this.companies = companies;
				this.filteredCompanies = of(companies);
				this.height = companies.length < 4 ? `${companies.length * this.itemSize}px` : `${this.itemSize * 4}px`;
			});

		this.dropdownControlChanged();
	}

	dropdownControlChanged() {
		this.dropdownControl.valueChanges
			.pipe(
				withLatestFrom(this.permissionsService.getIsZonarUser()),
				// wait 500 milliseconds to start
				debounceTime(500),
				// if value is the same, ignore
				distinctUntilChanged(),
				// start connection
				map(([value, isZonarUser]) => {
					/* if a text is entered, typeof value is a string */
					if (typeof value === "string") {
						/* if the user is a Zonar Admin user, we will get all the companies that matches with the entered text */
						if (isZonarUser) {
							this.isLoadingCompanies = true;
							this.cacheCompaniesService.getSearchedCompanies(value).subscribe(companies => {
								this.isLoadingCompanies = false;
								this.companies = companies;
								const filteredCompanies = this.companies?.filter(c =>
									c.title.toLowerCase().includes(value.toLowerCase()),
								);
								this.height =
									filteredCompanies?.length < 4
										? `${filteredCompanies.length * this.itemSize}px`
										: `${this.itemSize * 4}px`;
								this.filteredCompanies = of(filteredCompanies);
							});
						} else {
							const filteredCompanies = this.companies?.filter(c =>
								c.title.toLowerCase().includes(value.toLowerCase()),
							);
							this.height =
								filteredCompanies?.length < 4
									? `${filteredCompanies.length * this.itemSize}px`
									: `${this.itemSize * 4}px`;
							this.filteredCompanies = of(filteredCompanies);
						}
					} else {
						// if type of the value is not a string, it means a company object is selected from the dropdown
						this.setSelectedCompany(value);
					}
				}),
				takeUntil(this.onDestroy$),
			)
			.subscribe();
	}

	setSelectedCompany(company: CompanyOption) {
		this.selectedCompany = company;
	}

	handleSettingApiResponse(settingObservable: Observable<any>) {
		settingObservable.pipe(takeUntil(this.onDestroy$)).subscribe(apiResponse => {
			if (apiResponse.ok) {
				this.permissionsService
					.getCompanyMap()
					.pipe(take(1), withLatestFrom(this.permissionsService.getIsZonarUser()))
					.subscribe(([companies, isZonar]) => {
						if (!isZonar) {
							this.permissionsService.setCurrentCompanyContext(companies[this.selectedCompany.value]);
						}
					});
				this.dialogRef.close(this.selectedCompany);
			}
		});
	}

	handleDoneButtonClick() {
		if (!!this.selectedCompany && !this.isLoadingCompanies) {
			const companyValue = this.changeCompanyService.convertCompanyOptionToString(this.selectedCompany);
			const params: SettingParams = {
				value: companyValue,
			};

			let settingRequest;
			if (this.changeCompanyService.selectedCompanyUserSettingId) {
				settingRequest = this.settingApiService.updateSetting(
					this.changeCompanyService.selectedCompanyUserSettingId,
					params,
				);
			} else {
				params.ownerId = this.changeCompanyService.userLogging.id;
				params.name = UserSettings.SELECTED_COMPANY;
				params.ownerType = OwnerType.USER;
				params.status = SettingStatus.ACTIVE;
				settingRequest = this.settingApiService.createSetting(params);
			}
			this.handleSettingApiResponse(settingRequest);
		}
	}

	closeDialog() {
		this.dialogRef.close();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}

	companyTitleFn(option: CompanyOption): string {
		return option ? option.title : "";
	}
}
