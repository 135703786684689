<div class="asset-details-page">
	<div
		fxLayout="column"
		fxLayoutAlign="center start"
	>
		<!-- asset header and backlink -->
		<div class="asset-heading">
			<div
				fxLayout="column"
				fxLayoutAlign="start start"
				fxLayoutGap="9px"
			>
				<a
					[routerLink]="previousPageUrl"
					class="inspection-history-link"
				>
					<
					{{
						"back to previousPageTitle"
							| i18next: { format: "capitalize", previousPageTitle: previousPageTitle }
					}}
				</a>
				<div class="heading-1">
					{{ "asset defect and repair history" | i18next }}
				</div>
			</div>
		</div>

		<div
			class="asset-details-card"
			fxFlex="100"
		>
			<div
				fxLayout="column"
				fxLayoutAlign="start start"
			>
				<!-- begin asset details from last inspection in three column flex layout -->
				<div
					fxLayout="row wrap"
					fxFlexFill
				>
					<div
						*ngFor="let detail of assetDetailsArray"
						class="detail-loop"
						fxLayout="column"
						fxLayoutAlign="end"
						fxFlex="0 1 calc(33.3%)"
					>
						<div
							class="asset-cell"
							fxLayout="row"
							fxLayoutAlign="start end"
						>
							<div
								class="detail-label"
								fxFlex="44"
							>
								{{ detail.assetDetailLabel }}
							</div>
							<div
								class="detail-content"
								fxFlex="50"
							>
								{{ detail.assetDetailContent }}{{ detail?.assetDetailConversionPair }}
								<span *ngIf="detail.assetDetailIcon">
									<mat-icon matTooltip="User Created Asset">{{ detail.assetDetailIcon }}</mat-icon>
								</span>
							</div>
						</div>

						<!-- show divider -->
						<div fxLayout="row">
							<mat-divider fxFlex="95"></mat-divider>
						</div>
					</div>
				</div>
			</div>
		</div>

		<mat-accordion
			class="accordion"
			multi="true"
		>
			<!-- begin open and pending defects table -->
			<mat-expansion-panel
				*ngIf="openTableReady"
				class="defect-panels"
				[expanded]="true"
			>
				<mat-expansion-panel-header>
					<mat-panel-title>
						{{ "open and pending defects" | i18next: { format: "capitalize" } }}
					</mat-panel-title>
				</mat-expansion-panel-header>

				<app-open-pending
					[totalOpenDefects]="totalOpenDefects"
					[currentOpenDefectQuery]="getCurrentOpenDefectQuery()"
					(openDefectPaginate)="onOpenDefectsPaginate($event)"
					(emitClosedDefects)="updateClosedDefectTable($event)"
				></app-open-pending>
			</mat-expansion-panel>

			<!-- begin closed and ignored defects table -->
			<mat-expansion-panel
				*ngIf="openTableReady && closedTableReady"
				class="defect-panels"
			>
				<mat-expansion-panel-header>
					<mat-panel-title>
						{{ "closed / ignored defects" | i18next }}
					</mat-panel-title>
				</mat-expansion-panel-header>

				<app-closed-ignored
					[newClosedDefects]="closedDefects"
					[totalClosedDefects]="totalClosedDefects"
					[currentClosedDefectQuery]="getCurrentClosedDefectQuery()"
					(closedDefectPaginate)="onClosedDefectsPaginate($event)"
				></app-closed-ignored>
			</mat-expansion-panel>
		</mat-accordion>
	</div>
</div>
