<!-- <button
  mat-button
  class='action-button action-button-primary'
  (click)='logout()'
>
  Logout
</button> -->

<br />
<br />
<br />
<br />

<label>
	{{ "please enter at least three characters of an account or location" | i18next: { format: "capitalize" } }}:
</label>

<br />
<br />

<input
	matInput
	#accountCode
	id="account-code"
	[placeholder]="'account or location, eg bos2020' | i18next"
	[attr.aria-label]="'account or location' | i18next"
	type="text"
	autofocus
	(keyup)="handleAccountInputChange(accountCode.value)"
/>

<br />
<br />
<br />
<br />

<div *ngIf="showNoResults; then noResults; else results"></div>
<ng-template #noResults>
	{{ "no results found" | i18next: { format: "capitalize" } }}.
	{{ "please modify your search" | i18next: { format: "capitalize" } }}
</ng-template>
<ng-template #results>
	<span *ngFor="let division of divisions">
		<button
			mat-button
			class="action-button account-code-button action-button-primary"
			style="white-space: pre; text-align: left"
			(click)="handleAccountCodeSelect(division.id, division.companyId)"
			matTooltip="{{ division.tooltip }}"
			matTooltipPosition="above"
		>
			{{ division.displayName }}

			<mat-icon
				*ngIf="division.showIcon"
				style="float: right; margin-top: 5px"
			>
				home
			</mat-icon>
		</button>
	</span>
</ng-template>
