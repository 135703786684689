import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { PermissionsService } from "@zonar-ui/auth";
import { IDivision } from "@zonar-ui/auth/lib/models/company.model";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { AppState, GetCoreApiDivisions, SetCompanyAndDivisionId } from "src/app/app.state";
import { SettingApiService, UserSettings } from "src/app/services/setting-api/setting-api.service";
import { Utils } from "src/app/utils";
import { environment } from "src/environments/environment";
import { setSettings } from "src/utils/settings/set-settings";
import { Id } from "./../../models/openAPIAliases";
import { LoggerService } from "./../../services/logger.service";

@Component({
	selector: "app-zonar-admin-account-selection",
	templateUrl: "./zonar-admin-account-selection.component.html",
	styleUrls: ["./zonar-admin-account-selection.component.scss"],
})
export class ZonarAdminAccountSelectionComponent implements OnInit {
	divisions: (IDivision & { tooltip: string; showIcon: boolean; displayName: string })[] = [];
	showNoResults = false;

	@Select(AppState.selectCoreApiDivisions) coreApiDivisions$: Observable<Array<IDivision>>;

	constructor(
		private store: Store,
		private router: Router,
		private permissionService: PermissionsService,
		private settingApiService: SettingApiService,
		public logger: LoggerService,
	) {}

	ngOnInit() {
		this.coreApiDivisions$.pipe(filter(Utils.filterGarbage)).subscribe(divisions => {
			this.showNoResults = Array.isArray(divisions) && divisions.length === 0 ? true : false;

			if (divisions.length > 0) {
				this.divisions = divisions.map(division => ({
					...division,
					tooltip: division.name,
					showIcon: division.children.length === 0 ? false : true,
					displayName: division.name.length > 17 ? `${division.name.slice(0, 17)}...` : division.name,
				}));
			}
		});
	}

	handleAccountCodeSubmit(divisionName: string): void {
		this.store.dispatch(
			new GetCoreApiDivisions(divisionName, environment.environmentConstants.APP_ENDPOINT_CORE_COMPANY),
		);
	}

	handleAccountCodeSelect(divisionId: Id, companyId: Id): void {
		this.setGlobalDataAndSwitchViews(divisionId, companyId);
	}

	setGlobalDataAndSwitchViews(divisionId: Id, companyId: Id): void {
		this.logger.log(`division selected: ${divisionId}`);
		this.logger.log(`company selected: ${companyId}`);

		this.store.dispatch(new SetCompanyAndDivisionId(companyId, divisionId));

		// persist selected company and division (use localStorage for division)
		setSettings(
			UserSettings.SELECTED_COMPANY,
			companyId,
			this.permissionService.getUser(),
			this.settingApiService,
			false,
		).subscribe(() => {
			setSettings(UserSettings.SELECTED_DIVISION, divisionId, null, this.settingApiService, true);
			setSettings(UserSettings.SELECTED_COMPANY, companyId, null, this.settingApiService, true);
			this.permissionService.setCurrentCompanyContextById(companyId);

			// navigate to the inspections list page
			this.router.navigate(["/inspection-list"]);
		});
	}

	handleAccountInputChange(divisionName: string): void {
		if (divisionName.length > 2) {
			this.handleAccountCodeSubmit(divisionName.replace(":", "+"));
		}
	}
}
