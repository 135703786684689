import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { SatDatepickerModule, SatNativeDateModule } from "saturn-datepicker";
import { AppRoutingModule } from "./app-routing.module";

// Flex
import { FlexLayoutModule } from "@angular/flex-layout";

// Angular Material
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material.module";

// NGXS
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { NgxsModule } from "@ngxs/store";
import { AppState } from "./app.state";
import { ErrorState } from "./components/errors/state/error.state";
import { LogState } from "./log.state";
import { AssetState } from "./views/asset-details/state/asset-details.state";
import { DefectListState } from "./views/defect-list/state/defect-list.state";
import { InspectionState } from "./views/inspection-detail/state/inspection-detail.state";
import { InspectionHistoryState } from "./views/inspection-history/state/inspection-history.state";
import { MissingInspectionsState } from "./views/missing-inspections/state/missing-inspection.state";
import { RepairState } from "./views/repairs/state/repairs.state";

// Auth0
import { AuthHttpInterceptor, AuthModule } from "@auth0/auth0-angular";

// Router Services
import { ErrorService } from "./components/errors/service/error.service";

// I18Next
import { I18NextModule } from "angular-i18next";
import { I18N_PROVIDERS } from "./i18next";

// Environment
import { environment } from "src/environments/environment";

// Components
import { ZonarOwnerIdInterceptor, ZonarUiAuthModule, ZonarUiPermissionsModule } from "@zonar-ui/auth";
import { AppComponent } from "./app.component";
import { ActionButtonComponent } from "./components/action-button/action-button.component";
import { CharacterCounterComponent } from "./components/character-counter/character-counter.component";
import { CustomCalendarHeaderComponent } from "./components/custom-calendar-header/custom-calendar-header.component";
import { CustomDateRangeFilterComponent } from "./components/custom-date-range-filter/custom-date-range-filter.component";
import { DateRangeFilterComponent } from "./components/date-range-filter/date-range-filter.component";
import { DefectFilterComponent } from "./components/defect-type-filter/defect-type-filter.component";
import { ErrorsComponent } from "./components/errors/errors.component";
import { ExportComponent } from "./components/export/export/export.component";
import { FilterCardComponent } from "./components/filter-card/filter-card.component";
import { FooterComponent } from "./components/footer/footer.component";
import { GetTableFilterComponent } from "./components/get-table-filter/get-table-filter.component";
import { HeaderComponent } from "./components/header/header.component";
import { HighlightedTextComponent } from "./components/highlighted-text/highlighted-text.component";
import { ImageCarouselDialogComponent } from "./components/image-carousel-dialog/image-carousel-dialog.component";
import { InfoDialogComponent } from "./components/info-dialog/info-dialog.component";
import { LogoutControlComponent } from "./components/logout-control/logout-control.component";
import { MultiSelectDropdownComponent } from "./components/multi-select-dropdown/multi-select-dropdown.component";
import { NoResultsCardComponent } from "./components/no-results-card/no-results-card.component";
import { OverlayComponent } from "./components/overlay/overlay.component";
import { PaginatorComponent } from "./components/paginator/paginator.component";
import { PdfExportDialogComponent } from "./components/pdf-export-dialog/pdf-export-dialog.component";
import { SearchInputComponent } from "./components/search-input/search-input.component";
import { SeverityZoneIconComponent } from "./components/severity-zone-icon/severity-zone-icon.component";
import { SimpleTabsComponent } from "./components/simple-tabs/simple-tabs.component";
import { TabControlComponent } from "./components/tab-control/tab-control.component";
import { TabComponent } from "./components/tab/tab.component";
import { ColumnComponent } from "./components/table/column/column.component";
import { TableComponent } from "./components/table/table.component";
import { TimeoutDialogComponent } from "./components/timeout-dialog/timeout-dialog.component";
import { ZonarLogoComponent } from "./components/zonar-logo/zonar-logo.component";
import { permissions } from "./constants/internal-constants";
import { GenericLanguageMap } from "./i18next/generic-language.map";
import { LocalizedNumberPipe } from "./pipe/localized-number.pipe";
import { SplitAtFirstDelimiterPipe } from "./pipes/split-at-first-delimiter.pipe";
import { AccountAdminComponent } from "./views/account-admin/account-admin.component";
import { AssetDetailsComponent } from "./views/asset-details/asset-details.component";
import { ClosedIgnoredComponent } from "./views/asset-details/closed-ignored/closed-ignored.component";
import { OpenPendingComponent } from "./views/asset-details/open-pending/open-pending.component";
import { CompanySelectorComponent } from "./views/company-selector/company-selector.component";
import { DefaultLayoutComponent } from "./views/default-layout/default-layout.component";
import { DefectListComponent } from "./views/defect-list/defect-list.component";
import { InspectionDetailComponent } from "./views/inspection-detail/inspection-detail.component";
import { InspectionListViewComponent } from "./views/inspection-detail/inspection-list-view/inspection-list-view.component";
import { InspectionMapViewComponent } from "./views/inspection-detail/inspection-map-view/inspection-map-view.component";
import { InspectionHistoryComponent } from "./views/inspection-history/inspection-history.component";
import { InspectionsPrintViewComponent } from "./views/inspections-print-view/inspections-print-view.component";
import { MissingInspectionsComponent } from "./views/missing-inspections/missing-inspections.component";
import { RepairModalComponent } from "./views/repairs/modal/repair-modal.component";
import { RepairButtonsComponent } from "./views/repairs/repair-buttons/repair-buttons.component";
import { ResourcesPanelComponent } from "./views/resources-panel/resources-panel.component";
import { ZonarAdminAccountSelectionComponent } from "./views/zonar-admin-account-selection/zonar-admin-account-selection.component";

@NgModule({
	declarations: [
		AccountAdminComponent,
		ActionButtonComponent,
		ResourcesPanelComponent,
		AppComponent,
		AssetDetailsComponent,
		CharacterCounterComponent,
		ClosedIgnoredComponent,
		ColumnComponent,
		CompanySelectorComponent,
		CustomCalendarHeaderComponent,
		CustomDateRangeFilterComponent,
		DateRangeFilterComponent,
		DefaultLayoutComponent,
		DefectFilterComponent,
		DefectListComponent,
		ErrorsComponent,
		ExportComponent,
		FilterCardComponent,
		FooterComponent,
		FooterComponent,
		GetTableFilterComponent,
		HeaderComponent,
		HighlightedTextComponent,
		ImageCarouselDialogComponent,
		InfoDialogComponent,
		InspectionDetailComponent,
		InspectionHistoryComponent,
		InspectionListViewComponent,
		InspectionMapViewComponent,
		InspectionsPrintViewComponent,
		LocalizedNumberPipe,
		LogoutControlComponent,
		MissingInspectionsComponent,
		MultiSelectDropdownComponent,
		NoResultsCardComponent,
		OpenPendingComponent,
		OverlayComponent,
		PaginatorComponent,
		PdfExportDialogComponent,
		RepairButtonsComponent,
		RepairModalComponent,
		SearchInputComponent,
		SeverityZoneIconComponent,
		SimpleTabsComponent,
		SplitAtFirstDelimiterPipe,
		TabComponent,
		TabControlComponent,
		TableComponent,
		TimeoutDialogComponent,
		ZonarAdminAccountSelectionComponent,
		ZonarLogoComponent,
	],

	imports: [
		BrowserModule,
		HttpClientModule,
		AppRoutingModule,
		MaterialModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		NgxsModule.forRoot([
			DefectListState,
			InspectionHistoryState,
			InspectionState,
			AppState,
			AssetState,
			RepairState,
			MissingInspectionsState,
			ErrorState,
			LogState,
		]),
		NgxsReduxDevtoolsPluginModule.forRoot(),
		NgxsLoggerPluginModule.forRoot(),
		SatDatepickerModule,
		SatNativeDateModule,
		FlexLayoutModule,
		I18NextModule.forRoot(),
		AuthModule.forRoot({
			clientId: environment.auth0.CLIENT_ID,
			domain: environment.auth0.DOMAIN,
			audience: environment.auth0.AUDIENCE,
			redirect_uri: window.location.origin + "/inspection-list",
			scope: "openid profile email",
			useRefreshTokens: true,
			max_age: 36000,
			cacheLocation: "localstorage",
			httpInterceptor: {
				allowedList: [
					// core entity api base urls. these are used by the auth module to make calls to the user-api
					`${environment.environmentConstants.APP_ENDPOINT_CORE}/v1/*`,
					`${environment.environmentConstants.APP_ENDPOINT_CORE}/v1beta2/*`,
					`${environment.environmentConstants.APP_ENDPOINT_EVIR}/*`,
				],
			},
		}),
		ZonarUiAuthModule,
		ZonarUiPermissionsModule,
	],
	providers: [
		{
			provide: ErrorHandler,
			useClass: ErrorService,
		},
		I18N_PROVIDERS,
		{ provide: "applicationId", useValue: environment.environmentConstants.APP_APPLICATION_ID },
		{ provide: "defaultLanguage", useValue: environment.i18n.defaultLanguage },
		{ provide: "defaultPermPrefix", useValue: permissions.defaultPermPrefix },
		{ provide: "environment", useValue: environment.environment },
		{ provide: "genericLanguageMap", useValue: GenericLanguageMap },
		{ provide: "logoutReturnToUrl", useValue: environment.environmentConstants.APP_URL },
		{ provide: "pendoParams", useValue: environment.pendo },
		{ provide: "redirectUrl", useValue: permissions.defaultRedirectUrl },
		{ provide: "region", useValue: environment.region },
		{ provide: "routePermissions", useValue: permissions.routes },
		{ provide: "supportedLanguages", useValue: environment.i18n.supportedLanguages },
		{ provide: "useBrowserLanguage", useValue: true },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ZonarOwnerIdInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor() {}
}
