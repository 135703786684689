<div class="evir-header">
	<mat-toolbar>
		<div
			class="evir-header-container"
			fxLayout="row"
			fxLayoutAlign="space-between center"
		>
			<div
				fxFlex="70"
				class="logo-container"
			>
				<!-- Zonar logo -->
				<app-zonar-logo
					*ngIf="brandName === 'zonar'"
					fxLayout="column"
					fxLayoutAlign="center start"
					routerLink=""
					[logoTitle]="logoTitle"
				></app-zonar-logo>

				<!-- warning indicator that the build is not a production-build -->
				<div *ngIf="!productionBuild">🚚</div>
			</div>

			<!-- <div class='divider-bar' fxFlex></div> -->

			<app-logout-control
				fxFlex
				(logoutControl)="logoutControl.emit($event)"
				[userId]="userId"
				[userName]="userName"
				[userCompany]="userCompany"
				[userDivision]="userDivision"
			></app-logout-control>
		</div>
	</mat-toolbar>
</div>
