import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { ECompanyLoginMode, PermissionsService } from "@zonar-ui/auth";
import { ICompany, IDivision } from "@zonar-ui/auth/lib/models/company.model";
import { IUserGroupPolicy } from "@zonar-ui/auth/lib/models/user-group-policy.model";
import { Observable, Subject, combineLatest } from "rxjs";
import { map, startWith, take, takeUntil } from "rxjs/operators";
import { AppState, SetCurrentGroupPolicy, SetCurrentUserProfile, SetSelectedDivision } from "src/app/app.state";
import { HydratedUserPolicies, HydratedUserProfile, Role } from "src/app/models/core-api.models";
import { Id } from "src/app/models/openAPIAliases";
import { SearchBarGroup, SearchBarOption } from "src/app/models/search-bar.models";
import { ChangeCompanyService } from "src/app/services/change-company/change-company.service";
import { LocalStoreService } from "src/app/services/local-store/local-store.service";
import { LoggerService } from "src/app/services/logger.service";
import { SettingApiService, UserSettings } from "src/app/services/setting-api/setting-api.service";
import { Utils } from "src/app/utils";
import { environment } from "src/environments/environment";
import { setSettings } from "src/utils/settings/set-settings";
import { SearchTable } from "../get-table-filter/get-table-filter.component";

@Component({
	selector: "app-logout-control",
	templateUrl: "./logout-control.component.html",
	styleUrls: ["./logout-control.component.scss"],
	encapsulation: ViewEncapsulation.None,
	animations: [
		trigger("rotatedState", [
			state("down", style({ transform: "rotate(0)" })),
			state("up", style({ transform: "rotate(180deg)" })),
			transition("down <=> up", animate("225ms cubic-bezier(0.4,0.0,0.2,1)")),
		]),
	],
})
export class LogoutControlComponent implements OnInit, OnDestroy {
	@Input() accountSwitcherValues: Array<SearchBarGroup> = [];
	@Input() userId: string;
	@Input() userCompany: string;
	@Input() userDivision: string;
	@Input() userName: string;
	@Output() logoutControl = new EventEmitter<string>();

	@ViewChild("accountSwitcherMatAutoComplete") accountSwitcherMatAutoComplete: MatAutocomplete;
	@ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;

	@Select(AppState.selectHydratedUserProfiles) hydratedUserProfiles$: Observable<Array<HydratedUserProfile>>;
	@Select(AppState.selectHydratedUserPolicies) hydratedUserPolicies$: Observable<Array<HydratedUserPolicies>>;
	@Select(AppState.getSearchTables) searchTables$: Observable<Array<SearchTable>>;
	@Select(AppState.getCompanyDivisions) companyDivisions$: Observable<Array<IDivision>>;
	@Select(AppState.getActiveCompany) company$: Observable<ICompany>;

	accountSwitcherForm: UntypedFormGroup = this.formBuilder.group({
		accountSwitcherGroup: "",
	});
	accountSwitcherValues$: Observable<Array<SearchBarGroup>>;
	evirBrand = environment.brand.name === "zonar";
	loginMode: ECompanyLoginMode;
	onDestroy$ = new Subject<void>();
	rotateState = "down";
	showAccountSelectorOption = false;
	showCompanySelectorOption = false;
	userGroupPolicies: Array<IUserGroupPolicy> = [];
	userProfiles: Array<HydratedUserProfile> = [];

	constructor(
		private changeCompanyService: ChangeCompanyService,
		private formBuilder: UntypedFormBuilder,
		private localStoreService: LocalStoreService,
		private permissionsService: PermissionsService,
		private store: Store,
		public logger: LoggerService,
		public router: Router,
		private settingApiService: SettingApiService,
	) {}

	ngOnInit() {
		this.accountSwitcherValues$ = this.accountSwitcherForm.get("accountSwitcherGroup").valueChanges.pipe(
			startWith(""),
			map((value: string) => {
				return this.filterGroup(value);
			}),
		);

		// only show companies for non-zonar admins with more than one company
		combineLatest([this.permissionsService.getCompaniesUser(), this.permissionsService.getIsZonarUser()]).subscribe(
			([companies, isZonarUser]) => {
				this.showCompanySelectorOption = !isZonarUser && companies?.length > 1;
			},
		);

		this.permissionsService
			.getIsZonarUser()
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(isZonarUser => {
				if (isZonarUser !== undefined && isZonarUser !== null) {
					this.showAccountSelectorOption = isZonarUser;

					Utils.handleMultipleSelects(
						[this.permissionsService.getCurrentCompanyContext(), this.companyDivisions$],
						([companyContext, _]) => {
							if (companyContext) {
								// has company context
								this.accountSwitcherValues = [];
								this.loginMode = (
									companyContext as { id: string; name: string; loginMode: ECompanyLoginMode }
								).loginMode;
								this.loginMode === "GROUP_POLICY"
									? this.hydratedUserPolicies$
											.pipe(takeUntil(this.onDestroy$))
											.subscribe(policies => {
												if (policies) {
													this.userGroupPolicies = policies;
													this.accountSwitcherValues =
														this.createAccountSwitcherPoliciesValues(policies);
												}
											})
									: this.hydratedUserProfiles$
											.pipe(takeUntil(this.onDestroy$))
											.subscribe((userProfiles: HydratedUserProfile[]) => {
												if (userProfiles) {
													this.userProfiles = userProfiles;
													this.accountSwitcherValues =
														this.createAccountSwitcherProfileValues(userProfiles);
												}
											});
							}
						},
					);
				}
			});
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}

	/**
	 * Create the account switcher values based on the user group policies
	 * @param groupPolicies User Group Policies array
	 * @returns An array of SearchBarGroup elements
	 */
	createAccountSwitcherPoliciesValues(groupPolicies: Array<HydratedUserPolicies>): Array<SearchBarGroup> {
		const options: Array<SearchBarGroup> = [];

		this.logger.log(`Account switcher, received user policies: `);
		this.logger.log(JSON.stringify(groupPolicies));

		const userPoliciesByCompany = groupPolicies.reduce((previous, current) => {
			if (!previous[current.tenant.scope.companies[0].id]) {
				previous[current.tenant.scope.companies[0].id] = [];
			}
			previous[current.tenant.scope.companies[0].id].push(current);
			return previous;
		}, {});

		this.logger.log(`Account switcher, user policies by company: `);
		this.logger.log(JSON.stringify(userPoliciesByCompany));

		for (const company in userPoliciesByCompany) {
			const searchBarGroup: SearchBarGroup = {
				name: userPoliciesByCompany[company][0].company?.name,
				values: [],
			};

			userPoliciesByCompany[company]?.forEach((userPolicy: HydratedUserPolicies) => {
				if (userPolicy.tenant.scope.divisions && userPolicy.tenant.scope.divisions.length > 0) {
					userPolicy.tenant.scope.divisions?.forEach((currentDivision: IDivision) => {
						const division = userPolicy.allCompanyDivisions.find(companyDivision => {
							return companyDivision.id === currentDivision.id;
						});

						const tooltip = userPolicy.policy.grants
							.filter(
								grant => grant.application.id === environment.environmentConstants.APP_APPLICATION_ID,
							)
							.map(grant => {
								grant.roles.map(role => {
									if (role && role.name) {
										return role.name;
									} else {
										return "";
									}
								});
							})
							.join(", ");

						const divisionName = division.name;

						searchBarGroup.values.push({
							value: {
								userId: userPolicy.groupId,
								divisionId: currentDivision.id,
							},
							viewValue: divisionName,
							tooltip: tooltip,
						});
					});
				} else {
					userPolicy.allCompanyDivisions?.forEach((currentDivision: IDivision) => {
						const tooltip = userPolicy.policy.grants
							.filter(
								grant => grant.application.id === environment.environmentConstants.APP_APPLICATION_ID,
							)
							.map(grant => {
								grant.roles.map(role => {
									if (role && role.name) {
										return role.name;
									} else {
										return "";
									}
								});
							})
							.join(", ");

						const divisionName = currentDivision.name;

						searchBarGroup.values.push({
							value: {
								userId: userPolicy.groupId,
								divisionId: currentDivision.id,
							},
							viewValue: divisionName,
							tooltip: tooltip,
						});
					});
				}
			});

			this.logger.log(`Account switcher, search bar group before sorting: ` + JSON.stringify(groupPolicies));

			// sort alphabetically by view value
			searchBarGroup.values.sort((a, b) => {
				if (a.viewValue > b.viewValue) {
					return 1;
				}
				if (a.viewValue < b.viewValue) {
					return -1;
				}
				return 0;
			});

			// if there's a top-level division in the list, pull it to the top
			searchBarGroup.values.sort((a, b) => {
				if (a.viewValue === userPoliciesByCompany[company][0].company.name) {
					return -1;
				}
				if (b.viewValue === userPoliciesByCompany[company][0].company.name) {
					return 1;
				}
				return 0;
			});

			this.logger.log(`Account switcher, search bar group after sorting: ` + JSON.stringify(groupPolicies));

			options.push(searchBarGroup);
		}

		this.logger.log(`Account switcher, returning options: ` + JSON.stringify(groupPolicies));

		return options;
	}

	/**
	 * Create the account switcher values based on the user profiles
	 * @param userProfiles Array of HydratedUserProfile
	 * @returns Array of SearchBarGroup
	 */
	createAccountSwitcherProfileValues(userProfiles: Array<HydratedUserProfile>): Array<SearchBarGroup> {
		const options: Array<SearchBarGroup> = [];

		this.logger.log(`Account switcher, received user profiles: `);
		this.logger.log(JSON.stringify(userProfiles));

		const userProfilesByCompany = userProfiles.reduce(
			(output, current) => ({
				...output,
				[current.companyId]: [...(output[current.companyId] ?? []), current],
			}),
			{} as Record<string, Array<HydratedUserProfile>>,
		);

		this.logger.log(`Account switcher, user profiles by company: `);
		this.logger.log(JSON.stringify(userProfilesByCompany));

		for (const company in userProfilesByCompany) {
			const searchBarGroup: SearchBarGroup = {
				name: userProfilesByCompany[company][0].company?.name,
				values: [],
			};

			userProfilesByCompany[company]?.forEach((userProfile: HydratedUserProfile) => {
				if (userProfile.divisions && userProfile.divisions.length > 0) {
					userProfile.divisions?.forEach((divisionId: Id) => {
						const division = userProfile.allCompanyDivisions.find(companyDivision => {
							return companyDivision.id === divisionId;
						});

						const tooltip = userProfile.roles
							.map(currentRole => {
								const role = userProfile.application.roles.find((appRole: Role) => {
									return appRole.id === currentRole.id;
								});

								if (role && role.name) {
									return role.name;
								} else {
									return "";
								}
							})
							.join(", ");

						const userProfileCompanyName = userProfile.company.name;
						const divisionName = division.name;

						searchBarGroup.values.push({
							value: {
								userId: userProfile.id,
								divisionId: divisionId,
							},
							viewValue: divisionName.replace(`${userProfileCompanyName}:`, ""),
							tooltip: tooltip,
						});
					});
				} else {
					userProfile.allCompanyDivisions?.forEach(currentDivision => {
						const tooltip = userProfile.roles
							.map(currentRole => {
								const role = userProfile.application.roles.find((appRole: Role) => {
									return appRole.id === currentRole.id;
								});

								if (role && role.name) {
									return role.name;
								} else {
									return "";
								}
							})
							.join(", ");

						const userProfileCompanyName = userProfile.company.name;
						const divisionName = currentDivision.name;

						searchBarGroup.values.push({
							value: {
								userId: userProfile.id,
								divisionId: currentDivision.id,
							},
							viewValue: divisionName.replace(`${userProfileCompanyName}:`, ""),
							tooltip: tooltip,
						});
					});
				}
			});

			this.logger.log(`Account switcher, search bar group before sorting: ` + JSON.stringify(userProfiles));

			// sort alphabetically by view value
			searchBarGroup.values.sort((a, b) => {
				if (a.viewValue > b.viewValue) {
					return 1;
				}
				if (a.viewValue < b.viewValue) {
					return -1;
				}
				return 0;
			});

			// if there's a top-level division in the list, pull it to the top
			searchBarGroup.values.sort((a, b) => {
				if (a.viewValue === userProfilesByCompany[company][0].company.name) {
					return -1;
				}
				if (b.viewValue === userProfilesByCompany[company][0].company.name) {
					return 1;
				}
				return 0;
			});

			this.logger.log(`Account switcher, search bar group after sorting: ` + JSON.stringify(userProfiles));

			options.push(searchBarGroup);
		}

		this.logger.log(`Account switcher, returning options: ` + JSON.stringify(userProfiles));

		return options;
	}

	/**
	 * Handle the autocomplete option selected
	 * @param event Autocomplete selected event
	 */
	public handleOptionSelected(event: MatAutocompleteSelectedEvent): void {
		const userId = event.option.value.value.userId;
		const divisionId = event.option.value.value.divisionId;
		if (this.loginMode === "GROUP_POLICY") {
			const userPolicies = this.userGroupPolicies.find((policy: IUserGroupPolicy) => {
				return policy.groupId === userId;
			});
			this.store.dispatch(new SetCurrentGroupPolicy(userPolicies));
		} else {
			const userProfile = this.userProfiles.find((hydratedUserProfile: HydratedUserProfile) => {
				return hydratedUserProfile.id === userId;
			});
			this.store.dispatch(new SetCurrentUserProfile(userProfile));
		}

		setSettings(UserSettings.SELECTED_DIVISION, divisionId, null, this.settingApiService, true);

		this.store.dispatch(new SetSelectedDivision(divisionId));

		this.accountSwitcherForm.reset();
	}

	/**
	 * Filter the group (company) based on the filter value
	 * @param filterValue string to filter the group
	 * @returns Array of SearchBarGroup
	 */
	private filterGroup(filterValue: string): Array<SearchBarGroup> {
		if (filterValue) {
			return this.accountSwitcherValues
				.map((accountSwitcherValue: SearchBarGroup) => {
					return {
						name: accountSwitcherValue.name,
						values: this.filter(accountSwitcherValue.values, filterValue),
					};
				})
				.filter((accountSwitcherValue: SearchBarGroup) => {
					return accountSwitcherValue.values ? accountSwitcherValue.values.length > 0 : true;
				});
		}

		return this.accountSwitcherValues;
	}

	/**
	 * Filter the SearchBarOption values based on a string
	 * @param values Array of current SearchBarOption
	 * @param filterValue string to filter the locations
	 * @returns Array of SearchBarOption
	 */
	private filter(values: Array<SearchBarOption>, filterValue: string | SearchBarOption): Array<SearchBarOption> {
		if (!values) {
			return [];
		}

		// if filter value is SearchBarOption, it means the user clicked on an value, instead of typing a letter
		if (typeof filterValue === "string") {
			const filterValueLower = filterValue.toLowerCase();
			return values.filter(item => {
				// return item.viewValue.toLowerCase().indexOf(filterValueLower) === 0 // if we want the search to only apply if the value starts with the search term
				return item.viewValue.toLowerCase().includes(filterValueLower);
			});
		}
	}

	/**
	 * Rotate the arrow icon on the logout control
	 */
	rotate() {
		this.rotateState = this.rotateState === "down" ? "up" : "down";
	}

	/**
	 * Logout button handler
	 */
	logoutButtonHandler(): void {
		this.logoutControl.emit("logout");
	}

	/**
	 * Handle the autocomplete click
	 * @param event MouseEvent
	 */
	public handleAutoCompleteClick(event: MouseEvent): void {
		// prevent the menu from collapsing
		event.stopPropagation();

		this.accountSwitcherValues = [...this.accountSwitcherValues];

		this.accountSwitcherValues$ = this.accountSwitcherForm.get("accountSwitcherGroup").valueChanges.pipe(
			startWith(""),
			map((value: string) => {
				return this.filterGroup(value);
			}),
		);

		setTimeout(() => {
			this.trigger.openPanel();
		}, 0);
	}

	/**
	 * Switch to Zonar Admin Account Selection View
	 */
	switchToZonarAdminAccountSelectionView(): void {
		this.searchTables$.pipe(take(1)).subscribe(searchTables => {
			if (searchTables) {
				searchTables?.forEach(searchTable => {
					this.localStoreService.clear(searchTable.tableName);
				});
			}
		});
		this.router.navigate(["/zonar-admin-account-selection"]);
	}

	/**
	 * Company selector handler
	 */
	companySelector() {
		this.changeCompanyService
			.openChangeCompanyDialog(false /*hasDefaultCompany*/, this.userId, false)
			.subscribe(selectedCompanyInDialog => {
				if (selectedCompanyInDialog) {
					this.changeCompanyService.setSelectedCompany(selectedCompanyInDialog);
				}
			});
	}
}
