import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatSelect, MatSelectChange } from "@angular/material/select";
import { Select, Store } from "@ngxs/store";
import { PermissionsService } from "@zonar-ui/auth";
import { Observable } from "rxjs";
import { AppState, PatchSettings } from "src/app/app.state";
import { translateAndFormat } from "src/app/i18next";
import { RetentionTimeSettingsPayload } from "src/app/models/api-payloads.models";
import { environment } from "src/environments/environment";
import { Id, SettingsGet } from "../../models/openAPIAliases";
import { TabUpdaterService } from "../../services/tab-updater.service";

@Component({
	selector: "app-account-admin",
	templateUrl: "./account-admin.component.html",
	styleUrls: ["./account-admin.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class AccountAdminComponent implements OnInit, AfterViewInit {
	selectedValue: string;
	companyId: Id;
	settings: SettingsGet;

	// thoughts?
	optionsMap = {
		"1 Year": 365,
		"2 Years": 730,
		"3 Years": 1095,
		"4 Years": 1460,
		"5 Years": 1825,
		Indefinitely: 1000000000, // 1 billion
		365: "1 year",
		730: "2 years",
		1095: "3 years",
		1460: "4 years",
		1825: "5 years",
		1000000000: "indefinitely", // 1 billion
	};

	options: string[] = [
		translateAndFormat("1 year", "title"),
		translateAndFormat("2 years", "title"),
		translateAndFormat("3 years", "title"),
		translateAndFormat("4 years", "title"),
		translateAndFormat("5 years", "title"),
		translateAndFormat("indefinitely", "capitalize"),
	];

	@Select(AppState.selectSettings) settings$: Observable<SettingsGet>;

	@ViewChild("retentionTimeChangeSelect", { static: true }) retentionTimeChangeSelect: MatSelect;

	constructor(
		private tabUpdater: TabUpdaterService,
		private store: Store,
		private permissionsService: PermissionsService,
	) {}

	ngOnInit() {
		this.settings$.subscribe((settings: SettingsGet) => {
			if (settings) {
				this.settings = settings;

				// set the controls to whatever the current settings are
				this.selectedValue = this.optionsMap[settings.inspectionRetention];
			}
		});

		this.permissionsService.getCurrentCompanyContext().subscribe(currentCompany => {
			if (currentCompany) {
				this.companyId = currentCompany.id;
			}
		});
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.tabUpdater.onTabChange.emit({ tab: 3, subTab: -1 }); // Account Admin tab
		}, 0);
	}

	handleRetentionTimeChange(event: MatSelectChange): void {
		this.selectedValue = event.value;

		const payload: RetentionTimeSettingsPayload = {
			companyId: this.companyId,
			imageRetention: this.optionsMap[event.value],
			inspectionRetention: this.optionsMap[event.value],
		};

		this.store.dispatch(new PatchSettings(payload, environment.environmentConstants.APP_ENDPOINT_EVIR));
	}
}
