import { Injectable } from "@angular/core";

export interface LocaleConfig {
	links: {
		help: string;
		termsAndConditions?: string;
		dataProtectionAgreement: string;
	};
}

@Injectable({
	providedIn: "root",
})
export class LocaleConfigService {
	globalDefault: LocaleConfig = {
		links: {
			help: "https://support.zonarsystems.net",
			termsAndConditions:
				"https://support.zonarsystems.net/hc/en-us/articles/360038970832-EVIR-Mobile-Terms-of-Use",
			dataProtectionAgreement:
				"https://support.zonarsystems.net/hc/en-us/articles/360038968272-EVIR-Mobile-Privacy-Policy",
		},
	};

	getConfig(): LocaleConfig {
		return this.globalDefault;
	}
}
