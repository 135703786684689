import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PermissionsService } from "@zonar-ui/auth";
import { formatWord, translateAndFormat } from "src/app/i18next";
import { GlobalApiCallsService } from "src/app/services/global-api-calls.service";
import { InspectionDetailsViewModelService } from "src/app/services/inspection-details-view-model.service";
import { LanguageDictionaryService } from "src/app/services/language-dictionary/language-dictionary.service";
import { environment } from "src/environments/environment";
import type {
	InspectionDefectViewModel,
	InspectionDetailFormDataPhotosViewModel,
	InspectionDetailListViewViewModel,
	InspectionDetailStaticInfoViewModel,
} from "../../../models/inspection-detail-view-models.models";
import {
	InspectionAssetViewModel,
	InspectionZoneViewModel,
} from "../../../models/inspection-detail-view-models.models";
import { ConfigGet, Media } from "../../../models/openAPIAliases";
import { DefectListService } from "../../defect-list/service/defect-list.service";
import { ImageCarouselDialogComponent } from "./../../../components/image-carousel-dialog/image-carousel-dialog.component";

@Component({
	selector: "app-inspection-list-view",
	templateUrl: "./inspection-list-view.component.html",
	styleUrls: ["./inspection-list-view.component.scss"],
	encapsulation: ViewEncapsulation.None,
	animations: [
		// expandable rows example: https://material.angular.io/components/table/examples
		// added void: https://github.com/angular/components/issues/11990
		trigger("detailExpand", [
			state("collapsed, void", style({ height: "0px", minHeight: "0", display: "none" })),
			state("expanded", style({ height: "*", display: "table" })),
			transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
			transition("expanded <=> void", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
		]),
		trigger("rotatedState", [
			state("down", style({ transform: "rotate(0)" })),
			state("up", style({ transform: "rotate(180deg)" })),
			transition("down <=> up", animate("225ms cubic-bezier(0.4,0.0,0.2,1)")),
		]),
	],
})
export class InspectionListViewComponent implements OnInit {
	@Input() defectsOnly = true;
	@Input() formDataPhotos: Array<InspectionDetailFormDataPhotosViewModel> = [];
	@Input() staticInfo: Array<InspectionDetailStaticInfoViewModel>;
	@Input() viewModel: InspectionDetailListViewViewModel;
	@Input() viewModelError = false;
	@Input() viewModelErrorDetails = "";

	public closedStatus = translateAndFormat("closed", "uppercase");
	public photoLabel = translateAndFormat("photos", "uppercase");
	public prePopulatedIds: Array<{ defectId: string; inspectionId: string }> = [];
	public renderMap = [
		{
			label: translateAndFormat("mechanic", "uppercase"),
			value: "—",
		},
		{
			label: translateAndFormat("resolution", "uppercase"),
			value: "—",
		},
		{
			label: translateAndFormat("comments", "uppercase"),
			value: "—",
		},
	];
	public rotateState: string;
	public zoneComponents: Array<{
		zoneLayoutId: { id: string; version: number };
		zones: Array<{
			zone: string;
			components: Array<string>;
		}>;
	}> = [];
	public zoneDetailsLabel: Array<Array<string>> = [];

	constructor(
		private permissionsService: PermissionsService,
		public defectService: DefectListService,
		public dialog: MatDialog,
		public globalApiCallsService: GlobalApiCallsService,
		public inspectionDetailsViewModelService: InspectionDetailsViewModelService,
		public languageDictionaryService: LanguageDictionaryService,
	) {}

	ngOnInit() {
		this.permissionsService.getCurrentCompanyContext().subscribe(currentCompany => {
			if (currentCompany) {
				this.rotateState = "up";
				this.buildZoneComponents(this.viewModel.inspectionAssets, currentCompany.id);
			}
		});

		this.zoneDetailsLabel = new Array(this.viewModel.inspectionAssets.length).fill([]);
		this.viewModel.inspectionAssets.map((inspectionAsset, index) => {
			this.zoneDetailsLabel[index] = new Array(inspectionAsset.inspectionZones?.length).fill("more zone details");
		});
	}

	/**
	 * Update Zone details label
	 * @param assetIndex Asset index
	 * @param zoneIndex Zone index
	 */
	handleZoneClick(assetIndex: number, zoneIndex: number) {
		this.zoneDetailsLabel[assetIndex][zoneIndex] =
			this.zoneDetailsLabel[assetIndex][zoneIndex] === "more zone details"
				? "less zone details"
				: "more zone details";
	}

	/**
	 * Handle Defect Image Click
	 * @param defect Defect object
	 * @param i defect index
	 */
	handleImageClick(defect: InspectionDefectViewModel, i: number) {
		this.openImageCarouselDialog(defect.photos, i, translateAndFormat("defect photo", "title"));
	}

	/**
	 * Handle Inspection Image Click
	 * @param formDataPhoto inspection Photos object
	 * @param i defect index
	 */
	handleInspectionImageClick(formDataPhoto: InspectionDetailFormDataPhotosViewModel, i: number) {
		this.openImageCarouselDialog(formDataPhoto.images, i, formatWord(formDataPhoto.label, "title"));
	}

	/**
	 * Open Image Carousel Dialog
	 * @param media Array of photos
	 * @param i image index
	 */
	openImageCarouselDialog(media: Media[], i: number, title: string): void {
		this.dialog.open(ImageCarouselDialogComponent, {
			data: {
				title,
				media,
				imageIndex: i,
			},
		});
	}

	/**
	 * Rotate Inspection Detail Icon
	 */
	rotate() {
		this.rotateState = this.rotateState === "down" ? "up" : "down";
	}

	/**
	 * Expand or collapse animation
	 * @returns "expanded" or "collapsed" depending on the state of the asset
	 */
	expansionAnimation() {
		return this.rotateState === "up" ? "expanded" : "collapsed";
	}

	/**
	 * Get the Zone components of a specific zone layout
	 * @param zonelayoutId ID
	 * @returns an array of zone components
	 */
	getZoneComponents(zonelayoutId: string) {
		return this.zoneComponents.find(zoneComponent => zoneComponent.zoneLayoutId.id === zonelayoutId).zones;
	}

	/**
	 * Display the zone card if there are defects or if the user has selected to show all zones
	 * @param zone Current zone
	 * @returns True o False depending if the defect toggle is set to "defects only" and if the zone has defects
	 */
	showZoneCard(zone: InspectionZoneViewModel) {
		return this.defectsOnly ? zone.inspectionDefects : true;
	}

	/**
	 * Build a Zone-Component object from the config
	 * @param inspectionAssets Array of InspectionAssetViewModels
	 */
	private buildZoneComponents(inspectionAssets: Array<InspectionAssetViewModel>, companyId: string) {
		this.globalApiCallsService
			.getSingleConfig(
				inspectionAssets[0].inspectionConfig,
				companyId,
				environment.environmentConstants.APP_ENDPOINT_EVIR,
			)
			.subscribe((config: ConfigGet) => {
				if (config) {
					config.zoneLayouts.map(zoneLayout => {
						this.zoneComponents = [
							...this.zoneComponents,
							{
								zoneLayoutId: zoneLayout.zoneLayoutId,
								zones: zoneLayout.configZones.map(zone => {
									return {
										zone: this.languageDictionaryService.getTranslation(zone.tagLangKey),
										zoneLangKey: zone.tagLangKey,
										components: zone.tagComponents.map(tag =>
											this.languageDictionaryService.getTranslation(
												zoneLayout.components[tag].componentLangKey,
											),
										),
									};
								}),
							},
						];
					});

					const zoneLayoutIds = [
						...new Set(
							inspectionAssets.flatMap(inspectionAsset =>
								inspectionAsset.inspectionZones?.map(inspectionZone =>
									inspectionZone.zoneLayoutId.toLowerCase(),
								),
							),
						),
					];

					this.zoneComponents = this.zoneComponents.filter(zone =>
						zoneLayoutIds.includes(zone.zoneLayoutId.id.toLowerCase()),
					);

					this.viewModel.inspectionAssets = inspectionAssets.map(inspectionAsset => ({
						...inspectionAsset,
						inspectionZones:
							inspectionAsset.inspectionZones?.map(zone => ({
								...zone,
								componentsWithoutDefects: this.zoneComponents
									.find(
										zoneComponent =>
											zoneComponent.zoneLayoutId.id === zone.zoneLayoutId.toLowerCase(),
									)
									.zones.find(currentZone => currentZone.zone === zone.languageLabel)
									.components.filter(
										component =>
											!zone.inspectionDefects?.some(
												defect => defect.componentLabel === component,
											) ?? true,
									),
							})) ?? [],
					}));
				}
			});
	}
}
