import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { PermissionsService } from "@zonar-ui/auth";
import { Observable } from "rxjs";
import {
	InspectionDetailFormDataPhotosViewModel,
	InspectionDetailListViewViewModel,
	InspectionDetailStaticInfoViewModel,
} from "src/app/models/inspection-detail-view-models.models";
import { InspectionDetailsViewModelService } from "src/app/services/inspection-details-view-model.service";
import { PageInformation } from "src/app/services/previous-page.service";
import { environment } from "src/environments/environment";
import { AppState } from "../../app.state";
import { InspectionAsset, InspectionDetailData, InspectionGet, LangDictGet } from "../../models/openAPIAliases";
import { TabUpdaterService } from "../../services/tab-updater.service";
import { InspectionDetailStaticInfoSortedField } from "./../../models/inspection-detail-view-models.models";
import { ClearInspection, GetInspection, InspectionState } from "./state/inspection-detail.state";

export interface SingleInspectionListView {
	inspectionAssets: InspectionAsset[];
	inspectionDetailData?: InspectionDetailData[];
}

export interface SortedInspectionDetails {
	detailItemContent: string;
	detailItemField: string;
}

@Component({
	selector: "app-inspection-detail",
	templateUrl: "./inspection-detail.component.html",
	styleUrls: ["./inspection-detail.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class InspectionDetailComponent implements OnInit, OnDestroy, AfterViewInit {
	@Select(AppState.selectLanguageDictionary) languageDictionary$: Observable<LangDictGet>;
	@Select(AppState.selectPageInformation) pageInformation$: Observable<PageInformation>;
	@Select(InspectionState.getCoordinates) geolocation$: Observable<string>;
	@Select(InspectionState.getInspection) inspection$: Observable<InspectionGet>;
	@Select(InspectionState.getStaticInfo) staticInfo$: Observable<InspectionDetailStaticInfoViewModel>;

	public defectsOnly = true;
	public formDataPhotos: Array<InspectionDetailFormDataPhotosViewModel> = [];
	public geolocation: string = null;
	public inspectionId: string;
	public previousPageTitle: string = null;
	public previousPageUrl: string = null;
	public showGeolocationDivider = false;
	public sortedStaticInfo: Array<InspectionDetailStaticInfoSortedField> = [];
	public staticInfo: InspectionDetailStaticInfoViewModel;
	public viewModel: InspectionDetailListViewViewModel;
	public viewModelError = false;
	public viewModelErrorDetails = "";

	constructor(
		private route: ActivatedRoute,
		private store: Store,
		private tabUpdater: TabUpdaterService,
		private permissionsService: PermissionsService,
		public inspectionDetailsViewModelService: InspectionDetailsViewModelService,
	) {}

	ngOnInit() {
		this.inspectionId = this.route.snapshot.paramMap.get("inspection-uuid");

		// get the previous page title and url from the store
		this.pageInformation$.subscribe((pageInformation: PageInformation) => {
			this.previousPageTitle = pageInformation ? pageInformation.previousPageTitle : "";
			this.previousPageUrl = pageInformation ? pageInformation.previousPageUrl : "";
		});

		this.languageDictionary$.subscribe(langDict => {
			if (langDict) {
				if (this.inspectionId) {
					this.permissionsService.getCurrentCompanyContext().subscribe(currentCompany => {
						if (currentCompany) {
							this.store.dispatch(
								new GetInspection(
									this.inspectionId,
									currentCompany.id,
									environment.environmentConstants.APP_ENDPOINT_EVIR,
								),
							);
						}
					});
				}

				this.staticInfo$.subscribe((staticInfo: InspectionDetailStaticInfoViewModel) => {
					this.staticInfo = staticInfo;
					if (staticInfo) {
						this.sortedStaticInfo = this.inspectionDetailsViewModelService.getSortedStaticInfo(staticInfo);
					}
				});

				this.geolocation$.subscribe(geolocation => {
					this.geolocation = geolocation;
					if (geolocation !== null) {
						this.showGeolocationDivider = !this.showGeolocationDivider;
					}
				});

				this.inspection$.subscribe((inspection: InspectionGet) => {
					try {
						if (inspection) {
							this.viewModel =
								this.inspectionDetailsViewModelService.createInspectionDetailListViewViewModel(
									inspection,
								);
							this.formDataPhotos = this.inspectionDetailsViewModelService.getFormDataPhotos(inspection);
						}
					} catch (error) {
						console.log("Error: ");
						console.log(error);
						this.viewModelError = true;
						this.viewModelErrorDetails = error.toString();
					}
				});
			}
		});
	}

	// Make it so no tab is highlighted for this page
	ngAfterViewInit() {
		setTimeout(() => {
			this.tabUpdater.onTabChange.emit({ tab: -1, subTab: -1 });
		}, 0);
	}

	ngOnDestroy() {
		this.store.dispatch(new ClearInspection());
	}

	/**
	 * Toggle Defects Only value
	 */
	toggleFilter() {
		this.defectsOnly = !this.defectsOnly;
	}
}
