import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { Select } from "@ngxs/store";
import { PermissionsService } from "@zonar-ui/auth";
import { ICompany, IDivision } from "@zonar-ui/auth/lib/models/company.model";
import { IUser } from "@zonar-ui/auth/lib/models/user.model";
import { Observable, Subject } from "rxjs";
import { take } from "rxjs/operators";
import { translateAndFormat } from "src/app/i18next";
import { Utils } from "src/app/utils";
import { environment } from "src/environments/environment";
import { AppState } from "../../app.state";
import { TabUpdaterService } from "../../services/tab-updater.service";

export interface RouteLink {
	label: string;
	link?: string;
	subLabels?: ReadonlyArray<{ label: string; link: string }>;
}

@Component({
	selector: "app-default-layout",
	templateUrl: "./default-layout.component.html",
	styleUrls: ["./default-layout.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {
	accountSelectionPage = false;
	activeLinkIndex = -1;
	activeSubLinkIndex = -1;
	permissionsRetrieved = false;
	routeLinks: RouteLink[] = [];
	onDestroy$ = new Subject<void>();

	// for logout control
	userCompany = "";
	userDivision = "";
	userName = "";
	userId = "";

	@Select(AppState.selectUser) user$: Observable<IUser>;
	@Select(AppState.selectCompany) company$: Observable<ICompany>;
	@Select(AppState.selectDivision) division$: Observable<IDivision>;

	constructor(
		private auth: AuthService,
		private permissionsService: PermissionsService,
		public router: Router,
		public tabUpdater: TabUpdaterService,
	) {
		this.routeLinks = [
			{ label: translateAndFormat("inspection history", "uppercase"), link: "/inspection-list" },
			{
				label: translateAndFormat("reports", "uppercase"),
				subLabels: [
					{ label: translateAndFormat("defects", "capitalize"), link: "/defects" },
					{
						label: translateAndFormat("missing inspections", "capitalize"),
						link: "/missing-inspections",
					},
				],
			},
		];

		tabUpdater.onTabChange.subscribe(activeLink => {
			this.activeLinkIndex = activeLink.tab;
			this.activeSubLinkIndex = activeLink.subTab;
		});

		router.events.subscribe((event: RouterEvent) => {
			if (event instanceof NavigationEnd) {
				this.accountSelectionPage = event.urlAfterRedirects === "/zonar-admin-account-selection" ? true : false;
			}
		});
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}

	ngOnInit() {
		this.user$.subscribe((user: IUser) => {
			if (user) {
				this.userName = user.email;
				this.userId = user.id;
			}
		});

		Utils.handleMultipleSelects([this.company$, this.division$], ([company, division]) => {
			if (company) {
				this.userCompany = company.name;

				if (division) {
					this.userDivision = division.name;

					this.permissionsService
						.hasPermission(
							`${environment.environmentConstants.APP_APPLICATION_ID}:${company.id}:${division.id}:evir:read:account`,
						)
						.pipe(take(1))
						.subscribe(hasPermission => {
							if (hasPermission && !this.routeLinks.some(route => route.link === "/resources-panel")) {
								this.routeLinks = [
									...this.routeLinks,
									{
										label: translateAndFormat("resources", "uppercase"),
										link: "/resources-panel",
									},
								];
							}
						});

					this.permissionsService
						.getIsZonarUser()
						.pipe(take(1))
						.subscribe(isZonarUser => {
							if (isZonarUser && !this.routeLinks.some(route => route.link === "/account-admin")) {
								this.routeLinks = [
									...this.routeLinks,
									{
										label: translateAndFormat("account admin", "uppercase"),
										link: "/account-admin",
									},
								];
							}
						});
				}
			}
		});
	}

	logoutButtonHandler(): void {
		this.auth.logout({ returnTo: window.location.origin });
	}
}
