import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CoreCompanyApiService, PermissionsService } from "@zonar-ui/auth";
import { BehaviorSubject, Observable, Subscription, combineLatest, of, throwError } from "rxjs";
import { filter, switchMap, take, tap, withLatestFrom } from "rxjs/operators";
import { CompanySelectorComponent } from "src/app/views/company-selector/company-selector.component";
import { environment } from "src/environments/environment";
import { CacheCompaniesService } from "../cache-companies/cache-companies.service";
import {
	CompanyOption,
	OwnerType,
	SettingApiService,
	SettingParams,
	SettingStatus,
	UserSettings,
} from "../setting-api/setting-api.service";

const companyUuidValueRegExp = "(?<value>[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12})";
const companyTitleRegExp = "(?<title>.*)";

@Injectable({
	providedIn: "root",
})
export class ChangeCompanyService {
	isSingleCompanyUser = true;
	readonly width = "32.5rem";
	readonly panelClass = "change-company-dialog";
	companyOptionRegExp = new RegExp(`title:${companyTitleRegExp},value:${companyUuidValueRegExp}`);
	selectedCompanyId: string;
	selectedCompanyUserSettingId: string;
	/* selected company to subscribe */
	userLogging: any = {};
	changeCompanyDialogId = "change-company-dialog";
	selectedCompanySetting$: BehaviorSubject<CompanyOption> = new BehaviorSubject({} as CompanyOption);
	subscription = new Subscription();

	constructor(
		private cacheCompaniesService: CacheCompaniesService,
		private permissionsService: PermissionsService,
		private settingApiService: SettingApiService,
		private dialog: MatDialog,
		private companyApiService: CoreCompanyApiService,
	) {}

	showChangeCompanyDialog(hasDefaultCompany: boolean, disableBackdropClose = false): Observable<any> {
		return this.permissionsService.getCompanyMap().pipe(
			switchMap(companyMap => {
				if (typeof companyMap === "object") {
					return combineLatest([
						this.getSelectedCompany(),
						this.permissionsService.getUser(),
						this.permissionsService.getCompaniesUser(),
						this.permissionsService.getIsZonarUser(),
					]).pipe(
						filter(
							([settingCompany, user, companiesUser, _isZonarUser]) =>
								Boolean(settingCompany) && Boolean(user) && Boolean(companiesUser),
						),
						switchMap(([settingCompany, user, companiesUser, isZonarUser]) => {
							this.isSingleCompanyUser = !isZonarUser && companiesUser.length === 1;
							if (this.isSingleCompanyUser) {
								this.permissionsService.setCurrentCompanyContextById(companiesUser[0]);
							}
							if (!settingCompany[0] && !this.isSingleCompanyUser) {
								this.openChangeCompanyDialog(
									hasDefaultCompany,
									user.id,
									disableBackdropClose,
								).subscribe(selectedCompanyInDialog => {
									if (selectedCompanyInDialog) {
										this.setSelectedCompany(selectedCompanyInDialog);
									}
								});
							} else if (settingCompany[0]) {
								this.selectedCompanyId = this.retrieveCompanyOption(settingCompany[0].value).value;
							}
							return this.cacheCompaniesService.getCompanies();
						}),
					);
				}
				return of(null);
			}),
		);
	}

	openChangeCompanyDialog(hasDefaultCompany: boolean, userId?: string, disableClose = false): Observable<any> {
		if (this.dialog.getDialogById(this.changeCompanyDialogId)) {
			return of(null);
		}
		const changeCompanyDialogRef = this.dialog.open(CompanySelectorComponent, {
			width: this.width,
			height: "auto",
			panelClass: this.panelClass,
			id: this.changeCompanyDialogId,
			data: {
				hasDefaultCompany,
				userId,
				disableClose, // to remove/add close button on change-company dialog
			},
			disableClose,
		});

		return changeCompanyDialogRef.afterClosed();
	}

	setSelectedCompany(incomingCompany: CompanyOption) {
		if (incomingCompany?.value) {
			if (this.selectedCompanyId !== incomingCompany.value) {
				this.selectedCompanyId = incomingCompany.value;
				this.cacheCompaniesService.selectedCompany$.next(incomingCompany.value);
				const selectedCompany: { name: string; id: string } = {
					name: incomingCompany.title,
					id: incomingCompany.value,
				};
				this.cacheCompaniesService.selectedCompanyOption$.next(selectedCompany);
			}
		}
	}

	getSelectedCompany() {
		return this.permissionsService
			.getUser()
			.pipe(
				filter(user => Boolean(user)),
				switchMap(user => {
					this.userLogging = user;
					const url = new URL(environment.environmentConstants.APP_ENDPOINT_SETTINGS);
					const params = new URLSearchParams({
						ownerId: this.userLogging.id,
						name: UserSettings.SELECTED_COMPANY,
						ownerType: OwnerType.USER,
						status: SettingStatus.ACTIVE,
					});

					params.forEach((value, key) => url.searchParams.append(key, value));
					return this.settingApiService.getSetting(url.toString());
				}),
			)
			.pipe(
				tap(userSettingResponse => {
					if (userSettingResponse[0]) {
						// store the user id to patch later
						this.selectedCompanyUserSettingId = userSettingResponse[0].id;
						const companySelectedSetting = this.retrieveCompanyOption(userSettingResponse[0].value);
						this.setSelectedCompany(companySelectedSetting);
						this.permissionsService.setCurrentCompanyContextById(companySelectedSetting.value);
					}
				}),
			);
	}

	public convertCompanyOptionToString(companyOpt: CompanyOption): string {
		return companyOpt ? `title:${companyOpt.title},value:${companyOpt.value}` : "";
	}

	public retrieveCompanyOption(value: string): CompanyOption {
		const option: CompanyOption = {
			title: "",
			value: "",
		};
		if (value) {
			option.title = value.match(this.companyOptionRegExp)?.groups?.title;
			option.value = value.match(this.companyOptionRegExp)?.groups?.value;
		} else {
			return undefined;
		}
		return option;
	}

	public changeSelectedCompany(companyId: string) {
		let validCompanyChanging = true;
		this.subscription = this.cacheCompaniesService.sidenavFetchingUserInformation$.subscribe(data => {
			if (!data || data.fetchingUserInformation) {
				validCompanyChanging = false;
			}

			if (this.selectedCompanyId === companyId) {
				validCompanyChanging = false;
			}

			if (validCompanyChanging) {
				if (data.isZonarUser) {
					this.companyApiService.getCompany(companyId).subscribe(company => {
						this.handleSettingApi({ title: company.name, value: company.id });
					});
				} else {
					// map input companyId to companiesCache to get company option
					const companyOpt = this.cacheCompaniesService.companiesCache.find(company => {
						return company.value === companyId;
					});
					if (!companyOpt) {
						console.log("Target company is not available for current user!");
						return;
					}
					this.handleSettingApi(companyOpt);
				}
			}
			this.subscription.unsubscribe();
		});
	}

	handleSettingApi(companyOpt) {
		const companyValue = this.convertCompanyOptionToString(companyOpt);
		const params: SettingParams = {
			value: companyValue,
		};

		let settingRequest;
		if (this.selectedCompanyUserSettingId) {
			settingRequest = this.settingApiService.updateSetting(this.selectedCompanyUserSettingId, params);
		} else {
			params.ownerId = this.userLogging.id;
			params.name = UserSettings.SELECTED_COMPANY;
			params.ownerType = OwnerType.USER;
			params.status = SettingStatus.ACTIVE;
			settingRequest = this.settingApiService.createSetting(params);
		}
		this.handleSettingApiResponse(settingRequest, companyOpt);
	}

	handleSettingApiResponse(settingObservable: Observable<any>, companyOpt: CompanyOption) {
		settingObservable.pipe().subscribe(
			apiResponse => {
				if (apiResponse.ok) {
					// handle close event for higher component call to open dialog
					this.selectedCompanySetting$.next(companyOpt);
					// handle current company context in permission service
					this.permissionsService
						.getCompanyMap()
						.pipe(take(1), withLatestFrom(this.permissionsService.getIsZonarUser()))
						.subscribe(([companies, isZonar]) => {
							if (!isZonar) {
								this.permissionsService.setCurrentCompanyContext(companies[companyOpt.value]);
							}
						});
					// handle selected company in this service from the dialog close event
					this.setSelectedCompany(companyOpt);
				} else {
					throwError(apiResponse);
				}
			},
			error => {
				console.log("Target company not loading!", error);
			},
		);
	}
}
