import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Select } from "@ngxs/store";
import pdfMake from "pdfmake/build/pdfmake";
import { Content, ContextPageSize, TDocumentDefinitions } from "pdfmake/interfaces";
import { Observable } from "rxjs";
import { AppState } from "src/app/app.state";
import { translateAndFormat } from "src/app/i18next";
import { formatDate } from "src/app/i18next/formatDate";
import { ExportType } from "src/app/models/exportType";
import { ClosedDefectTableViewModel, OpenDefectTableViewModel } from "src/app/models/open-defect-table.models";
import { ConfigGet, InspectionGet } from "src/app/models/openAPIAliases";
import { ValueVector } from "src/app/models/value-vector.model";
import { Utils } from "src/app/utils";
import { InspectionHistoryService } from "src/app/views/inspection-history/service/inspection-history.service";
import {
	GpsMissingInspectionViewModel,
	MissingInspectionsAssetViewModel,
	MissingInspectionsInspectorViewModel,
} from "src/app/views/missing-inspections/missing-inspections.component";
import { environment } from "src/environments/environment";
import { getSeverity } from "src/utils/getSeverity";
import { newDate } from "src/utils/newDate";
import { timeDifference } from "src/utils/timeDifference";
import { BooleanTransformService } from "../boolean-transform.service";
import { LanguageDictionaryService } from "../language-dictionary/language-dictionary.service";
import { LocaleService } from "../locale/locale.service";

pdfMake.fonts = {
	...pdfMake.fonts,
	Roboto: {
		normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
		bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
		italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
		bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
	},
};

type MissingInspectionsFocus = "inspectors" | "assets" | "assets-gps";

const pdfConfig = (title: string) => ({
	pageSize: "LEGAL",
	pageOrientation: "landscape",
	header: (currentPage: number, pageCount: number, pageSize: ContextPageSize) =>
		[
			{ text: `${translateAndFormat(title, "title")} (Page ${currentPage} of ${pageCount})`, style: "header" },
			{
				alignment: currentPage % 2 ? "right" : "left",
				style: "header",
				text: `${currentPage} of ${pageCount}`,
			},
			{ canvas: [{ type: "rect", x: 170, y: 32, w: pageSize.width - 170, h: 40 }] },
		] as Content,
	footer: (currentPage: number, pageCount: number) =>
		({
			text: `${currentPage.toString()} of ${pageCount}`,
			style: "footer",
		} as Content),
	styles: {
		document: {
			fontSize: 12,
		},
		header: {
			alignment: "center",
			bold: true,
			fontSize: 18,
			margin: 2,
		},
		footer: {
			alignment: "right",
			font: "Roboto",
			fontSize: 10,
		},
	},
});

@Injectable({
	providedIn: "root",
})
export class ExportService {
	@Select(AppState.selectConfigs) configs$: Observable<ConfigGet[]>;

	constructor(
		private booleanTransformService: BooleanTransformService,
		private inspectionListService: InspectionHistoryService,
		private localeService: LocaleService,
		private languageDictionaryService: LanguageDictionaryService,
	) {}

	public exportInspectionData(inspectionIds: ReadonlyArray<string>, companyId: string, exportType: ExportType) {
		this.inspectionListService
			.getSingleInspections(inspectionIds, companyId, environment.environmentConstants.APP_ENDPOINT_EVIR)
			.subscribe((singleInspections: Array<HttpResponse<InspectionGet>>) => {
				singleInspections.sort((a, b) => inspectionIds.indexOf(a.body.id) - inspectionIds.indexOf(b.body.id));

				const data = singleInspections.map(({ body: inspection }: HttpResponse<InspectionGet>) => {
					const customData = inspection.inspectionDetail.inspectionDetailData.map(
						({
							inspectionDetailContent,
							inspectionDetailDataName,
							inspectionDetailSelectedUnit,
							inspectionDetailUnitConversionPair,
						}) =>
							[
								`${this.languageDictionaryService.getTranslation(
									inspectionDetailDataName.languageKey,
								)} ${this.unitToDisplayInHeader(
									inspectionDetailContent,
									inspectionDetailSelectedUnit,
									inspectionDetailUnitConversionPair,
								)}`,
								this.booleanTransformService.booleanTransformData(
									inspectionDetailContent,
									inspectionDetailUnitConversionPair,
								) ?? "",
							] as const,
					);

					const inspectionData = [
						["severity", this.severity(inspection.maxSeverity)],
						["date", formatDate(this.getStartTime(inspection), "P")],
						["time", formatDate(this.getStartTime(inspection), "pp")],
						["duration", this.inspectionDuration(inspection)],
						["asset number", inspection.inspectionAssets[0].asset.assetName],
						[
							"asset type",
							this.languageDictionaryService.getTranslation(
								inspection.inspectionAssets[0].zoneLayoutName.languageKey,
							),
						],
						[
							"insp type",
							this.languageDictionaryService.getTranslation(
								inspection.inspectionDetail.inspectionDetailName.languageKey,
							),
						],
						["inspector", `${inspection.inspectorFirstName} ${inspection.inspectorLastName}`],
						["asset location", inspection?.inspectionAssets?.[0]?.asset.assetDivisions?.[0]?.divisionName],
					].map(([key, value]) => [translateAndFormat(key, "capitalize"), value ?? ""] as const);

					return Object.fromEntries([...inspectionData, ...customData]);
				});

				const fileName = translateAndFormat("inspection details", "camelCase");
				const columns = [...new Set(data.flatMap(Object.keys))];
				const patchedData = data.map(row =>
					Object.fromEntries(columns.map(column => [column, row[column] ?? ""] as const)),
				);

				exportType === ExportType.CSV
					? this.exportCSV(patchedData, fileName)
					: this.exportPDF(patchedData, fileName, "inspection details");
			});
	}

	public exportDefectData(
		defects: ReadonlyArray<OpenDefectTableViewModel | ClosedDefectTableViewModel>,
		status: "open" | "closed",
		exportType: ExportType,
	) {
		const data = defects.map(defect => {
			const defectData = [
				status === "open"
					? ["severity", this.severity(defect.severity)]
					: ["severity", `${this.severity(defect.severity)} > ${translateAndFormat("none", "capitalize")}`],
				status === "open"
					? ["date", (defect as OpenDefectTableViewModel).lastInspectedDate]
					: ["repaired date", (defect as ClosedDefectTableViewModel).repairedDate],
				status === "open"
					? ["time", (defect as OpenDefectTableViewModel).lastInspectedTime]
					: ["time", (defect as ClosedDefectTableViewModel).repairedTime],
				["asset number", defect.reconciledAssetName],
				["asset location", defect.assetLocation],
				["zone", defect.zoneLabel],
				["component", defect.componentLabel],
				["condition", defect.conditionLabel],
			].map(([key, value]) => [translateAndFormat(key, "capitalize"), value ?? ""] as const);

			return Object.fromEntries([...defectData]);
		});

		const fileName = translateAndFormat("defect details", "camelCase");
		const columns = [...new Set(data.flatMap(Object.keys))];
		const patchedData = data.map(row =>
			Object.fromEntries(columns.map(column => [column, row[column] ?? ""] as const)),
		);

		exportType === ExportType.CSV
			? this.exportCSV(patchedData, fileName)
			: this.exportPDF(patchedData, fileName, "defect details");
	}

	public exportMissingInspectionData(
		missingInspections: ReadonlyArray<
			MissingInspectionsInspectorViewModel | MissingInspectionsAssetViewModel | GpsMissingInspectionViewModel
		>,
		focus: MissingInspectionsFocus,
		exportType: ExportType,
	) {
		const data = missingInspections.map(missingInspection => {
			return Object.fromEntries(this.generateMissingInspectionsDataRow(focus, missingInspection));
		});

		const fileName = translateAndFormat("missing inspections", "camelCase");
		const columns = [...new Set(data.flatMap(Object.keys))];
		const patchedData = data.map(row =>
			Object.fromEntries(columns.map(column => [column, row[column] ?? ""] as const)),
		);

		exportType === ExportType.CSV
			? this.exportCSV(patchedData, fileName)
			: this.exportPDF(patchedData, fileName, "missing inspections");
	}

	public exportCSV(data: ReadonlyArray<Record<string, string>>, fileName) {
		const formattedData = data.map(element =>
			Object.fromEntries(Object.entries(element).map(([key, value]) => [key, `"${value}"`])),
		);
		const BOM = "\uFEFF"; // Byte Order Mark

		this.download({
			blob: new Blob(
				[
					BOM +
						[[...Object.keys(data[0])], ...formattedData.map(element => Object.values(element))]
							.map(element => element.join(","))
							.join("\n"),
				],
				{ type: "text/csv;charset=utf-8" },
			),
			fileName,
			type: "csv",
		});
	}

	public exportPDF(data: ReadonlyArray<Record<string, string>>, fileName: string, title: string) {
		pdfMake
			.createPdf({
				...pdfConfig(title),
				content: {
					table: {
						headerRows: 1,
						widths: new Array(Object.keys(data[0]).length).fill("auto"),
						body: [
							// Table headers
							Object.keys(data[0]).map(text => ({ text, bold: true })),
							// Table data
							...data.map(element => Object.values(element).map(text => ({ text, font: "Roboto" }))),
						],
					},
				},
			} as TDocumentDefinitions)
			.getBlob(blob => this.download({ blob, fileName, type: "pdf" }));
	}

	private download = ({ blob, fileName, type }: { blob: Blob; fileName: string; type: string }) =>
		Object.assign(document.createElement("a"), {
			download: `${fileName}.${type}`,
			href: URL.createObjectURL(blob),
		}).click();

	private getStartTime(inspection: InspectionGet): Date {
		const relevantInspectionInfo = inspection.inspectionInfo.find(({ action }) => action === "start");

		return relevantInspectionInfo !== undefined ? newDate(relevantInspectionInfo.telemetry.timestamp) : null;
	}

	private severity(maxSeverity: number) {
		return getSeverity(maxSeverity) || translateAndFormat("no defects", "title");
	}

	private generateMissingInspectionsDataRow(
		focus: MissingInspectionsFocus,
		data: MissingInspectionsInspectorViewModel | MissingInspectionsAssetViewModel | GpsMissingInspectionViewModel,
	) {
		return [
			[
				focus === "assets-gps" ? "start date / time" : "date",
				focus === "assets-gps" ? data.startTime.join(" ") : data.startTime,
			],
			...(focus === "inspectors"
				? ([
						["inspector", (data as MissingInspectionsInspectorViewModel).inspector],
						["inspection name", (data as MissingInspectionsInspectorViewModel).inspection.name],
						["inspection type", (data as MissingInspectionsInspectorViewModel).inspection.type.join(" ")],
						["home location", (data as MissingInspectionsInspectorViewModel).division],
				  ] as const)
				: focus === "assets-gps"
				? ([
						["asset id", (data as GpsMissingInspectionViewModel).asset.name],
						["asset type", (data as GpsMissingInspectionViewModel).zoneLayout],
						["inspection name", (data as GpsMissingInspectionViewModel).inspection.name],
						["inspection type", (data as GpsMissingInspectionViewModel).inspection.type.join(" ")],
						["home location", (data as GpsMissingInspectionViewModel).division],
				  ] as const)
				: ([
						["asset number", (data as MissingInspectionsAssetViewModel).asset.name],
						["asset type", (data as MissingInspectionsAssetViewModel).asset.type],
						["inspection name", (data as MissingInspectionsAssetViewModel).inspection.name],
						["inspection type", (data as MissingInspectionsAssetViewModel).inspection.type.join(" ")],
						["home location", (data as MissingInspectionsAssetViewModel).division],
				  ] as const)),
		].map(([key, value]) => [translateAndFormat(key, "capitalize"), value ?? ""]) as ReadonlyArray<
			[key: string, value: string]
		>;
	}

	private unitToDisplayInHeader(
		data: string | ValueVector,
		selectedUnit: string,
		unitsConversionPair?: ReadonlyArray<string>,
	) {
		const unitsConversionPairDefined = unitsConversionPair !== undefined;

		return unitsConversionPairDefined || typeof data !== "string"
			? `(${
					unitsConversionPairDefined
						? unitsConversionPair[Utils.findUnitIndex(this.localeService, unitsConversionPair)]
						: selectedUnit
			  })`
			: "";
	}

	private inspectionDuration(inspection: InspectionGet) {
		const start = newDate(inspection.inspectionInfo.find(info => info.action === "start").telemetry.timestamp);
		const end = newDate(inspection.inspectionInfo.find(info => info.action === "end").telemetry.timestamp);
		const timeDiff = timeDifference(end)(start);

		return `${timeDiff.hours}:${timeDiff.minutes}:${timeDiff.seconds}`;
	}
}
