import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent {
	@Input() userId: string;
	@Input() userName: string;
	@Input() userCompany: string;
	@Input() userDivision: string;
	@Output() logoutControl: EventEmitter<Event> = new EventEmitter<Event>();
	public productionBuild = environment.production;

	public brandName = environment.brand.name;

	public logoTitle = "EVIR";
}
