import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HydratedUserProfile } from "src/app/models/core-api.models";
import { RequestService } from "src/app/services/request/request.service";
import { RepairRequest, RepairResolutionsResponseInner, RepairResponse } from "../../../models/openAPIAliases";

@Injectable({
	providedIn: "root",
})
export class RepairService {
	public repairEndpoint = "/defects";
	public resolutionEndpoint = "/repair-resolutions";
	public userProfileEndpoint = "/userprofiles";

	constructor(public requestService: RequestService) {}

	public getMechanics(companyId: string, roleId: string, endpoint: string): Observable<Array<HydratedUserProfile>> {
		return this.requestService.get<Array<HydratedUserProfile>>({
			url: `${endpoint}${this.userProfileEndpoint}?companyId=${companyId}&roleId=${roleId}&embed=user`,
			httpOptions: {
				headers: {
					"expand-all-divisions": "true",
				},
			},
		});
	}

	public getResolution(companyId: string, endpoint: string): Observable<RepairResolutionsResponseInner[]> {
		return this.requestService.get<RepairResolutionsResponseInner[]>({
			url: `${endpoint}${this.resolutionEndpoint}?companyId=${companyId}`,
			httpOptions: {},
		});
	}

	public postRepairStatus(repair: RepairRequest, defectId: string, endpoint: string): Observable<RepairResponse> {
		return this.requestService.post<RepairResponse>({
			url: `${endpoint}${this.repairEndpoint}/${defectId}/repairs`,
			data: repair,
		});
	}
}
