import { Injectable } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { ICompany, IDivision } from "@zonar-ui/auth/lib/models/company.model";
import { IUserGroupPolicy } from "@zonar-ui/auth/lib/models/user-group-policy.model";
import { IUserProfile } from "@zonar-ui/auth/lib/models/user-profile.model";
import { IUser } from "@zonar-ui/auth/lib/models/user.model";
import { tap } from "rxjs/operators";
import { SetNavState } from "./components/errors/state/error.state";
import { SearchTable } from "./components/get-table-filter/get-table-filter.component";
import { navErrorConstants } from "./constants/error-constants";
import { Application, HydratedUserPolicies, HydratedUserProfile } from "./models/core-api.models";
import {
	Asset,
	ConfigGet,
	EvirDivisionsGetInner,
	Id,
	InspectionNames,
	InspectionTypes,
	InspectionTypesResponse,
	InspectorsInner,
	LangDictGet,
	SettingsGet,
	ZoneLayouts,
} from "./models/openAPIAliases";
import { GlobalApiCallsService } from "./services/global-api-calls.service";
import { LoadTableRequest } from "./services/local-store/local-store.service";
import { UserNotification } from "./services/notification/notification.service";
import { PageInformation } from "./services/previous-page.service";
import { SettingsService } from "./services/settings.service";
import { Utils } from "./utils";

export interface AppStateModel {
	activeCompany: ICompany;
	activeDivision: IDivision;
	application: Application;
	assets: Asset[];
	assetTypeImage: SafeHtml;
	assetTypes: ZoneLayouts;
	company: ICompany;
	companyDivisions: Array<IDivision>;
	companyHasTCU: boolean;
	companyId: Id; // in lieu of selectedUserProfile, to allow for the zonar admin auth flow
	config: ConfigGet;
	configs: ConfigGet[];
	coreApiDivisions: Array<IDivision>;
	division: IDivision;
	divisions: EvirDivisionsGetInner[];
	groupPolicies: Array<IUserGroupPolicy>;
	hydratedUserPolicies: Array<HydratedUserPolicies>;
	hydratedUserProfiles: Array<HydratedUserProfile>;
	inspectionNames: InspectionNames;
	inspectionTypes: InspectionTypes;
	inspectors: InspectorsInner[];
	languageDictionary: LangDictGet;
	loadTableRequest: LoadTableRequest;
	notification: UserNotification;
	pageInformation: PageInformation;
	searchTable: Array<SearchTable>;
	selectedDivisionId: string;
	selectedGroupPolicy: IUserGroupPolicy;
	selectedUserProfile: IUserProfile; // this will always be null for a zonar admin
	settings: SettingsGet;
	user: IUser;
	userProfiles: Array<IUserProfile>;
}

export class SetSearchTable {
	static readonly type = "[App] Set Search Table";
	constructor(public searchTable: Array<SearchTable>) {}
}

export class SetUserObject {
	static readonly type = "[App] Set User Object";
	constructor(public user: IUser) {}
}

export class SetUserProfiles {
	static readonly type = "[App] Set User Profiles";
	constructor(public userProfiles: Array<IUserProfile>) {}
}

export class SetGroupPolicies {
	static readonly type = "[App] Set User Profiles";
	constructor(public groupPolicies: Array<IUserGroupPolicy>) {}
}

export class SetHydratedUserGroupPolicies {
	static readonly type = "[App] Set Hydrated User Group Policies";
	constructor(public userPolicies: Array<IUserGroupPolicy>, public companyEndpoint: string) {}
}

export class SetHydratedUserProfiles {
	static readonly type = "[App] Set Hydrated User Profiles";
	constructor(
		public user: IUser,
		public userProfiles: Array<IUserProfile>,
		public companyEndpoint: string,
		public applicationEndpoint: string,
	) {}
}

export class SetCurrentUserProfile {
	static readonly type = "[App] Set Current User Profile";
	constructor(public userProfile: IUserProfile) {}
}

export class SetCurrentGroupPolicy {
	static readonly type = "[App] Set Current Group Policy";
	constructor(public groupPolicy: IUserGroupPolicy) {}
}

export class SetSelectedDivision {
	static readonly type = "[App] Set Selected Division";
	constructor(public divisionId: string) {}
}

export class GetDivision {
	static readonly type = "[App] Set Selected Division Name";
	constructor(public companyId: string, public divisionId: string, public endpoint: string) {}
}

export class GetCompanyObject {
	static readonly type = "[App] Get Company Object";
	constructor(public companyId: string, public endpoint: string) {}
}

export class SetCompanyHasTCU {
	static readonly type = "[App] Set if Company has TCU";
	constructor(public companyHasTCU: boolean) {}
}

export class GetApplicationObject {
	static readonly type = "[App] Get Application Object";
	constructor(public applicationId: string, public endpoint: string) {}
}

export class GetLanguageDictionary {
	static readonly type = "[App] Get Language Dictionary";
	constructor(public companyId: string, public endpoint: string) {}
}

export class GetInspectionTypes {
	static readonly type = "[App] Get Inspection Types Object";
	constructor(public companyId: string, public endpoint: string) {}
}

export class GetAssetTypes {
	static readonly type = "[App] Get Asset Types Object";
	constructor(public companyId: string, public endpoint: string) {}
}

export class GetAssets {
	static readonly type = "[App] Get Assets";
	constructor(public companyId: string, public divisionId: string, public endpoint: string) {}
}

export class GetInspectors {
	static readonly type = "[App] Get Inspectors";
	constructor(public companyId: string, public divisionId: string, public endpoint: string) {}
}

export class GetDivisions {
	static readonly type = "[App] Get Divisions";
	constructor(public companyId: string, public divisionId: string, public endpoint: string) {}
}

export class SetCompanyDivisions {
	static readonly type = "[App] Set Company Divisions";
	constructor(public companyDivisions: Array<IDivision>) {}
}

export class GetCoreApiDivisions {
	static readonly type = "[App] Get Core API Divisions";
	constructor(public divisionName: string, public endpoint: string) {}
}

export class GetSettings {
	static readonly type = "[App] GET Settings";
	constructor(public companyId: string, public endpoint: string) {}
}

export class PatchSettings {
	static readonly type = "[App] PATCH Settings";
	constructor(public payload: unknown, public endpoint: string) {}
}

export class GetConfig {
	static readonly type = "[App] GET Config";
	constructor(public companyId: string, public endpoint: string) {}
}

export class GetConfigs {
	static readonly type = "[App] GET Configs";
	constructor(
		public configIds: string[],
		public companyId,
		public endpoint: string,
		public queryStrings: string = "",
	) {}
}

export class GetAssetTypeImage {
	static readonly type = "[App] GET Asset Type Image";
	constructor(public companyId: string, public assetViewId: string, public endpoint: string) {}
}

export class SetPageInformation {
	static readonly type = "[App] Set Page Information";
	constructor(public pageInformation: PageInformation) {}
}

export class SetCompanyAndDivisionId {
	static readonly type = "[App] Set Company and Division ID";
	constructor(public companyId: Id, public divisionId: Id) {}
}

export class GetActiveGtcCompany {
	static readonly type = "[App] Get Active GTC Account";
	constructor(public legacyAccountCode: string, public endpoint: string, public userProfileID: string) {}
}

export class GetActiveGtcDivision {
	static readonly type = "[App] Get Active GTC Division";
	constructor(public legacyAccountCode: string, public endpoint: string, public userID: string) {}
}

export class RequestLoadTable {
	static readonly type = "[LocalStoreService] Request Table Load";
	constructor(public loadTableRequest: LoadTableRequest) {}
}
export class ClearLoadTableRequest {
	static readonly type = "[DbLoaderService] Clear the current Table Load Request";
}
export class NotificationFromDbLoader {
	static readonly type = "[DbLoaderService] Send a Message to the User";
	constructor(public notification: UserNotification) {}
}
export class ClearNotification {
	static readonly type = "[NotificationService] Clear the pending notification";
}
@Injectable()
@State<AppStateModel>({
	name: "app",
})
export class AppState {
	constructor(
		private globalApiCallsService: GlobalApiCallsService,
		private settingsService: SettingsService,
		private router: Router,
		private store: Store,
	) {}

	@Selector()
	static getAppState(state: AppStateModel) {
		return state;
	}

	@Selector()
	static selectUser(state: AppStateModel) {
		return state.user;
	}

	@Selector()
	static selectSelectedUserProfile(state: AppStateModel) {
		return state.selectedUserProfile;
	}

	@Selector()
	static selectSelectedGroupPolicy(state: AppStateModel) {
		return state.selectedGroupPolicy;
	}

	@Selector()
	static selectSelectedDivision(state: AppStateModel) {
		return state.selectedDivisionId;
	}

	@Selector()
	static selectDivision(state: AppStateModel) {
		return state.division;
	}

	@Selector()
	static selectUserProfiles(state: AppStateModel) {
		return state.userProfiles;
	}

	@Selector()
	static selectGroupPolicies(state: AppStateModel) {
		return state.groupPolicies;
	}

	@Selector()
	static selectHydratedUserPolicies(state: AppStateModel) {
		return state.hydratedUserPolicies;
	}

	@Selector()
	static selectHydratedUserProfiles(state: AppStateModel) {
		return state.hydratedUserProfiles;
	}

	@Selector()
	static selectCompany(state: AppStateModel) {
		return state.company;
	}

	// might not be needed, because defectlist is getting into the state another way
	@Selector()
	static selectApplication(state: AppStateModel) {
		return state.application;
	}

	@Selector()
	static selectLanguageDictionary(state: AppStateModel) {
		return state.languageDictionary;
	}

	@Selector()
	static selectInspectionTypes(state: AppStateModel) {
		return state.inspectionTypes;
	}

	@Selector()
	static selectInspectionNames(state: AppStateModel) {
		return state.inspectionNames;
	}

	@Selector()
	static selectAssetTypes(state: AppStateModel) {
		return state.assetTypes;
	}

	@Selector()
	static selectAssets(state: AppStateModel) {
		return state.assets;
	}

	@Selector()
	static selectInspectors(state: AppStateModel) {
		return state.inspectors;
	}

	@Selector()
	static selectDivisions(state: AppStateModel) {
		return state.divisions;
	}

	@Selector()
	static selectCoreApiDivisions(state: AppStateModel) {
		return state.coreApiDivisions;
	}

	@Selector()
	static selectSettings(state: AppStateModel) {
		return state.settings;
	}

	@Selector()
	static selectConfig(state: AppStateModel) {
		return state.config;
	}

	@Selector()
	static selectConfigs(state: AppStateModel) {
		return state.configs;
	}

	@Selector()
	static selectAssetTypeImage(state: AppStateModel) {
		return state.assetTypeImage;
	}

	@Selector()
	static selectPageInformation(state: AppStateModel) {
		return state.pageInformation;
	}

	@Selector()
	static selectCompanyId(state: AppStateModel) {
		return state.companyId;
	}

	@Selector()
	static selectCompanyHasTCU(state: AppStateModel) {
		return state.companyHasTCU;
	}

	@Selector()
	static getActiveCompany(state: AppStateModel) {
		return state.activeCompany;
	}

	@Selector()
	static getActiveDivision(state: AppStateModel) {
		return state.activeDivision;
	}

	@Selector()
	static getLoadTableRequest(state: AppStateModel) {
		return { ...state.loadTableRequest };
	}

	@Selector()
	static getNotification(state: AppStateModel) {
		return { ...state.notification };
	}

	@Selector()
	static getSearchTables(state: AppStateModel) {
		return state.searchTable;
	}

	@Selector()
	static getCompanyDivisions(state: AppStateModel) {
		return state.companyDivisions;
	}

	@Action(SetSearchTable)
	setSearchTables({ patchState }: StateContext<AppStateModel>, { searchTable }: SetSearchTable) {
		patchState({
			searchTable: searchTable,
		});
	}

	@Action(SetUserObject)
	setUserObject({ patchState }: StateContext<AppStateModel>, action: SetUserObject) {
		patchState({ user: action.user });
	}

	@Action(SetUserProfiles)
	setUserProfiles({ patchState }: StateContext<AppStateModel>, action: SetUserProfiles) {
		patchState({
			userProfiles: action.userProfiles,
		});
	}

	@Action(SetGroupPolicies)
	setGroupPolicies({ patchState }: StateContext<AppStateModel>, action: SetGroupPolicies) {
		patchState({
			groupPolicies: action.groupPolicies,
		});
	}

	@Action(SetHydratedUserGroupPolicies)
	setHydratedUserGroupPolicies(
		{ patchState }: StateContext<AppStateModel>,
		{ userPolicies, companyEndpoint }: SetHydratedUserGroupPolicies,
	) {
		return this.globalApiCallsService.hydrateUserPolicies(userPolicies, companyEndpoint).pipe(
			tap((result: Array<HydratedUserPolicies>) => {
				patchState({
					hydratedUserPolicies: result,
				});
			}),
		);
	}

	@Action(SetHydratedUserProfiles)
	setHydratedUserProfiles(
		{ patchState }: StateContext<AppStateModel>,
		{ user, userProfiles, companyEndpoint, applicationEndpoint }: SetHydratedUserProfiles,
	) {
		return this.globalApiCallsService
			.hydrateUserProfiles(user, userProfiles, companyEndpoint, applicationEndpoint)
			.pipe(
				tap((result: Array<HydratedUserProfile>) => {
					patchState({
						hydratedUserProfiles: result,
					});
				}),
			);
	}

	@Action(SetCurrentUserProfile)
	setCurrentUserProfile({ patchState }: StateContext<AppStateModel>, action: SetCurrentUserProfile) {
		patchState({
			selectedUserProfile: action.userProfile,
		});
	}

	@Action(SetCurrentGroupPolicy)
	setCurrentGroupPolicy({ patchState }: StateContext<AppStateModel>, action: SetCurrentGroupPolicy) {
		patchState({
			selectedGroupPolicy: action.groupPolicy,
		});
	}

	@Action(SetSelectedDivision)
	setSelectedDivision({ patchState }: StateContext<AppStateModel>, action: SetSelectedDivision) {
		patchState({
			selectedDivisionId: action.divisionId,
		});
	}

	@Action(GetDivision)
	getDivision({ patchState }: StateContext<AppStateModel>, action: GetDivision) {
		return this.globalApiCallsService.getDivision(action.companyId, action.divisionId, action.endpoint).pipe(
			tap((result: IDivision) => {
				patchState({
					division: result,
				});
			}),
		);
	}

	@Action(GetCompanyObject)
	getCompanyObject({ patchState }: StateContext<AppStateModel>, action: GetCompanyObject) {
		return this.globalApiCallsService.getCompany(action.companyId, action.endpoint).pipe(
			tap((result: ICompany) => {
				patchState({
					company: result,
				});
			}),
		);
	}

	@Action(SetCompanyHasTCU)
	setCompanyHasTCU({ patchState }: StateContext<AppStateModel>, { companyHasTCU }: SetCompanyHasTCU) {
		patchState({
			companyHasTCU: companyHasTCU,
		});
	}

	@Action(GetApplicationObject)
	getApplicationObject({ patchState }: StateContext<AppStateModel>, action: GetApplicationObject) {
		return this.globalApiCallsService.getApplication(action.applicationId, action.endpoint).pipe(
			tap((result: Application) => {
				// if there's no application object
				if (Utils.isEmptyObject(result)) {
					this.store.dispatch(
						new SetNavState({
							http: false,
							type: navErrorConstants.NAV_ERROR_NO_APPLICATION,
						}),
					);
					this.router.navigate(["server-error"]);
				}

				patchState({
					application: result,
				});
			}),
		);
	}

	@Action(GetLanguageDictionary)
	getLanguageDictionary({ patchState }: StateContext<AppStateModel>, { companyId, endpoint }: GetLanguageDictionary) {
		return this.globalApiCallsService.getLanguageDictionary(companyId, endpoint).pipe(
			tap(langDic => {
				patchState({
					languageDictionary: langDic,
				});
			}),
		);
	}

	@Action(GetInspectionTypes)
	getInspectionTypes({ patchState }: StateContext<AppStateModel>, action: GetInspectionTypes) {
		return this.globalApiCallsService.getInspectionTypes(action.companyId, action.endpoint).pipe(
			tap((result: InspectionTypesResponse) => {
				patchState({
					inspectionTypes: result[0], // The inspection types will be at the zeroth index
					inspectionNames: result[1], // The inspection names will be at the first index
				});
			}),
		);
	}

	@Action(GetAssetTypes)
	getAssetTypes({ patchState }: StateContext<AppStateModel>, action: GetAssetTypes) {
		return this.globalApiCallsService.getAssetTypes(action.companyId, action.endpoint).pipe(
			tap((result: ZoneLayouts) => {
				patchState({
					assetTypes: result,
				});
			}),
		);
	}

	@Action(GetAssets)
	getAssets({ patchState }: StateContext<AppStateModel>, action: GetAssets) {
		return this.globalApiCallsService.getAssets(action.companyId, action.divisionId, action.endpoint).pipe(
			tap((result: Asset[]) => {
				patchState({
					assets: result,
				});
			}),
		);
	}

	@Action(GetInspectors)
	getInspectors({ patchState }: StateContext<AppStateModel>, action: GetInspectors) {
		return this.globalApiCallsService.getInspectors(action.companyId, action.divisionId, action.endpoint).pipe(
			tap((result: InspectorsInner[]) => {
				patchState({
					inspectors: result,
				});
			}),
		);
	}

	@Action(GetDivisions)
	getDivisions({ patchState }: StateContext<AppStateModel>, { companyId, divisionId, endpoint }: GetDivisions) {
		return this.globalApiCallsService.getDivisions(companyId, divisionId, endpoint).pipe(
			tap(result => {
				patchState({
					divisions: result,
				});
			}),
		);
	}

	@Action(SetCompanyDivisions)
	setCompanyDivisions({ patchState }: StateContext<AppStateModel>, action: SetCompanyDivisions) {
		patchState({
			companyDivisions: action.companyDivisions,
		});
	}

	@Action(GetCoreApiDivisions)
	getCoreApiDivisions({ patchState }: StateContext<AppStateModel>, action: GetCoreApiDivisions) {
		return this.globalApiCallsService.getCoreApiDivisionsWithFuzzySearch(action.divisionName, action.endpoint).pipe(
			tap(result => {
				patchState({
					coreApiDivisions: result,
				});
			}),
		);
	}

	@Action(GetSettings)
	getSettings({ patchState }: StateContext<AppStateModel>, { companyId, endpoint }: GetSettings) {
		return this.settingsService.getSettings(companyId, endpoint).pipe(
			tap((response: SettingsGet[]) => {
				patchState({
					settings: response[0], // Eric said the first response in the list will be the one we want
				});
			}),
		);
	}

	@Action(PatchSettings)
	patchSettings({ patchState }: StateContext<AppStateModel>, { payload, endpoint }: PatchSettings) {
		// one of the settings payloads in api-payloads.models.ts
		return this.settingsService.patchSettings(payload, endpoint).pipe(
			tap((response: SettingsGet) => {
				patchState({
					settings: response,
				});
			}),
		);
	}

	@Action(GetConfig)
	getConfig({ patchState }: StateContext<AppStateModel>, { companyId, endpoint }: GetConfig) {
		return this.globalApiCallsService.getConfig(companyId, endpoint).pipe(
			tap(([config]: ConfigGet[]) => {
				// We're assuming the first config in the array is the most up-to-date, Eric confirmed
				patchState({ config: config });
			}),
		);
	}

	@Action(GetConfigs)
	getConfigs(
		{ patchState }: StateContext<AppStateModel>,
		{ configIds, companyId, endpoint, queryStrings }: GetConfigs,
	) {
		return this.globalApiCallsService.getConfigs(configIds, companyId, endpoint, queryStrings).pipe(
			tap((response: ConfigGet[]) => {
				patchState({
					configs: response,
				});
			}),
		);
	}

	@Action(GetAssetTypeImage)
	getAssetTypeImage(
		{ patchState }: StateContext<AppStateModel>,
		{ companyId, assetViewId, endpoint }: GetAssetTypeImage,
	) {
		return this.settingsService.getAssetTypeImage(companyId, assetViewId, endpoint).pipe(
			tap(response => {
				// using any type for image
				patchState({
					assetTypeImage: response,
				});
			}),
		);
	}

	@Action(SetPageInformation)
	setPageInformation({ patchState }: StateContext<AppStateModel>, { pageInformation }: SetPageInformation) {
		patchState({
			pageInformation: pageInformation,
		});
	}

	@Action(SetCompanyAndDivisionId)
	setCompanyAndDivisionId({ patchState }: StateContext<AppStateModel>, action: SetCompanyAndDivisionId) {
		patchState({
			companyId: action.companyId,
			selectedDivisionId: action.divisionId,
		});
	}

	@Action(GetActiveGtcCompany)
	getActiveCompany(
		{ patchState }: StateContext<AppStateModel>,
		{ legacyAccountCode, endpoint, userProfileID }: GetActiveGtcCompany,
	) {
		return this.globalApiCallsService.getActiveGtcCompany(legacyAccountCode, endpoint, userProfileID).pipe(
			tap((response: ICompany) => {
				patchState({
					activeCompany: response,
				});
			}),
		);
	}

	@Action(GetActiveGtcDivision)
	getActiveDivision(
		{ patchState }: StateContext<AppStateModel>,
		{ legacyAccountCode, endpoint, userID }: GetActiveGtcDivision,
	) {
		return this.globalApiCallsService.getActiveGtcDivision(legacyAccountCode, endpoint, userID).pipe(
			tap((response: IDivision) => {
				patchState({
					activeDivision: response,
				});
			}),
		);
	}

	@Action(RequestLoadTable)
	requestDbTableLoad({ patchState }: StateContext<AppStateModel>, { loadTableRequest }: RequestLoadTable) {
		patchState({
			loadTableRequest: loadTableRequest,
		});
	}

	@Action(ClearLoadTableRequest)
	clearRequestDbTableLoad({ patchState }: StateContext<AppStateModel>) {
		patchState({
			loadTableRequest: null,
		});
	}

	@Action(NotificationFromDbLoader)
	notifyUserFromDbLoader({ patchState }: StateContext<AppStateModel>, { notification }: NotificationFromDbLoader) {
		patchState({
			notification: notification,
		});
	}
	@Action(ClearNotification)
	clearNotification({ patchState }: StateContext<AppStateModel>) {
		patchState({
			notification: null,
		});
	}
}
