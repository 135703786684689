import { InspectionDetailDatumViewModel } from "src/app/models/inspection-detail-view-models.models";
import { InspectionDetailData } from "src/app/models/openAPIAliases";
import { LocaleService } from "src/app/services/locale/locale.service";
import { Utils } from "src/app/utils";
import { normalizeTuple } from "./normalizeTuple";

/**
 * Concatenate decimals and units and return the value
 * @param param0 InspectionDetailData object
 * @param param1 InspectionDetailDatumViewModel object
 * @param localeService Locale Service
 * @returns A string with the decimal and unit to render. It returns "—" in case we didn't received data
 */
export const addUnitsToContent = (
	{ inspectionDetailSelectedUnit }: InspectionDetailData,
	{ inspectionDetailContent, inspectionDetailUnitConversionPair }: InspectionDetailDatumViewModel,
	localeService: LocaleService,
	defaultValue = "—",
): string => {
	let tupleIndex = 0;
	/* Select the index irrespective of the order received to display accurate metrics */
	if (Array.isArray(inspectionDetailUnitConversionPair)) {
		tupleIndex = Utils.findUnitIndex(localeService, inspectionDetailUnitConversionPair);
	} else {
		tupleIndex = Utils.findUnitIndex(localeService, Array(inspectionDetailSelectedUnit));
	}

	/** The response is inconsistent, we need to normalize it as a tuple first */
	const normalizedValue = (
		Array.isArray(inspectionDetailContent) // Is inspectionDetailContent an array
			? // normalize tuple
			  normalizeTuple(inspectionDetailContent)
			: // If it's not an array, turn it into a tuple
			  [inspectionDetailContent, inspectionDetailContent]
	)[tupleIndex];

	/** The response is inconsistent, we need to normalize it as a tuple first */
	const normalizedUnit =
		// if it's not a legacy inspection
		Array.isArray(inspectionDetailUnitConversionPair)
			? // normalize tuple
			  normalizeTuple(inspectionDetailUnitConversionPair)[tupleIndex]
			: // if it's a legacy inspection, leave it as it is
			  inspectionDetailSelectedUnit;

	return (
		`${normalizedValue !== "null" ? normalizedValue : ""}${
			normalizedValue && normalizedValue !== "null" && normalizedUnit ? ` ${normalizedUnit}` : ""
		}` || defaultValue
	);
};
