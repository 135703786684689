import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LoggerService } from "./logger.service";
import { expand, reduce, tap } from "rxjs/operators";
import { Observable, EMPTY } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class ServiceUtilsService {
	// this service exists for utilities that need the injected resources from Angular

	constructor(private http: HttpClient, public logger: LoggerService) {}

	// any return type since it handles any api request with multiple pages
	public getAllPages(
		uri: string,
		entityName: string = "entities",
		pageSize: number = 1000,
		startingPage: number = 1,
	): Observable<unknown[]> {
		let page = startingPage;

		this.logger.log(`Beginning retrieval of ${entityName}: ${uri}`);

		// until we get back an empty array, keep requesting the next page of entities
		// https://stackoverflow.com/questions/40529232/angular-2-http-observables-and-recursive-requests
		return this.http.get<unknown[]>(`${uri}&page=${page}&perPage=${pageSize}`).pipe(
			expand(response => {
				if (response.length === pageSize) {
					this.logger.log(`Retrieved page ${page} of ${entityName}`);
					page++;
					this.logger.log(`Requesting page ${page} of ${entityName}`);

					return this.http.get<unknown[]>(`${uri}&page=${page}`);
				} else if (response.length === 0) {
					if (page === startingPage) {
						this.logger.log(`No results returned for ${entityName}`);
					} else {
						this.logger.log(`No results returned for page ${page} of ${entityName}, ending stream`);
					}

					return EMPTY;
				} else {
					this.logger.log(`Reached last page (${page}) of ${entityName}`);

					return EMPTY;
				}
			}),
			reduce((accumulator, current) => {
				return accumulator.concat(current);
			}, []),
			tap((entities: unknown[]) => {
				this.logger.log(`Total number of ${entityName} retrieved: ${entities.length}`);
			}),
		);
	}
}
