<div
	class="modal"
	cdkOverlayOrigin
>
	<div
		mat-dialog-title
		fxLayout="row"
		fxLayoutAlign="space-between start"
	>
		<div>
			{{ repair.type | lowercase | i18next: { format: "capitalize" } }}
		</div>
		<img
			id="clear"
			class="pointer"
			src="assets/ic_clear.svg"
			(click)="!isPostingRepairs && cancelRepair()"
		/>
	</div>

	<div mat-dialog-content>
		<!-- cdkFocusRegionstart removes initial focus on the mat components -->
		<form
			cdkFocusRegionstart
			id="repairStatus"
			[formGroup]="repairForm"
			novalidate
		>
			<!-- we only need comments for pending/ignored statuses -->
			<div
				*ngIf="pending || repairNotNeeded"
				id="pending-ignored-dialog-content"
				fxLayout="column"
				fxLayoutGap="5px"
			>
				<div class="field-border">
					<label>
						{{ "comments" | i18next: { format: "capitalize" } }}
						<span class="red">*</span>
					</label>
					<textarea
						formControlName="comments"
						maxlength="512"
					></textarea>
				</div>

				<div
					class="character-row"
					fxFlexLayout="row"
					fxLayoutAlign="space-around start"
					fxFlex="100"
				>
					<!-- show error if user input more than 512 characters -->
					<div fxFlex="85">
						<div
							*ngIf="commentCharacterCounter >= 512"
							class="error"
						>
							{{ "character limit reached" | i18next: { format: "capitalize" } }}
						</div>
						<div
							*ngIf="
								repairForm.get('checkbox').dirty &&
								(repairForm.get('comments').value === null || repairForm.get('comments').value === '')
							"
							class="error"
						>
							{{ "comment required" | i18next: { format: "capitalize" } }}
						</div>
					</div>

					<app-character-counter
						class="character-counter"
						[currentLength]="commentCharacterCounter"
						[maxLength]="512"
						fxFlex="15"
					></app-character-counter>
				</div>
			</div>

			<!-- if the mechanic has repaired vehicle, show resolution, work order, comments -->
			<div
				*ngIf="repaired"
				id="repaired-dialog-content"
				fxLayout="column"
			>
				<!-- mechanic search box shows if user is supervising mechanic -->
				<mat-form-field
					*ngIf="showMechanicSelect"
					class="mechanic-search"
					floatLabel="never"
					matAutocompleteOrigin
					#origin="matAutocompleteOrigin"
				>
					<span
						matPrefix
						class="search-icon"
					>
						<img src="assets/ic_search.svg" />
					</span>
					<input
						type="text"
						id="mechanic-input"
						[attr.aria-label]="'repairing mechanic' | i18next: { format: 'capitalize' }"
						[placeholder]="'repairing mechanic' | i18next: { format: 'capitalize' }"
						matInput
						[formControl]="searchControl"
						[matAutocomplete]="auto"
						[matAutocompleteConnectedTo]="origin"
					/>

					<mat-error
						*ngIf="searchControl.touched && searchControl.invalid"
						id="mechanic-select-error"
					>
						{{ "please select a mechanic from the list" | i18next: { format: "capitalize" } }}
					</mat-error>

					<mat-autocomplete
						#auto="matAutocomplete"
						[displayWith]="displayName"
					>
						<mat-option
							*ngFor="let mechanic of filteredMechanics | async"
							[value]="mechanic"
						>
							{{ mechanic.lastName }}, {{ mechanic.firstName }}
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>

				<div
					fxLayout="row"
					fxLayoutAlign="space-between"
					fxFlex="100"
				>
					<!-- resolution strings -->
					<div
						fxFlex="45"
						class="field-border"
					>
						<label>
							{{ "resolution" | i18next: { format: "capitalize" } }}
							<span class="red">*</span>
						</label>

						<div class="radio">
							<mat-radio-group
								id="resolution-radio-group"
								formControlName="resolution"
							>
								<mat-radio-button
									*ngFor="let resolution of companyResolutions$ | async"
									fxLayout="column"
									[value]="resolution"
								>
									{{ resolution.resolutionValues[locale] || resolution.resolutionValues["en-us"] }}
									<br />
								</mat-radio-button>
							</mat-radio-group>
						</div>
					</div>

					<div
						fxLayout="column"
						fxLayoutGap="8px"
						fxFlex="45"
					>
						<!-- work order number -->
						<div
							class="field-border"
							fxFlex="100"
						>
							<label>{{ "work order number" | i18next: { format: "capitalize" } }}</label>
							<input
								formControlName="workOrder"
								type="text"
								maxlength="128"
							/>
						</div>

						<div
							class="character-row"
							fxFlexLayout="row"
							fxLayoutAlign="space-around start"
							fxFlex="100"
						>
							<div fxFlex="75">
								<div
									*ngIf="workOrderCharacterCounter >= 128"
									class="error"
								>
									{{ "character limit reached" | i18next: { format: "capitalize" } }}
								</div>
							</div>

							<app-character-counter
								class="character-counter"
								[currentLength]="workOrderCharacterCounter"
								[maxLength]="128"
								fxFlex="25"
							></app-character-counter>
						</div>

						<!-- comments -->
						<div
							class="field-border"
							fxFlex="100"
						>
							<label>
								{{ "comments" | i18next: { format: "capitalize" } }}

								<!-- if user selected other in resolution field, show red asterisk for required field -->
								<span
									*ngIf="otherSelected"
									class="red"
								>
									*
								</span>
							</label>
							<textarea
								formControlName="comments"
								maxlength="512"
							></textarea>
						</div>

						<div
							class="character-row"
							fxFlexLayout="row"
							fxLayoutAlign="space-around start"
							fxFlex="100"
						>
							<!-- show error if user input more than 512 characters -->
							<div fxFlex="75">
								<div
									*ngIf="commentCharacterCounter >= 512"
									class="error"
								>
									{{ "character limit reached" | i18next: { format: "capitalize" } }}
								</div>

								<!-- alert user comment is required if they selected other, comments is empty, and they clicked the checkbox -->
								<div
									*ngIf="repairForm.errors?.commentRequired && repairForm.get('checkbox').dirty"
									class="error"
								>
									{{ "comment required" | i18next: { format: "capitalize" } }}
								</div>
							</div>

							<app-character-counter
								[currentLength]="commentCharacterCounter"
								[maxLength]="512"
								fxFlex="25"
							></app-character-counter>
						</div>
					</div>
				</div>
			</div>

			<div
				class="certify-repair"
				fxLayout="column"
			>
				<div
					fxLayout="row"
					fxLayoutAlign="start start"
					fxFlex="100"
				>
					<div fxFlex="5">
						<mat-checkbox
							id="repair-checkbox"
							formControlName="checkbox"
						></mat-checkbox>
					</div>

					<!-- change text based on pending or ignored -->
					<div
						*ngIf="pending"
						id="certify-text"
						fxFlex
					>
						{{
							"i certify the above defect update is true."
								| i18next
									: { format: "capitalize", userFirstName: userFirstName, userLastName: userLastName }
						}}
					</div>
					<div
						*ngIf="repairNotNeeded"
						id="certify-text"
						fxFlex
					>
						{{
							"i certify the indicated defects required no repairs."
								| i18next
									: { format: "capitalize", userFirstName: userFirstName, userLastName: userLastName }
						}}
					</div>

					<!-- if user is mechanic, show first person -->
					<div
						*ngIf="repaired && !searchControl.value"
						id="certify-text"
						fxFlex
					>
						{{
							"i certify the indicated defects have been corrected."
								| i18next
									: { format: "capitalize", userFirstName: userFirstName, userLastName: userLastName }
						}}
					</div>
					<!-- if user interacted with searchbox but it is invalid -->
					<div
						*ngIf="repaired && searchControl.value && searchControl.dirty && !searchControl.valid"
						id="certify-text"
						fxFlex
					>
						{{
							"i certify the indicated defects have been corrected."
								| i18next
									: { format: "capitalize", userFirstName: userFirstName, userLastName: userLastName }
						}}
					</div>
					<!-- if user is supervising mechanic and has selected mechanic in searchbox, display both names -->
					<div
						*ngIf="repaired && searchControl.value && searchControl.touched && searchControl.valid"
						id="certify-text"
						fxFlex
					>
						{{
							"i certify the indicated defects have been corrected by"
								| i18next
									: { format: "capitalize", userFirstName: userFirstName, userLastName: userLastName }
						}}
						{{ searchControl.value.firstName }} {{ searchControl.value.lastName }}.
					</div>
				</div>
			</div>
			<ng-container *ngIf="errors.length > 0">
				<mat-error style="white-space: nowrap">
					<div>Unfortunately we're experiencing a problem with processing this repair.</div>
					<div>If this issue persists, please reach out to customer care for assistance.</div>
					<div class="support-info">
						Support phone:
						<b>877-843-3847</b>
					</div>
					<div class="support-info"><b>Support email: customercare@zonarsystems.com</b></div>
				</mat-error>
			</ng-container>
		</form>
	</div>

	<div
		mat-dialog-actions
		align="end"
	>
		<app-action-button
			id="cancel-repair"
			[disabled]="isPostingRepairs"
			type="outlined-2"
			title="cancel"
			(buttonClicked)="cancelRepair()"
			[style.marginRight.px]="16"
		></app-action-button>

		<app-action-button
			title="apply"
			id="apply-repair"
			[disabled]="!repairForm.valid || !searchControl.valid || isPostingRepairs"
			(buttonClicked)="submitRepair(); isPostingRepairs = true"
		></app-action-button>
	</div>
</div>
