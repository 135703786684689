import { Injectable } from "@angular/core";
import { Select } from "@ngxs/store";
import { decode } from "html-entities";
import { Observable } from "rxjs";
import { AppState } from "src/app/app.state";
import { currentLocale } from "src/app/i18next";
import { getSupportedLng } from "src/app/i18next/i18nextOptions";
import { Efigs, LangDictGet } from "src/app/models/openAPIAliases";

@Injectable({ providedIn: "root" })
export class LanguageDictionaryService {
	@Select(AppState.selectLanguageDictionary) languageDictionary$: Observable<LangDictGet>;

	public languageDictionary: LangDictGet;
	public languagesSupported: string[];

	constructor() {
		this.languageDictionary$.subscribe(langDict => {
			if (langDict) {
				this.languageDictionary = langDict;
			}
		});
	}

	/**
	 * Get the supported language code based on the current locale
	 *
	 */
	private supportedLangDictCode() {
		return getSupportedLng({
			language: currentLocale(),
			locales: ["en-us", "fr-fr", "it-it", "de-de", "es-es"] as ReadonlyArray<keyof Efigs>,
		});
	}

	/**
	 * Get a translation from the langDict base on the locale
	 *
	 * @param keyToTranslate Key to translate, it could be an UUID or a legacy string
	 */
	public getTranslation(keyToTranslate: string) {
		const translationMap = this.languageDictionary?.languageStrings?.[keyToTranslate];
		return decode(
			translationMap?.translations[this.supportedLangDictCode()] ?? translationMap?.translations["en-us"],
		);
	}
}
