import { ICompany } from "@zonar-ui/auth/lib/models/company.model";

export function parseSettings(settingsValue: string): ICompany {
	// Split the input string by comma to separate the key-value pairs
	const parts = settingsValue.split(",");

	const company = {} as ICompany;

	parts.forEach(part => {
		const [key, value] = part.split(":");

		if (key === "title") {
			company.name = value;
		} else if (key === "value") {
			company.id = value;
		}
	});

	return company;
}
