<div
	*ngIf="staticInfo"
	class="inspection-page"
>
	<div class="inspection-page-container">
		<div class="inspection-heading">
			<!-- HEADER -->
			<div class="inspection-heading-container">
				<a
					[routerLink]="previousPageUrl"
					class="inspection-history-link"
				>
					<
					{{
						"back to previousPageTitle"
							| i18next: { format: "capitalize", previousPageTitle: previousPageTitle }
					}}
				</a>
				<h1 class="heading-1 inspection-heading-text">
					{{ staticInfo["inspection type"] }} {{ "inspection" | i18next: { format: "capitalize" } }}
				</h1>
			</div>
			<!-- DEFECT TOGGLE -->
			<div class="defect-toggle">
				<mat-slide-toggle
					class="zone-defect-toggle"
					(toggleChange)="toggleFilter()"
					[checked]="defectsOnly"
				>
					{{ "defects only" | i18next: { format: "capitalize" } }}
				</mat-slide-toggle>
			</div>
		</div>

		<div class="inspection-content">
			<section class="asset-details">
				<h2 class="section-title">{{ "asset details" | i18next: { format: "capitalize" } }}</h2>
				<ng-container *ngFor="let asset of viewModel.inspectionAssets; let i = index">
					<h3 class="asset-name">{{ asset.languageLabel }}</h3>

					<!-- ASSET INFORMATION -->

					<table class="asset-detail-table">
						<tr class="asset-detail">
							<td class="asset-detail-field">
								{{ "asset number" | i18next: { format: "uppercase" } }}
							</td>
							<td class="asset-detail-data">
								<a routerLink="/asset-details/{{ asset.assetId }}">
									{{ asset.assetName }}
								</a>
								<span
									class="asset-flag"
									*ngIf="asset.assetCategory === 'user'"
								>
									<mat-icon
										class="user-asset-icon"
										matTooltip="User Created Asset"
									>
										outlined_flag
									</mat-icon>
								</span>
							</td>
						</tr>
						<ng-container *ngFor="let addtlInfo of asset.addtlAssetInfo">
							<tr class="asset-detail">
								<td class="asset-detail-field">{{ addtlInfo.languageLabel }}</td>
								<td class="asset-detail-data">
									{{ addtlInfo.addtlAssetContent }}
								</td>
							</tr>
						</ng-container>
					</table>
				</ng-container>
			</section>
			<section class="inspection-list">
				<app-inspection-list-view
					[defectsOnly]="defectsOnly"
					[formDataPhotos]="formDataPhotos"
					[staticInfo]="sortedStaticInfo"
					[viewModel]="viewModel"
					[viewModelError]="viewModelError"
					[viewModelErrorDetails]="viewModelErrorDetails"
				></app-inspection-list-view>
			</section>
		</div>
	</div>
</div>
