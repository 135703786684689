<div class="evir-footer">
	<div
		class="evir-footer-container"
		fxLayout="row"
		fxLayoutAlign="space-between end"
	>
		<div
			fxLayout="row"
			fxLayoutAlign="center center"
		>
			<a href="https://www.zonarsystems.com/">
				<img [src]="'assets/' + footerLogoFilename" />
			</a>

			<div class="evir-copyright">&copy; {{ currentYear }} {{ copyright }}</div>
		</div>

		<div
			class="support-links"
			fxLayout="column"
			fxLayoutAlign="space-around end"
			fxLayoutGap="2px"
		>
			<div
				fxLayout="row"
				fxLayoutGap="10px"
				*ngIf="localeConfig"
			>
				<a
					*ngIf="localeConfig.links.help"
					[href]="localeConfig.links.help"
				>
					{{ supportText }}
				</a>
				<a
					*ngIf="localeConfig.links.termsAndConditions"
					[href]="localeConfig.links.termsAndConditions"
					target="_blank"
				>
					{{ termsConditionsText }}
				</a>
				<a
					*ngIf="localeConfig.links.dataProtectionAgreement"
					[href]="localeConfig.links.dataProtectionAgreement"
					target="_blank"
				>
					{{ dataDisclosureText }}
				</a>
			</div>

			<div
				fxLayout="row"
				fxLayoutGap="10px"
				*ngIf="supportPhoneNumber"
			>
				<span>{{ supportPhoneText }}: {{ supportPhoneNumber }}</span>
				<a [href]="mailTo">{{ supportEmail }}</a>
			</div>
		</div>
	</div>
</div>
