import { State, Action, StateContext, Selector } from "@ngxs/store";
import { InspectionHistoryService } from "../service/inspection-history.service";
import { tap } from "rxjs/operators";
import { InspectionGet } from "../../../models/openAPIAliases";
import { HttpResponse } from "@angular/common/http";
import { InspectionListPrintViewModel } from "src/app/models/inspection-print-view.models";
import { Injectable } from "@angular/core";

export class InspectionHistoryStateModel {
	inspectionList: InspectionGet[];
	inspectionListCount: number;
	inspectionListForPdfExport: InspectionGet[];
	inspectionsPrintViewViewModels: InspectionListPrintViewModel[];
}

const inspectionHistoryStateDefaults: InspectionHistoryStateModel = {
	inspectionList: null,
	inspectionListCount: 0,
	inspectionListForPdfExport: [],
	inspectionsPrintViewViewModels: [],
};

export class GetInspectionListWithQueryStrings {
	static readonly type = "[Inspection List View] Get Inspection List With Query Strings";
	constructor(public queryStrings: URLSearchParams, public companyId: string, public endpoint: string) {}
}

export class GetInspectionListForPdfExport {
	static readonly type = "[Inspection List View] Get Inspection List for PDF export";
	constructor(public queryStrings: URLSearchParams, public companyId: string, public endpoint: string) {}
}

export class PopulateInspectionsPrintViewViewModels {
	static readonly type = "[Inspection List View] Put the view models for the print view in the store";
	constructor(public viewModels: InspectionListPrintViewModel[]) {}
}
@Injectable()
@State<InspectionHistoryStateModel>({
	name: "inspectionList",
	defaults: inspectionHistoryStateDefaults,
})
export class InspectionHistoryState {
	constructor(private inspectionHistoryService: InspectionHistoryService) {}

	@Selector()
	static getInspectionList(state: InspectionHistoryStateModel) {
		return state.inspectionList;
	}

	@Selector()
	static getInspectionListCount(state: InspectionHistoryStateModel) {
		return state.inspectionListCount;
	}

	@Selector()
	static getInspectionListForPdfExport(state: InspectionHistoryStateModel) {
		return state.inspectionListForPdfExport;
	}

	@Selector()
	static getInspectionsPrintViewViewModels(state: InspectionHistoryStateModel) {
		return state.inspectionsPrintViewViewModels;
	}

	@Action(GetInspectionListWithQueryStrings)
	getInspectionListWithQueryStrings(
		{ patchState }: StateContext<InspectionHistoryStateModel>,
		{ queryStrings, companyId, endpoint }: GetInspectionListWithQueryStrings,
	) {
		return this.inspectionHistoryService.getInspectionList(companyId, endpoint, queryStrings).pipe(
			tap((response: HttpResponse<InspectionGet[]>) => {
				patchState({
					inspectionList: response.body,
					inspectionListCount: parseInt(response.headers.get("x-total-count"), 10),
				});
			}),
		);
	}

	@Action(GetInspectionListForPdfExport)
	getInspectionListForPdfExport(
		{ patchState }: StateContext<InspectionHistoryStateModel>,
		{ queryStrings, companyId, endpoint }: GetInspectionListForPdfExport,
	) {
		return this.inspectionHistoryService.getInspectionList(companyId, endpoint, queryStrings).pipe(
			tap((response: HttpResponse<InspectionGet[]>) => {
				patchState({
					inspectionListForPdfExport: response.body,
				});
			}),
		);
	}

	@Action(PopulateInspectionsPrintViewViewModels)
	populateInspectionsPrintViewViewModels(
		{ patchState }: StateContext<InspectionHistoryStateModel>,
		{ viewModels }: PopulateInspectionsPrintViewViewModels,
	) {
		patchState({
			inspectionsPrintViewViewModels: viewModels,
		});
	}
}
