import { Routes } from "@angular/router";
import { AuthGuard } from "@auth0/auth0-angular";
import { PermissionsGuard } from "@zonar-ui/auth";
import { AccountAdminComponent } from "../../../views/account-admin/account-admin.component";
import { AssetDetailsComponent } from "../../../views/asset-details/asset-details.component";
import { DefectListComponent } from "../../../views/defect-list/defect-list.component";
import { InspectionDetailComponent } from "../../../views/inspection-detail/inspection-detail.component";
import { InspectionHistoryComponent } from "../../../views/inspection-history/inspection-history.component";
import { InspectionsPrintViewComponent } from "../../inspections-print-view/inspections-print-view.component";
import { MissingInspectionsComponent } from "../../missing-inspections/missing-inspections.component";
import { ResourcesPanelComponent } from "../../resources-panel/resources-panel.component";
import { ZonarAdminAccountSelectionComponent } from "../../zonar-admin-account-selection/zonar-admin-account-selection.component";

export const DEFAULT_ROUTES: Routes = [
	{ path: "", component: InspectionHistoryComponent, canActivate: [AuthGuard] },
	{ path: "inspection-list", component: InspectionHistoryComponent, canActivate: [AuthGuard, PermissionsGuard] },
	{
		path: "inspections-print-view",
		component: InspectionsPrintViewComponent,
		canActivate: [AuthGuard, PermissionsGuard],
	},
	{ path: "resources-panel", component: ResourcesPanelComponent, canActivate: [AuthGuard, PermissionsGuard] },
	{ path: "account-admin", component: AccountAdminComponent, canActivate: [AuthGuard, PermissionsGuard] },
	{
		path: "inspection/:inspection-uuid",
		component: InspectionDetailComponent,
		canActivate: [AuthGuard, PermissionsGuard],
	},
	{ path: "defects", component: DefectListComponent, canActivate: [AuthGuard, PermissionsGuard] },
	{ path: "missing-inspections", component: MissingInspectionsComponent, canActivate: [AuthGuard, PermissionsGuard] },
	{ path: "asset-details/:asset-uuid", component: AssetDetailsComponent, canActivate: [AuthGuard, PermissionsGuard] },
	{
		path: "zonar-admin-account-selection",
		component: ZonarAdminAccountSelectionComponent,
		canActivate: [PermissionsGuard],
	},
];
