<div
	class="company-selector-container"
	mat-dialog-content
	fxLayout="column"
	fxFlex
>
	<div
		fxLayout="row"
		fxLayoutAlign="space-between"
		class="company-selector-header"
	>
		<h4>{{ "select a company" | i18next: { format: "capitalize" } }}</h4>
		<button
			mat-button
			*ngIf="!data.disableClose"
			id="change-company-clear-button"
			(click)="closeDialog()"
		>
			<mat-icon>clear</mat-icon>
		</button>
	</div>

	<div
		fxLayout="row"
		fxFlex="100%"
	>
		<mat-form-field
			appearance="outline"
			class="form"
		>
			<mat-label>{{ "company" | i18next: { format: "capitalize" } }}</mat-label>
			<div
				fxLayout="row"
				fxLayoutAlign="space-between center"
			>
				<input
					type="text"
					matInput
					[formControl]="dropdownControl"
					placeholder="{{ placeholder }}"
					[matAutocomplete]="auto"
				/>
				<mat-spinner
					class="change-company-spinner"
					*ngIf="isLoadingCompanies"
					diameter="17"
					strokeWidth="3"
					color="accent"
				></mat-spinner>
			</div>
			<mat-autocomplete
				#auto="matAutocomplete"
				[displayWith]="companyTitleFn"
			>
				<cdk-virtual-scroll-viewport
					class="autocomplete-test-viewport"
					[ngStyle]="{ height: height }"
					itemSize="{{ itemSize }}"
					minBufferPx="{{ itemSize * 8 }}"
					maxBufferPx="{{ itemSize * 10 }}"
				>
					<mat-option
						*cdkVirtualFor="let companyOption of filteredCompanies | async"
						[value]="companyOption"
						class="autocomplete-item"
					>
						{{ companyOption.title }}
					</mat-option>
				</cdk-virtual-scroll-viewport>
			</mat-autocomplete>
		</mat-form-field>
	</div>

	<div
		fxLayout="row"
		fxLayoutAlign="end"
	>
		<button
			fxLayout="row"
			fxLayoutAlign="center center"
			mat-stroked-button
			id="done-button"
			class="sidenav-company-selector-done-button"
			(click)="handleDoneButtonClick()"
		>
			<ng-container>
				{{ "done" | i18next: { format: "capitalize" } }}
			</ng-container>
		</button>
	</div>
</div>
