<div
	class="logout-control-component"
	fxLayout="row"
	fxLayoutAlign="flex-end center"
	fxLayoutGap="8px"
	[matMenuTriggerFor]="accountMenu"
	(click)="rotate()"
	(menuClosed)="rotate()"
>
	<div class="evir-logout-control">
		<div class="logout-control-username">{{ userName }}</div>
		<div
			class="logout-control-company"
			*ngIf="userCompany && userDivision"
		>
			{{ userCompany }} > {{ userDivision }}
		</div>
	</div>

	<mat-icon [@rotatedState]="rotateState">expand_more</mat-icon>

	<mat-menu
		#accountMenu="matMenu"
		backdropClass="account-menu"
		xPosition="before"
	>
		<button
			mat-menu-item
			id="logout-control-menu-button"
			(click)="logoutButtonHandler()"
		>
			{{ "log out" | i18next: { format: "capitalize" } }}
		</button>

		<button
			mat-menu-item
			*ngIf="showCompanySelectorOption"
			(click)="companySelector()"
		>
			{{ "select company" | i18next: { format: "title" } }}
		</button>

		<button
			mat-menu-item
			*ngIf="showAccountSelectorOption"
			id="switch-to-account-selection"
			(click)="switchToZonarAdminAccountSelectionView()"
		>
			{{ "select account" | i18next: { format: "title" } }}
		</button>

		<div
			*ngIf="!showAccountSelectorOption"
			class="account-divider"
		></div>

		<form
			*ngIf="!showAccountSelectorOption"
			[formGroup]="accountSwitcherForm"
			class="account-switcher"
			fxLayout="row"
			fxLayoutAlign="start center"
			matAutocompleteOrigin
			#origin="matAutocompleteOrigin"
		>
			<mat-icon class="search-icon">search</mat-icon>
			<mat-form-field
				class="account-switcher-filter"
				(click)="handleAutoCompleteClick($event)"
				floatLabel="never"
			>
				<input
					matInput
					type="text"
					[placeholder]="'account or location' | i18next"
					formControlName="accountSwitcherGroup"
					[matAutocomplete]="autoGroup"
					[matAutocompleteConnectedTo]="origin"
					(focus)="handleAutoCompleteClick($event)"
				/>

				<mat-autocomplete
					#accountSwitcherMatAutoComplete
					#autoGroup="matAutocomplete"
					(optionSelected)="handleOptionSelected($event)"
				>
					<mat-optgroup
						*ngFor="let group of accountSwitcherValues$ | async"
						[label]="group.name"
					>
						<mat-option
							*ngFor="let value of group.values"
							class="account-switcher-option"
							[value]="value"
							matTooltip="{{ value.tooltip }}"
							matTooltipPosition="after"
							matTooltipShowDelay="1000"
						>
							{{ value.viewValue }}
						</mat-option>
					</mat-optgroup>
				</mat-autocomplete>
			</mat-form-field>
		</form>
	</mat-menu>
</div>
