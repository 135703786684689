import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivationStart, Router, RouterEvent } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { PendoService } from "@zonar-ui/analytics";
import { ECompanyLoginMode, PermissionsService } from "@zonar-ui/auth";
import { ICompany, IDivision } from "@zonar-ui/auth/lib/models/company.model";
import { IUserGroupPolicy } from "@zonar-ui/auth/lib/models/user-group-policy.model";
import { IUserProfile } from "@zonar-ui/auth/lib/models/user-profile.model";
import { IUser } from "@zonar-ui/auth/lib/models/user.model";
import { Observable, Subject, combineLatest } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map, take, takeUntil } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { getSettings } from "src/utils/settings/get-settings";
import { parseSettings } from "src/utils/settings/parse-settings";
import { setSettings } from "src/utils/settings/set-settings";
import { v4 as uuid } from "uuid";
import {
	AppState,
	GetActiveGtcCompany,
	GetActiveGtcDivision,
	GetApplicationObject,
	GetAssetTypes,
	GetAssets,
	GetCompanyObject,
	GetConfig,
	GetDivision,
	GetDivisions,
	GetInspectionTypes,
	GetInspectors,
	GetLanguageDictionary,
	GetSettings,
	SetCompanyAndDivisionId,
	SetCompanyDivisions,
	SetCompanyHasTCU,
	SetCurrentGroupPolicy,
	SetCurrentUserProfile,
	SetGroupPolicies,
	SetHydratedUserGroupPolicies,
	SetHydratedUserProfiles,
	SetUserObject,
	SetUserProfiles,
} from "./app.state";
import { ErrorService } from "./components/errors/service/error.service";
import { SetNavState } from "./components/errors/state/error.state";
import { navErrorConstants } from "./constants/error-constants";
import { SetLogInfo } from "./log.state";
import { Id } from "./models/openAPIAliases";
import { ChangeCompanyService } from "./services/change-company/change-company.service";
import { DataDogRumService } from "./services/data-dog-rum/data-dog-rum.service";
import { DbTableLoaderService } from "./services/db-table-loader/db-table-loader.service";
import { GlobalApiCallsService } from "./services/global-api-calls.service";
import { LoggerService } from "./services/logger.service";
import { NotificationService } from "./services/notification/notification.service";
import { PreviousPageService } from "./services/previous-page.service";
import { CompanyOption, SettingApiService, UserSettings } from "./services/setting-api/setting-api.service";
import { Utils } from "./utils";

export interface RouteLink {
	label: string;
	link: string;
}

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
	private activeAccountCode: string = null;
	private disableBackdropClose = false;
	private hasDefaultCompany = false;
	private onDestroy$ = new Subject<void>();
	private user: IUser;
	public groupPolicyLoginMode = false;

	@Select(AppState.getActiveCompany) activeCompany$: Observable<ICompany>;
	@Select(AppState.getActiveDivision) activeDivision$: Observable<IDivision>;
	@Select(AppState.selectCompany) company$: Observable<ICompany>;
	@Select(AppState.selectCompanyId) companyId$: Observable<Id>;
	@Select(AppState.selectSelectedDivision) selectedDivision$: Observable<Id>;
	@Select(AppState.selectSelectedGroupPolicy) selectedGroupPolicy$: Observable<IUserGroupPolicy>;
	@Select(AppState.selectSelectedUserProfile) selectedUserProfile$: Observable<IUserProfile>;
	@Select(AppState.selectGroupPolicies) groupPolicies$: Observable<Array<IUserGroupPolicy>>;
	@Select(AppState.selectUserProfiles) userProfiles$: Observable<Array<IUserProfile>>;

	constructor(
		private globalApiCallsService: GlobalApiCallsService,
		private pendoService: PendoService,
		private permissionsService: PermissionsService,
		public changeCompanyService: ChangeCompanyService,
		public datadog: DataDogRumService,
		public dbTableLoaderService: DbTableLoaderService,
		public errorService: ErrorService,
		public logger: LoggerService,
		public notificationService: NotificationService,
		public previousPageService: PreviousPageService,
		public router: Router,
		public store: Store,
		public settingApiService: SettingApiService,
	) {}

	ngOnInit() {
		this.store.dispatch(new SetLogInfo({ sessionId: uuid() }));
		this.getActiveAccountCode();
		this.getCurrentUserLogin();
		this.setCurrentUserProfileOrPolicy();
		this.getEVIRData();
		this.initializePendo();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}

	/**
	 * Update the selected division id in the store. If no division is found, navigate to the server error page
	 * @param selectedDivisionId Selected Division ID
	 */
	public setSelectedDivisionAndCompany(selectedDivisionId: string, companyId: string) {
		if (selectedDivisionId) {
			this.store.dispatch(new SetCompanyAndDivisionId(companyId, selectedDivisionId));
			setSettings(
				UserSettings.SELECTED_COMPANY,
				companyId,
				this.permissionsService.getUser(),
				this.settingApiService,
				false,
			);
			setSettings(UserSettings.SELECTED_DIVISION, selectedDivisionId, null, this.settingApiService, true);
			setSettings(UserSettings.SELECTED_COMPANY, companyId, null, this.settingApiService, true);
		} else {
			this.store.dispatch(new SetNavState({ http: true, type: navErrorConstants.NAV_ERROR_NO_DIVISIONS }));
			this.router.navigate(["server-error"]);
		}
	}

	/**
	 * Initialize Pendo
	 */
	private initializePendo() {
		if (environment.analytics.pendo) {
			this.permissionsService
				.getIsFetchPermsSuccess()
				.pipe(
					takeUntil(this.onDestroy$),
					filter(permissions => Boolean(permissions)),
				)
				.subscribe(() => {
					this.pendoService.initialize();
				});
		}
	}

	/**
	 * Get the active account code from the URL query params if any
	 */
	private getActiveAccountCode() {
		this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe((event: RouterEvent) => {
			// Get the GTC account code from the queryParams if it exists
			// active param is only there when we activate the route from clicking link in GTC
			// active param disappears after app redirects during auth
			if (event instanceof ActivationStart && event.snapshot.queryParamMap.get("active")) {
				this.activeAccountCode = event.snapshot.queryParamMap.get("active");
			}
		});
	}

	/**
	 * Get the current user object and update the store
	 */
	private getCurrentUserLogin() {
		this.permissionsService
			.getUser()
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(user => {
				if (user) {
					this.store.dispatch(new SetUserObject(user));
					this.user = user;

					// Sidenav Logic
					this.changeCompanyService
						.showChangeCompanyDialog(this.hasDefaultCompany, this.disableBackdropClose)
						.subscribe(companies => {
							// Case companyMap not an Object
							if (companies) {
								// Case have company selected or single company User
								if (companies.length) {
									// need this additional subscription to determine if we show the Change Company row or not for a Zonar/mult-company user.
									// a single company user should NOT see the Change Company row
									let currentCompany: CompanyOption = companies[0];
									if (companies.length > 1) {
										currentCompany = companies.find(
											company => company.value === this.changeCompanyService.selectedCompanyId,
										);
									}

									// EVIR CODE
									this.permissionsService.setCurrentCompanyContextById(currentCompany.value);
								}
							}
						});
				}
			});
	}
	/**
	 * Set the current user profile or policy. Selects Zonar user profile in case user is a
	 * super admin, otherwise defaults to the first user profile of the response.
	 */
	private setCurrentUserProfileOrPolicy() {
		combineLatest([this.permissionsService.getCurrentCompanyContext(), this.permissionsService.getZonarUser()])
			.pipe(debounceTime(100), takeUntil(this.onDestroy$))
			.subscribe(([companyContext, zonarUser]) => {
				combineLatest([
					getSettings(
						UserSettings.SELECTED_COMPANY,
						this.permissionsService.getUser(),
						this.settingApiService,
						false,
					).pipe(
						distinctUntilChanged(),
						map(companyIdSetting => {
							return companyIdSetting ? parseSettings(companyIdSetting).id : "";
						}),
					),
					getSettings(UserSettings.SELECTED_DIVISION, null, this.settingApiService, true).pipe(
						distinctUntilChanged(),
					),
					getSettings(UserSettings.SELECTED_COMPANY, null, this.settingApiService, true).pipe(
						distinctUntilChanged(),
					),
				])
					.pipe(take(1))
					.subscribe(([lastSelectedCompanyId, lastSelectedDivisionId, lastSelectedZonarAdminCompanyId]) => {
						if (companyContext && companyContext.id) {
							this.groupPolicyLoginMode =
								(companyContext as { id: string; name: string; loginMode: ECompanyLoginMode })
									.loginMode === "GROUP_POLICY";

							this.handleUserProfileOrPolicy(
								companyContext.id,
								this.groupPolicyLoginMode,
								lastSelectedCompanyId,
							);
						} else {
							// zonar admins have a null company context
							if (zonarUser) {
								this.handleZonarAdminProfileOrPolicy(
									zonarUser,
									this.groupPolicyLoginMode,
									lastSelectedZonarAdminCompanyId,
									lastSelectedDivisionId,
								);
							}
						}
					});
			});
	}

	/**
	 * Handle Zonar Admin Profile, set permissions and navigate to the account
	 * selection page if no previous company/division is found in local storage
	 * @param zonarUserProfileOrPolicy Zonar User Profile or Group Policy object
	 *
	 * Note: the logic has been isolated for testability
	 */
	public handleZonarAdminProfileOrPolicy2(
		lastCompanyId: string,
		lastDivisionId: string,
		zonarUserProfileOrPolicy: IUserProfile | IUserGroupPolicy,
		hasPolicies: boolean,
	) {
		this.logger.log(`Previously selected company ID: ${lastCompanyId}`);
		this.logger.log(`Previously selected division ID: ${lastDivisionId}`);

		this.store.dispatch(
			hasPolicies
				? new SetCurrentGroupPolicy(zonarUserProfileOrPolicy)
				: new SetCurrentUserProfile(zonarUserProfileOrPolicy),
		);

		console.log(">>>>>>>Zonar User", { lastCompanyId, lastDivisionId });

		if (lastCompanyId && lastDivisionId) {
			this.store.dispatch(new SetCompanyAndDivisionId(lastCompanyId, lastDivisionId));
		} else {
			this.router.navigate(["/zonar-admin-account-selection"]);
		}
	}
	public handleZonarAdminProfileOrPolicy(
		zonarUserProfileOrPolicy: IUserProfile | IUserGroupPolicy,
		hasPolicies: boolean,
		lastSelectedCompanyId: string,
		lastSelectedDivisionId: string,
	) {
		this.handleZonarAdminProfileOrPolicy2(
			lastSelectedCompanyId,
			lastSelectedDivisionId,
			zonarUserProfileOrPolicy,
			hasPolicies,
		);
	}

	/**
	 * Process received user profiles
	 * @param userProfiles Array of User Profiles
	 * @param companyId Selected Company ID
	 */
	public processReceivedUserProfiles(userProfiles: Array<IUserProfile>, companyId: string) {
		if (userProfiles && userProfiles.length && companyId) {
			const companyUserProfiles = userProfiles.filter(profile => profile.companyId === companyId);
			const currentUserProfiles =
				companyUserProfiles && companyUserProfiles.length ? companyUserProfiles : userProfiles;

			combineLatest([
				this.globalApiCallsService.getCompanyDivision(
					companyId,
					environment.environmentConstants.APP_ENDPOINT_CORE_COMPANY,
				),
			]).subscribe(([companyDivisions]) => {
				combineLatest([
					getSettings(UserSettings.SELECTED_DIVISION, null, this.settingApiService, true).pipe(take(1)),
				])
					.pipe(debounceTime(100))
					.subscribe(([lastSelectedDivisionId]) => {
						const selectedUserProfile: IUserProfile = this.selectUserProfile(
							currentUserProfiles,
							lastSelectedDivisionId,
						);

						if (companyDivisions) {
							const selectedDivision =
								lastSelectedDivisionId &&
								companyDivisions.find(division => division.id === lastSelectedDivisionId)
									? lastSelectedDivisionId
									: selectedUserProfile.divisions[0] ?? companyDivisions[0].id;

							this.logger.log(`Setting division in state: ${selectedDivision}`);
							this.logger.log(`Setting user profile in state: ${selectedUserProfile}`);

							const divisions =
								selectedUserProfile.divisions.length > 0
									? companyDivisions.filter(division =>
											selectedUserProfile.divisions.includes(division.id),
									  )
									: companyDivisions;

							this.store.dispatch(new SetCompanyDivisions(divisions));

							this.store.dispatch(new SetCurrentUserProfile(selectedUserProfile));

							this.store.dispatch(
								new SetHydratedUserProfiles(
									this.user,
									companyUserProfiles,
									environment.environmentConstants.APP_ENDPOINT_CORE_COMPANY,
									environment.environmentConstants.APP_ENDPOINT_CORE_COMPANY,
								),
							);

							this.getActiveGTCCompanyData(selectedUserProfile.id);
							this.setSelectedDivisionAndCompany(selectedDivision, companyId);
						}
					});
			});
		}
	}

	/**
	 * Process received user group policies
	 * @param userGroupPolicies Array of User Group Policies
	 * @param companyId Selected Company ID
	 * @param lastSelectedCompanyId Last selected Company ID
	 */
	public processReceivedUserPolicies(
		userGroupPolicies: Array<IUserGroupPolicy>,
		companyId: string,
		lastSelectedCompanyId: string,
	) {
		if (userGroupPolicies && userGroupPolicies?.length) {
			const companyUserPolicies = userGroupPolicies.filter(groupPolicy =>
				groupPolicy.tenant.scope.companies.some(company => company.id === companyId),
			);
			const selectedUserGroupPolicy: IUserGroupPolicy = companyUserPolicies[0];

			this.logger.log(`Previously selected company ID: ${lastSelectedCompanyId}`);

			this.globalApiCallsService
				.getCompanyDivision(companyId, environment.environmentConstants.APP_ENDPOINT_CORE_COMPANY)
				.pipe(take(1))
				.subscribe(companyDivisions => {
					combineLatest([
						getSettings(UserSettings.SELECTED_DIVISION, null, this.settingApiService, true).pipe(take(1)),
					])
						.pipe(debounceTime(100))
						.subscribe(([lastSelectedDivisionId]) => {
							if (companyDivisions) {
								const selectedDivision =
									lastSelectedDivisionId &&
									companyDivisions.find(division => division.id === lastSelectedDivisionId)
										? lastSelectedDivisionId
										: selectedUserGroupPolicy.tenant.scope.divisions[0]?.id ??
										  companyDivisions[0].id;

								this.logger.log(`Setting division in state: ${selectedDivision}`);
								this.logger.log(`Setting user policy in state: ${selectedUserGroupPolicy}`);

								this.store.dispatch(new SetCompanyDivisions(companyDivisions));

								this.store.dispatch(new SetCurrentGroupPolicy(selectedUserGroupPolicy));

								this.store.dispatch(
									new SetHydratedUserGroupPolicies(
										companyUserPolicies,
										environment.environmentConstants.APP_ENDPOINT_CORE_COMPANY,
									),
								);

								this.getActiveGTCCompanyData(selectedUserGroupPolicy.groupId);
								this.setSelectedDivisionAndCompany(
									selectedDivision,
									selectedUserGroupPolicy.policy.companyId,
								);
							}
						});
				});
		}
	}

	/**
	 * Get current user profile or group policy
	 * @param companyId Selected Company ID
	 * @param hasPolicies Boolean used to identify if the user is using Group Policy
	 * @param lastSelectedCompanyId Last selected Company ID
	 */
	private handleUserProfileOrPolicy(companyId: string, hasPolicies: boolean, lastSelectedCompanyId: string) {
		if (hasPolicies) {
			this.handleUserPolicies(companyId, lastSelectedCompanyId);
		} else {
			this.handleUserProfiles(companyId);
		}
	}

	/**
	 * Handle UserPolicies
	 * @param companyId Current company ID
	 * @param lastSelectedCompanyId last selected company ID
	 */
	private handleUserPolicies(companyId: string, lastSelectedCompanyId: string) {
		// If the user has userPolicies
		this.permissionsService
			// Get the userGroupPolicies
			.getUserGroupPolicies()
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(userGroupPolicies => {
				if (userGroupPolicies) {
					// Filter userGroupPolicies that belong to the EVIR application
					const userEvirGroupPolicies = userGroupPolicies.filter(groupPolicy =>
						groupPolicy.policy.grants.some(
							grant => grant.application.id === environment.environmentConstants.APP_APPLICATION_ID,
						),
					);

					// Patch the userGroupPolicies to the store
					this.store.dispatch(new SetGroupPolicies(userEvirGroupPolicies));

					// Process the received userGroupPolicies
					this.processReceivedUserPolicies(userEvirGroupPolicies, companyId, lastSelectedCompanyId);
				}
			});
	}

	/**
	 * Handle UserProfiles
	 * @param companyId Current company ID
	 */
	private handleUserProfiles(companyId: string) {
		// If the user has userProfiles
		this.permissionsService
			// Get the userProfiles
			.getUserProfiles()
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(userProfiles => {
				if (userProfiles) {
					// Filter userProfiles that belong to the EVIR application
					const userEvirProfiles = userProfiles.filter(
						profile => profile.applicationId === environment.environmentConstants.APP_APPLICATION_ID,
					);

					// Patch the userProfiles to the store
					this.store.dispatch(new SetUserProfiles(userEvirProfiles));

					// Process the received userProfiles
					this.processReceivedUserProfiles(userEvirProfiles, companyId);
				}
			});
	}

	/**
	 * Get Active GTC Company and Division
	 * @param userId User profile ID or Group Policy ID depending if the user is using Group Policy or not.
	 */
	public getActiveGTCCompanyData(userId: string) {
		// get the company and division from the GTC account code
		if (this.activeAccountCode) {
			this.store.dispatch([
				new GetActiveGtcCompany(
					this.activeAccountCode,
					environment.environmentConstants.APP_ENDPOINT_CORE_COMPANY,
					userId,
				),
				new GetActiveGtcDivision(
					this.activeAccountCode,
					environment.environmentConstants.APP_ENDPOINT_CORE_COMPANY,
					userId,
				),
			]);
		}
	}

	/**
	 * Get the amount of TCU devices for the selected company
	 * @param companyId Selected Company ID
	 */
	public getCompanyTCUs(companyId: string) {
		this.globalApiCallsService
			.getCompanyTCUDevices(companyId, environment.environmentConstants.APP_ENDPOINT_CORE_COMPANY)
			.subscribe(response => {
				if (response) {
					const devices = parseInt(response.headers.get("x-total-count"), 10);
					this.store.dispatch(new SetCompanyHasTCU(devices > 0));
				}
			});
	}

	/**
	 * Get EVIR Data such as configs, settings, translations and filter's data
	 * @param companyId Selected Company ID
	 * @param divisionId Selected Division ID
	 */
	public getEVIRData() {
		Utils.handleMultipleSelects([this.companyId$, this.selectedDivision$], ([companyId, divisionId]) => {
			this.store.dispatch(
				new GetCompanyObject(companyId, environment.environmentConstants.APP_ENDPOINT_CORE_COMPANY),
			);
			this.store.dispatch(
				new GetDivision(companyId, divisionId, environment.environmentConstants.APP_ENDPOINT_CORE_COMPANY),
			);
			this.store.dispatch(
				new GetLanguageDictionary(companyId, environment.environmentConstants.APP_ENDPOINT_EVIR),
			);
			this.store.dispatch(new GetSettings(companyId, environment.environmentConstants.APP_ENDPOINT_EVIR));
			this.store.dispatch(new GetConfig(companyId, environment.environmentConstants.APP_ENDPOINT_EVIR));
			this.store.dispatch(new GetInspectionTypes(companyId, environment.environmentConstants.APP_ENDPOINT_EVIR));
			this.store.dispatch(new GetAssetTypes(companyId, environment.environmentConstants.APP_ENDPOINT_EVIR));
			this.store.dispatch(
				new GetAssets(companyId, divisionId, environment.environmentConstants.APP_ENDPOINT_EVIR),
			);
			this.store.dispatch(
				new GetInspectors(companyId, divisionId, environment.environmentConstants.APP_ENDPOINT_EVIR),
			);
			this.store.dispatch(
				new GetDivisions(companyId, divisionId, environment.environmentConstants.APP_ENDPOINT_EVIR),
			);
			this.store.dispatch(
				new GetApplicationObject(
					environment.environmentConstants.APP_APPLICATION_ID,
					environment.environmentConstants.APP_ENDPOINT_CORE_APPLICATION,
				),
			);
			this.getCompanyTCUs(companyId);
		});
	}

	/**
	 * Select the User Profile to use on the app
	 * @param userEvirProfiles User profiles Array
	 * @param lastSelectedDivisionId Last Division selected by the user if any
	 * @returns A user profile that contains the last division selected if any, or the first profile in the Array
	 */
	private selectUserProfile(
		userEvirProfiles: Array<IUserProfile>,
		lastSelectedDivisionId: string | undefined,
	): IUserProfile {
		return lastSelectedDivisionId
			? // If lastSelectedDivisionId exist
			  userEvirProfiles.filter(
					profile =>
						profile.divisions.some(
							division =>
								// Get the profile that includes the lastSelectedDivisionId
								division.toLowerCase() === lastSelectedDivisionId.toLowerCase(),
						),
					// If it does not exist
			  )[0] ??
					// Check if exist an user profile that belongs to all the divisions (division === [])
					(userEvirProfiles.some(profile => profile.divisions.length === 0)
						? // Select the profile that includes all the divisions (division === [])
						  userEvirProfiles.filter(profile => profile.divisions.length === 0)[0]
						: // Otherwise default to the first userProfile
						  userEvirProfiles[0])
			: // If lastSelectedDivisionId does not exist
			  userEvirProfiles[0]; // Default to the first user profile
	}
}
