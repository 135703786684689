<div class="zonar-admin-panel">
	<div class="header-row heading-1">
		{{ "account admin" | i18next: { format: "capitalize" } }}
	</div>

	<mat-card>
		<div
			class="inspection-image-retention-card"
			fxLayout="column"
			fxLayoutGap="4px"
			fxLayoutOffset="24px"
		>
			<div class="heading-4">{{ "inspection and image retention" | i18next: { format: "capitalize" } }}</div>
			<div class="setting-description">
				{{ "amount of time Inspections and images will be kept on file" | i18next: { format: "capitalize" } }}.
			</div>

			<label class="zonar-admin-label">{{ "time" | i18next: { format: "capitalize" } }}</label>
			<mat-form-field class="field-border">
				<mat-select
					#retentionTimeChangeSelect
					(selectionChange)="handleRetentionTimeChange($event)"
					[value]="selectedValue"
				>
					<mat-option
						*ngFor="let option of options"
						[value]="option"
					>
						{{ option }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</mat-card>
</div>
