<div class="evir-resources-panel">
	<div class="header-row heading-1">
		{{ "resources" | i18next: { format: "capitalize" } }}
	</div>

	<div class="panel-main-row">
		<!-- links column  -->
		<div class="panel-col">
			<!-- alert settings  -->
			<mat-card>
				<div class="layout">
					<div class="heading-4">{{ "alert settings" | i18next: { format: "capitalize" } }}</div>
					<div class="setting-description">
						{{
							"Keep your users up to date with the latest fleet changes by creating alerts for events like reported defects, missing inspections, etc."
								| i18next: { format: "capitalize" }
						}}
					</div>
					<!-- link button  -->
					<div class="outer">
						<a
							target="_blank"
							class="link-button"
							[href]="resourceLinks.alertSettings"
						>
							<div class="inner">
								<!-- label  -->
								<div>
									{{ "Alert settings" | i18next: {} }}
								</div>

								<!-- launch "button"  -->
								<div class="launch-btn">
									<a target="_blank">
										<img src="assets/launch.png" />
									</a>
								</div>
							</div>
						</a>
					</div>
				</div>
			</mat-card>
			<!-- inspection builder  -->
			<mat-card>
				<div class="layout">
					<div class="heading-4">{{ "inspection builder" | i18next: { format: "capitalize" } }}</div>
					<div class="setting-description">
						{{
							"Create customized inspections according to your fleet requirements by editing and creating inspections in Inspection Builder."
								| i18next: { format: "capitalize" }
						}}
					</div>
					<!-- link button  -->
					<div class="outer">
						<a
							target="_blank"
							class="link-button"
							[href]="resourceLinks.inspectionBuilder"
						>
							<div class="inner">
								<!-- label  -->
								<div>
									{{ "inspection builder" | i18next: { format: "capitalize" } }}
								</div>

								<!-- launch "button"  -->
								<div class="launch-btn">
									<a target="_blank">
										<img src="assets/launch.png" />
									</a>
								</div>
							</div>
						</a>
					</div>
				</div>
			</mat-card>
			<!-- EVIR help documentation  -->
			<mat-card>
				<div class="layout">
					<div class="heading-4">{{ "EVIR help documentation" | i18next: { format: "capitalize" } }}</div>
					<div class="setting-description">
						{{
							"For quick access to answers and resources, click on the help documentation for comprehensive assistance."
								| i18next: { format: "capitalize" }
						}}
					</div>
					<!-- link button  -->
					<div class="outer">
						<a
							target="_blank"
							class="link-button"
							[href]="resourceLinks.evirHelpDocumentation"
						>
							<div class="inner">
								<!-- label  -->
								<div>
									{{ "EVIR help documentation" | i18next: {} }}
								</div>

								<!-- launch "button"  -->
								<div class="launch-btn">
									<a target="_blank">
										<img src="assets/launch.png" />
									</a>
								</div>
							</div>
						</a>
					</div>
				</div>
			</mat-card>
		</div>
		<!-- default asset type  -->
		<div class="panel-col">
			<mat-card>
				<div class="layout asset-layout">
					<div class="heading-4">{{ "default asset type" | i18next: { format: "capitalize" } }}</div>
					<div class="setting-description">
						{{
							"please choose which asset type should be the default for all future inspections"
								| i18next: { format: "capitalize" }
						}}
					</div>

					<div class="content">
						<mat-radio-group
							[value]="selectedAssetType"
							(change)="handleAssetTypeChange($event)"
							class="radio-group"
						>
							<mat-radio-button
								*ngFor="let type of assetTypes"
								[value]="type.value"
								class="radio-btns"
							>
								{{ type.label }}
							</mat-radio-button>
						</mat-radio-group>

						<div [innerHTML]="assetTypeImage"></div>
					</div>
				</div>
			</mat-card>
		</div>
	</div>
</div>

<!-- De-scoped for R1 -->
<!-- <mat-card class='settings-card card-resolutions'>
  <div class='heading-4'>Defect resolution descriptions</div>


</mat-card> -->
