import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ICompany, IDivision } from "@zonar-ui/auth/lib/models/company.model";
import { IPolicy, IUserGroupPolicy } from "@zonar-ui/auth/lib/models/user-group-policy.model";
import { IUserProfile } from "@zonar-ui/auth/lib/models/user-profile.model";
import { IUser } from "@zonar-ui/auth/lib/models/user.model";
import { Observable, forkJoin, of } from "rxjs";
import { map } from "rxjs/operators";
import { IGroup } from "../models/IGroup";
import { Application, HydratedUserPolicies, HydratedUserProfile } from "../models/core-api.models";
import {
	Asset,
	ConfigGet,
	EvirDivisionsGetInner,
	Id,
	InspectionTypesResponse,
	InspectorsInner,
	LangDictGet,
	ZoneLayouts,
} from "../models/openAPIAliases";
import { LoggerService } from "./logger.service";
import { RequestService } from "./request/request.service";
import { ServiceUtilsService } from "./service-utils.service";

@Injectable({
	providedIn: "root",
})
export class GlobalApiCallsService {
	constructor(
		private requestService: RequestService,
		public logger: LoggerService,
		public serviceUtils: ServiceUtilsService,
	) {}

	public getCompany(companyId: string, endpoint: string): Observable<ICompany> {
		return this.requestService.get<ICompany>({
			url: `${endpoint}/companies/${encodeURIComponent(companyId)}`,
		});
	}

	public getCompanyTCUDevices(companyId: string, endpoint: string): Observable<HttpResponse<unknown>> {
		return this.requestService.get<HttpResponse<unknown>>({
			url: `${endpoint}/devices?companyId=${encodeURIComponent(companyId)}&deviceType=TCU`,
			httpOptions: { observe: "response" },
		});
	}

	public getApplication(applicationId: string, endpoint: string): Observable<Application> {
		return this.requestService.get<Application>({
			url: `${endpoint}/applications/${encodeURIComponent(applicationId)}`,
		});
	}

	public getInspectionTypes(companyId: string, endpoint: string): Observable<InspectionTypesResponse> {
		return this.requestService.get<InspectionTypesResponse>({
			url: `${endpoint}/inspection-types?companyId=${encodeURIComponent(companyId)}`,
		});
	}

	public getAssetTypes(companyId: string, endpoint: string): Observable<ZoneLayouts> {
		return this.requestService.get<ZoneLayouts>({
			url: `${endpoint}/zone-layouts?companyId=${encodeURIComponent(companyId)}`,
		});
	}

	public getAssets(companyId: string, divisionId: string, endpoint: string) {
		const uri = `${endpoint}/inspected-assets?companyId=${encodeURIComponent(
			companyId,
		)}&divisionIds=${encodeURIComponent(divisionId)}&allChildren=true`;
		return this.serviceUtils.getAllPages(uri, "inspected assets") as Observable<Asset[]>;
	}

	public getInspectors(companyId: string, divisionId: string, endpoint: string) {
		const uri = `${endpoint}/inspectors?companyId=${encodeURIComponent(companyId)}&divisionIds=${encodeURIComponent(
			divisionId,
		)}&allChildren=true`;
		return this.serviceUtils.getAllPages(uri, "inspectors") as Observable<InspectorsInner[]>;
	}

	public getDivisions(companyId: string, divisionId: string, endpoint: string) {
		const uri = `${endpoint}/evir-divisions?companyId=${encodeURIComponent(
			companyId,
		)}&divisionIds=${encodeURIComponent(divisionId)}&allChildren=true`;
		return this.serviceUtils.getAllPages(uri, "divisions") as Observable<EvirDivisionsGetInner[]>;
	}

	public getDivision(companyId: string, divisionId: string, endpoint: string) {
		return this.requestService.get<IDivision>({
			url: `${endpoint}/companies/${encodeURIComponent(companyId)}/divisions/${encodeURIComponent(divisionId)}`,
		});
	}

	public getCompanyDivision(companyId: string, endpoint: string) {
		return this.requestService.get<Array<IDivision>>({
			url: `${endpoint}/companies/${encodeURIComponent(companyId)}/divisions`,
		});
	}

	public getCoreApiDivisionsWithFuzzySearch(divisionName: string, endpoint: string): Observable<Array<IDivision>> {
		// todo: re-enable after https://jira.zonarsystems.net/browse/APID-1690 is done
		// return this.http.get<any[]>(`${endpoint}/divisions?q=${encodeURIComponent(divisionName)}&df=name&page=1&per_page=100`)
		return this.requestService.get<Array<IDivision>>({
			url: `${endpoint}/divisions?q=${encodeURIComponent(
				divisionName,
			)}&df=name&page=1&per_page=100&default_operator=and`,
		});
	}

	// this is singular because in app.state.ts, we're taking the first in the array
	public getConfig(companyId: string, endpoint: string): Observable<ConfigGet[]> {
		return this.requestService.get<ConfigGet[]>({
			url: `${endpoint}/evir-configs?companyId=${encodeURIComponent(companyId)}&activeOnly=True`,
		});
	}

	public getSingleConfig(configId: string, companyId: string, endpoint: string): Observable<ConfigGet> {
		return this.requestService.get<ConfigGet>({
			url: `${endpoint}/evir-configs/${encodeURIComponent(configId)}?companyId=${encodeURIComponent(companyId)}`,
		});
	}

	// exempt from this.serviceUtils.getAllPages() because it isn't return a collection
	public getConfigs(
		configIds: string[],
		companyId: string,
		endpoint: string,
		queryStrings: string = "",
	): Observable<ConfigGet[]> {
		const observables = configIds.map((configId: Id) => {
			return this.requestService.get<ConfigGet>({
				url: `${endpoint}/evir-configs/${configId}?companyId=${encodeURIComponent(companyId)}${queryStrings}`,
			});
		});

		return forkJoin(observables);
	}

	public getPoliciesFromCompany(companyId: string, endpoint: string): Observable<Array<IPolicy>> {
		return this.requestService.get<Array<IPolicy>>({
			url: `${endpoint}/policies?companyId=${encodeURIComponent(companyId)}`,
		});
	}

	public getGroupsFromPolicy(policyId: string, endpoint: string): Observable<Array<IGroup>> {
		return this.requestService.get<Array<IGroup>>({
			url: `${endpoint}/groups?policyId=${encodeURIComponent(policyId)}`,
		});
	}

	public hydrateUserProfiles(
		user: IUser,
		userProfiles: Array<IUserProfile>,
		companyEndpoint: string,
		applicationEndpoint: string,
	): Observable<HydratedUserProfile[]> {
		const observables = userProfiles.map((userProfile: IUserProfile) => {
			return this.hydrateUserProfile(user, userProfile, companyEndpoint, applicationEndpoint);
		});

		return forkJoin(observables);
	}

	hydrateUserProfile(
		user: IUser,
		userProfile: IUserProfile,
		companyEndpoint: string,
		applicationEndpoint: string,
	): Observable<HydratedUserProfile> {
		const options = { headers: { "Zonar-Owner-ID": `User ${userProfile.id}` } };

		const companyObservable = this.requestService.get<ConfigGet>({
			url: `${companyEndpoint}/companies/${encodeURIComponent(userProfile.companyId)}`,
			httpOptions: options,
		});

		const divisionsObservable = this.requestService.get<Array<IDivision>>({
			url: `${companyEndpoint}/companies/${encodeURIComponent(userProfile.companyId)}/divisions`,
			httpOptions: options,
		});

		const applicationObservable = this.requestService.get<Application>({
			url: `${applicationEndpoint}/applications/${encodeURIComponent(userProfile.applicationId)}`,
			httpOptions: options,
		});

		const observables = [
			of(userProfile),
			applicationObservable,
			...(userProfile.companyId ? [companyObservable, divisionsObservable] : []),
		];

		return forkJoin(observables).pipe(
			map(
				([userProfileData, application, company, allCompanyDivisions]: [
					IUserProfile,
					Application,
					ICompany | undefined,
					Array<IDivision> | undefined,
				]) => {
					return {
						...userProfileData,
						allCompanyDivisions,
						application,
						company,
						user,
					};
				},
			),
		);
	}

	public hydrateUserPolicies(
		userPolicies: Array<IUserGroupPolicy>,
		companyEndpoint: string,
	): Observable<HydratedUserProfile[]> {
		const observables = userPolicies.map(userPolicy => {
			return this.hydrateUserPolicy(userPolicy, companyEndpoint);
		});

		return forkJoin(observables);
	}

	hydrateUserPolicy(userPolicy: IUserGroupPolicy, companyEndpoint: string): Observable<HydratedUserPolicies> {
		const options = { headers: { "Zonar-Owner-ID": `Group ${userPolicy.groupId}` } };

		const companyObservable = this.requestService.get<ConfigGet>({
			url: `${companyEndpoint}/companies/${encodeURIComponent(userPolicy.tenant.scope.companies[0].id)}`,
			httpOptions: options,
		});

		const divisionsObservable = this.requestService.get<Array<IDivision>>({
			url: `${companyEndpoint}/companies/${encodeURIComponent(
				userPolicy.tenant.scope.companies[0].id,
			)}/divisions`,
			httpOptions: options,
		});

		const observables = [
			of(userPolicy),
			...(userPolicy.tenant.scope.companies[0].id ? [companyObservable, divisionsObservable] : []),
		];

		return forkJoin(observables).pipe(
			map(
				([userProfileData, company, allCompanyDivisions]: [
					IUserProfile,
					ICompany | undefined,
					Array<IDivision> | undefined,
				]) => {
					return {
						...userProfileData,
						allCompanyDivisions,
						company,
					};
				},
			),
		);
	}

	public getLanguageDictionary(companyId: string, endpoint: string): Observable<LangDictGet> {
		const url = new URL(endpoint);
		url.pathname = `${url.pathname}/lang-dict`;
		url.searchParams.set("companyId", companyId);

		return this.requestService.get<LangDictGet>({
			url: url.toString(),
		});
	}

	public getActiveGtcCompany(
		legacyAccountCode: string,
		endpoint: string,
		userProfileID: string,
	): Observable<ICompany> {
		const options = { headers: { "Zonar-Owner-ID": `User ${userProfileID}` } };
		return this.requestService.get<ICompany>({
			url: `${endpoint}/companies?legacyAccountCode=${encodeURIComponent(legacyAccountCode)}`,
			httpOptions: options,
		});
	}

	/**
	 * Get active GTC division
	 * @param legacyAccountCode Legacy account code. For example, "mbl2020"
	 * @param endpoint Endpoint to make the call
	 * @param userID User profile ID or Group ID depending if the company is using Group Policy or not.
	 * @returns Division object
	 */
	public getActiveGtcDivision(legacyAccountCode: string, endpoint: string, userID: string): Observable<IDivision> {
		const options = { headers: { "Zonar-Owner-ID": `User ${userID}` } };
		return this.requestService.get<IDivision>({
			url: `${endpoint}/divisions?legacyAccountCode=${encodeURIComponent(legacyAccountCode)}`,
			httpOptions: options,
		});
	}

	/**
	 * Get BigQuery Assets
	 * @param companyId Company ID
	 * @param _divisionId division ID
	 * @param endpoint Endpoint to make the call
	 * @returns An observable that could contain the list of Asset
	 */
	getBigQueryAssets(companyId: string, _divisionId: string, endpoint: string) {
		return this.getBigQueryFilterValues(companyId, "assets", endpoint);
	}

	/**
	 * Get BigQuery Divisions
	 * @param companyId Company ID
	 * @param _divisionId division ID
	 * @param endpoint Endpoint to make the call
	 * @returns An observable that could contain the list of EvirDivisionsGetInner
	 */
	getBigQueryDivisions(companyId: string, _divisionId: string, endpoint: string) {
		return this.getBigQueryFilterValues(companyId, "divisions", endpoint);
	}

	/**
	 * Get BigQuery Inspectors
	 * @param companyId Company ID
	 * @param _divisionId division ID
	 * @param endpoint Endpoint to make the call
	 * @returns An observable that could contain the list of InspectorsInner
	 */
	getBigQueryInspectors(companyId: string, _divisionId: string, endpoint: string) {
		return this.getBigQueryFilterValues(companyId, "inspectors", endpoint);
	}

	/**
	 * Get BigQuery filter values
	 * @param companyId company ID
	 * @param filterType BigQuery filter type: divisions, assets, or inspectors
	 * @param endpoint EVIR API endpoint
	 * @returns Observable of Divisions/Inspectors/Assets array
	 */
	private getBigQueryFilterValues(
		companyId: string,
		filterType: "divisions" | "assets" | "inspectors",
		endpoint: string,
	) {
		const uri = `${endpoint}/reports/filters?filter=${filterType}&companyId=${encodeURIComponent(companyId)}`;
		return this.serviceUtils.getAllPages(uri, filterType) as Observable<
			Array<EvirDivisionsGetInner | InspectorsInner | Asset>
		>;
	}
}
