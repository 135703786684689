import { Injectable } from "@angular/core";
import { Router, RouterEvent, NavigationEnd } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
// let angular know about global variable provided by google analytics library
declare let gtag;

export class GaPageInformation {
	pagePath: string;
	pageTitle: string;
	pageLocation: string;
}

@Injectable({
	providedIn: "root",
})
export class AnalyticsService {
	enabled: boolean;

	constructor(private router: Router) {
		this.enabled = environment.analytics.google;

		this.sendGaPageview();
	}

	public sendGaPageview(): void {
		if (this.enabled) {
			this.router.events.subscribe((event: RouterEvent) => {
				if (event instanceof NavigationEnd) {
					if (this.getPageInformation(event.urlAfterRedirects)) {
						const { pagePath, pageTitle, pageLocation }: GaPageInformation = this.getPageInformation(
							event.urlAfterRedirects,
						);

						gtag("config", "UA-27674600-7", {
							page_path: pagePath,
							page_title: pageTitle,
							page_location: pageLocation,
						});
					}
				}
			});
		}
	}

	public getPageInformation(url: string): GaPageInformation {
		if (url.includes("inspection-list")) {
			return { pagePath: "/inspection-list", pageTitle: "Inspection History", pageLocation: url };
		}

		if (url.includes("defects")) {
			return { pagePath: "/defects", pageTitle: "Defects", pageLocation: url };
		}

		if (url.includes("missing-inspections")) {
			return { pagePath: "/missing-inspections", pageTitle: "Missing Inspections", pageLocation: url };
		}

		if (url.includes("inspection/")) {
			return { pagePath: "/inspection/:id", pageTitle: "Single Inspection Details", pageLocation: url };
		}

		if (url.includes("asset-details")) {
			return {
				pagePath: "/asset-details/:id",
				pageTitle: "Single Asset Defect and Repair History",
				pageLocation: url,
			};
		}

		if (url.includes("resources-panel")) {
			return { pagePath: "/resources-panel", pageTitle: "Customer Resources Panel", pageLocation: url };
		}

		if (url.includes("account-admin")) {
			return { pagePath: "/account-admin", pageTitle: "Zonar Admin Panel", pageLocation: url };
		}

		if (url.includes("inspections-print-view")) {
			return { pagePath: "/inspections-print-view", pageTitle: "Inspections Print View", pageLocation: url };
		}
	}

	public sendGaException(error: unknown): void {
		if (this.enabled) {
			console.log("calling ga exception");

			if (error instanceof HttpErrorResponse) {
				gtag("event", "exception", {
					description: `HTTP Status: ${error.status} | Error: ${JSON.stringify(error.error)}`,
					fatal: false,
				});
			} else {
				gtag("event", "exception", {
					description: `Angular client-side error ${error}`,
					fatal: false,
				});
			}
		}
	}
}
