import { Injectable } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, take, timeout } from "rxjs/operators";
import { AppState, ClearLoadTableRequest, NotificationFromDbLoader } from "src/app/app.state";
import { timeoutErrorConstants } from "src/app/constants/error-constants";
import { Id } from "src/app/models/openAPIAliases";
import { Utils } from "src/app/utils";
import { environment } from "src/environments/environment";
import { GlobalApiCallsService } from "../global-api-calls.service";
import { FieldMeta, LoadTableRequest, LocalStoreService, TableRecord } from "../local-store/local-store.service";
import { NotificationLevel } from "../notification/notification.service";

export const NetworkErrorMessage = "Network connection error.";

type Loader = (companyId: string, divisionId: string, evirEndpoint: string) => Observable<Array<unknown>>;
@Injectable({
	providedIn: "root",
})
export class DbTableLoaderService {
	@Select(AppState.selectSelectedDivision) selectedDivisionId$: Observable<Id>;
	@Select(AppState.selectCompanyId) companyId$: Observable<Id>;
	@Select(AppState.getLoadTableRequest) tableToLoad$: Observable<LoadTableRequest>;

	requests$ = new BehaviorSubject<boolean>(false);
	requests: Array<LoadTableRequest> = [];

	selectedDivisionId: string;
	companyId: string;
	endPointEvir: string;

	loaders: Record<string, Loader> = {
		assets: this.globalApiCallsService.getAssets.bind(this.globalApiCallsService),
		inspectors: this.globalApiCallsService.getInspectors.bind(this.globalApiCallsService),
		divisions: this.globalApiCallsService.getDivisions.bind(this.globalApiCallsService),
		bigQueryAssets: this.globalApiCallsService.getBigQueryAssets.bind(this.globalApiCallsService),
		bigQueryInspectors: this.globalApiCallsService.getBigQueryInspectors.bind(this.globalApiCallsService),
		bigQueryDivisions: this.globalApiCallsService.getBigQueryDivisions.bind(this.globalApiCallsService),
	};

	schemas: Record<string, Array<FieldMeta>> = {
		divisions: [{ name: "divisionId" }, { name: "divisionName" }, { name: "divisionType" }],
		inspectors: [{ name: "inspectorId" }, { name: "inspectorFirstName" }, { name: "inspectorLastName" }],
		assets: [
			{ name: "assetId" },
			{ name: "assetCategory" },
			{ name: "assetJurisdiction" },
			{ name: "assetVersion" },
			{ name: "assetDivisions" },
			{ name: "assetName" },
			{ name: "assetType" },
		],
		bigQueryDivisions: [{ name: "divisionId" }, { name: "divisionName" }],
		bigQueryInspectors: [{ name: "inspectorId" }, { name: "inspectorFirstName" }, { name: "inspectorLastName" }],
		bigQueryAssets: [{ name: "assetId" }, { name: "assetName" }],
	};

	constructor(
		private localStoreService: LocalStoreService,
		private globalApiCallsService: GlobalApiCallsService,
		private store: Store,
	) {
		// initialize global db args
		Utils.handleMultipleSelects([this.companyId$, this.selectedDivisionId$], ([companyId, selectedDivisionId]) => {
			this.init(companyId, selectedDivisionId);
		});

		// accumulate requests
		this.tableToLoad$.subscribe((loadTableRequest: LoadTableRequest) => {
			if (loadTableRequest && typeof loadTableRequest.tableName === "string") {
				// otherwise in the init state
				this.store.dispatch(new ClearLoadTableRequest());

				this.requests.push(loadTableRequest);
				this.requests$.next(true);
			}
		});
	}

	init(companyId: string, selectedDivisionId: string) {
		this.selectedDivisionId = selectedDivisionId;
		this.companyId = companyId;
		this.endPointEvir = environment.environmentConstants.APP_ENDPOINT_EVIR;

		// now that we have completed initialization, we can start processing requests
		this.requests$.subscribe(() => {
			let loadTableRequest: LoadTableRequest;

			do {
				loadTableRequest = this.requests.shift();

				if (loadTableRequest) {
					this.loadTable(loadTableRequest);
				}
			} while (loadTableRequest);
		});
	}

	loadTable(loadTableRequest: LoadTableRequest) {
		if (loadTableRequest.tableName) {
			this.loaders[loadTableRequest.tableName](this.companyId, this.selectedDivisionId, this.endPointEvir)
				.pipe(
					timeout(timeoutErrorConstants.TIMEOUT_MS),
					catchError(error => {
						this.store.dispatch(
							new NotificationFromDbLoader({
								message: NetworkErrorMessage,
								level: NotificationLevel.Error,
							}),
						);
						console.error(error);
						return of();
					}),
					take(1),
				)
				.subscribe((dBrecords: Array<TableRecord>) => {
					this.receiveDbRecords(loadTableRequest, dBrecords);
				});
		}
	}

	receiveDbRecords(loadTableRequest: LoadTableRequest, dBrecords: Array<TableRecord>) {
		let context = this.localStoreService.startUpdate(
			loadTableRequest.tableName,
			this.schemas[loadTableRequest.tableName],
		);

		for (const dbRecord of dBrecords) {
			// assemble a local-store formatted record from the raw db record
			const record: TableRecord = { fields: [] };

			for (const fieldName of this.schemas[loadTableRequest.tableName].map(
				fieldDescription => fieldDescription.name,
			)) {
				record.fields.push({
					name: fieldName,
					value: dbRecord[fieldName],
				});
			}

			// update the local store, once the record has been assembled
			context = this.localStoreService.continueUpdate(context, [record]);
		}

		this.localStoreService.completeUpdate(context);
	}
}
