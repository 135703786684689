import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Select, Store } from "@ngxs/store";
import { Observable, Subscription, combineLatest } from "rxjs";
import { AppState, GetAssetTypeImage, PatchSettings } from "src/app/app.state";
import { Option } from "src/app/components/multi-select-dropdown/multi-select-dropdown.component";
import { currentLocale } from "src/app/i18next";
import { AssetTypeSettingsPayload } from "src/app/models/api-payloads.models";
import { environment } from "src/environments/environment";
import { ConfigGet, Efigs, Id, SettingsGet, ZoneLayouts } from "../../models/openAPIAliases";
import { TabUpdaterService } from "../../services/tab-updater.service";

@Component({
	selector: "app-resources-panel",
	templateUrl: "./resources-panel.component.html",
	styleUrls: ["./resources-panel.component.scss"],
})
export class ResourcesPanelComponent implements AfterViewInit, OnInit, OnDestroy {
	selectedAssetType: string;
	assetTypes: Array<Option> = [];
	settings: SettingsGet;
	companyId: Id;
	assetTypeImage: SafeHtml;
	subscriptions = new Subscription();

	resourceLinks = environment.resourceLinks;

	@Select(AppState.selectAssetTypes) assetTypes$: Observable<ZoneLayouts>;
	@Select(AppState.selectSettings) settings$: Observable<SettingsGet>;
	@Select(AppState.selectCompanyId) companyId$: Observable<Id>;
	@Select(AppState.selectConfig) config$: Observable<ConfigGet>;
	@Select(AppState.selectAssetTypeImage) assetTypeImage$: Observable<SafeHtml>;

	constructor(private tabUpdater: TabUpdaterService, private store: Store, private sanitizer: DomSanitizer) {}

	ngOnInit() {
		this.subscriptions.add(
			this.assetTypeImage$.subscribe((assetTypeImage: string) => {
				if (assetTypeImage) {
					this.assetTypeImage = this.sanitizer.bypassSecurityTrustHtml(assetTypeImage);
				}
			}),
		);

		// todo: use existing utils function
		this.subscriptions.add(
			combineLatest([this.settings$, this.assetTypes$, this.config$, this.companyId$]).subscribe(
				([settings, assetTypes, config, companyId]) => {
					this.processStateUpdate(settings, assetTypes, config, companyId);
				},
			),
		);
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.tabUpdater.onTabChange.emit({ tab: 2, subTab: -1 }); // Admin Panel tab
		}, 0);
	}

	processStateUpdate(settings: SettingsGet, assetTypes: ZoneLayouts, config: ConfigGet, companyId: Id) {
		if (settings && assetTypes && config && companyId) {
			this.companyId = companyId;
			this.settings = settings;
			const assetTypesArray: Array<Option> = [];

			// Generate the asset types array
			for (const uuid in assetTypes) {
				const localizationObject: Efigs = assetTypes[uuid];
				const assetType: Option = {
					value: uuid,
					label: localizationObject[currentLocale()] || localizationObject["en-us"],
				};
				assetTypesArray.push(assetType);
			}
			this.assetTypes = assetTypesArray;

			// Set the controls to whatever the current settings are
			const zoneLayoutId = this.assetTypes.find(
				assetType => assetType.value === settings.defaultZoneLayoutId,
			)?.value;

			this.selectedAssetType = zoneLayoutId;
			const assetViewId = config.zoneLayouts.find(layout => layout.zoneLayoutId.id === zoneLayoutId)?.assetViewId;

			if (assetViewId) {
				this.store.dispatch(
					new GetAssetTypeImage(companyId, assetViewId, environment.environmentConstants.APP_ENDPOINT_EVIR),
				);
			}
		}
	}

	handleAssetTypeChange(event): void {
		const payload: AssetTypeSettingsPayload = {
			companyId: this.companyId,
			defaultZoneLayoutId: event.value,
		};

		this.store.dispatch(new PatchSettings(payload, environment.environmentConstants.APP_ENDPOINT_EVIR));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
